import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getDoctorId, getListPatients } from '../api/CurrentServiceWorker';

export const AppContext = createContext({});

const AppContextProvider = ({children}) => {
    const [value, setValue] = useState({})

    const handleSetValue = (k, v) => {
        if(k && v) {
            setValue({
                ...value,
                [k]: v
            })
        }
    }

    const {data: patientList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients(),
        {
            enabled: !!getDoctorId()
        }
    )

    const getPatientManagedIds = () => {
        if(!patientList || !Array.isArray(patientList)) {
            return [];
        }

        let returnedList = [];
        for(let i = 0; i < patientList.length; i++) {
            if(patientList[i] && patientList[i].patient_id) {
                returnedList.push(patientList[i].patient_id)
            }
        }

        return returnedList;
    }

    const patientManagedIds = useMemo(() => {
        return getPatientManagedIds()
    }, [patientList?.length])

    return(
        <AppContext.Provider
            value={{
                value: value,
                patientManagedIds: patientManagedIds,
                handleSetValue: () => handleSetValue
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => useContext(AppContext);
export default AppContextProvider;