import React, { useCallback, useEffect, useMemo } from 'react'
import { PrimeIcons } from "primereact";
import { useQuery } from '@tanstack/react-query';
import { getAllDoctors, getAllObs, getAllPatientObs, getDoctorId, getExerciseTableData, getObsColors, getParameterData, getPatientId, getPatientObs, getSummaryData } from '../../api/CurrentServiceWorker';
import { initialParameterValue } from './SummaryParameterForm';
import moment from 'moment';
import { innerHtml } from '../../utils/CommonUtils';
import { oxiColorData, pulseColorData, pulseDataRange, respirationColorData, respirationDataRange, spO2DataRange, tempColorData, temperatureDataRange } from '../observations/Constants';
import { getIndexOfValueInMultiCol} from '../observations/Utils'
import { async } from '@firebase/util';
import { useState } from 'react';

const SummaryDetail = () => {
    const {data} = useQuery(['summaryParameterData', getPatientId()], async() => await getParameterData())
    const reversedParameterList = data && Array.isArray(data) ? data.reverse() : [];
        
    const {data: allObsData} = useQuery(['obsAllData', getPatientId()], async() => await getAllObs())

    const convertValueToObsAllData = () => {
        if(obsDataList && Array.isArray(obsDataList)) {
            let holderObj = {}
            for(let i = 0; i < obsDataList.length; i++) {
                if(obsDataList[i] && Object.keys(obsDataList[i]).length > 0) {
                    for(const key in obsDataList[i]) {
                        if(key in holderObj && holderObj[key]) {
                            continue;
                        } else {
                            holderObj[key] = obsDataList[i][key];
                            holderObj[`${key}_createdAt`] = obsDataList[i].created_at
                        }
                    }
                }                
            }
             
            return [holderObj]
        } else {
            return []
        }
    } 

    const summaryDataList = useMemo(() => {
        return convertValueToObsAllData();
    }, [obsDataList])

    const summaryData = summaryDataList && Array.isArray(summaryDataList) ? summaryDataList[0] : {};
    
    const obsDataList = useMemo(() => (
        allObsData && Array.isArray(allObsData) ? allObsData.reverse() : []
    ), []);

    const summaryParameterData = reversedParameterList.length > 0 ? reversedParameterList[0] : initialParameterValue

    const {data: excerciseData} = useQuery(['excerciseTable', getPatientId()], async() => await getExerciseTableData()) 

    const convertValueToLabel = (value) => {
        let returnLabel = "";
        switch(value){
            case 25: 
                returnLabel = "None";
                break;
            case 50:
                returnLabel = "Mild";
                break;
            case 75:
                returnLabel = "Moderate";
                break;
            case 100:
                returnLabel = "Severe";
                break;
            default:
                break;
        }
        return returnLabel
    }

    const getColorByValue = (colorList, dataRange, value) => {
        if(!colorList || !Array.isArray(colorList) || !dataRange || !Array.isArray(dataRange) || !value) {
          return ""
        }
    
        const obsColors = getObsColors();
        if(obsColors && obsColors.lv1) {
          colorList = colorList.map((x) => x.replace(obsColors.lv1, "black"));
        }
    
        const headColor = colorList[0];
        const tailColor = colorList[colorList.length - 1];
    
        colorList.push(tailColor);
        colorList.unshift(headColor);
        
    
        let colorValue = "";
        let colorIndex = getIndexOfValueInMultiCol(dataRange, value);
        if(colorIndex !== null) {
          colorValue = colorList[colorIndex + 1];
        }
            
        return colorValue;
      }

    const changeLabel = useMemo(() => {
        const summaryDataObj = (summaryDataList && Array.isArray(summaryDataList) && summaryDataList[0]) ? summaryDataList[0] : {}

        const dataList = [
            {   
                key:"confusion",
                label: "Confushion",
                value:  `${summaryDataObj.confusion ? summaryDataObj.confusion : "-"}`,
                description: "Confusion",
                color: `${summaryDataObj.confusion ? 'red' : "black"}`,
                icon: 'brain',
                updated: `${summaryDataObj.confusion && summaryDataObj.confusion_createdAt ?  summaryDataObj.confusion_createdAt : null}`,
            },
            {   
                key:"consciousness",
                label: "",
                value: `${summaryDataObj.consciousness ? summaryDataObj.consciousness : "-"}`,
                description: "Level Of Consciousness",
                icon: 'brain',
                updated: `${summaryDataObj.consciousness_createdAt ? summaryDataObj.consciousness_createdAt : null}`,
            },
            {
                key:"hrValue",
                label: "",
                value: `${summaryDataObj.hrValue ? summaryDataObj.hrValue  : "-"}`,
                description: "Heart Rate (Regular)",
                color: summaryDataObj.hrValue ? getColorByValue(pulseColorData, pulseDataRange, summaryDataObj.hrValue) : "black",
                icon: 'heartbeat',
                labelData: ['hrLower', 'hrUpper'],
                updated: `${ summaryDataObj.hrValue && summaryDataObj.hrValue_createdAt ? summaryDataObj.hrValue_createdAt  : null}`
            },
            {
                key:"rrValue",
                label: "",
                value: `${summaryDataObj.rrValue ? summaryDataObj.rrValue : "-"}`,
                description: "Respiration Rate",
                color: summaryDataObj.rrValue ? getColorByValue(respirationColorData, respirationDataRange, summaryDataObj.rrValue) : "black",
                icon: 'lungs',
                labelData: ['rrLower', 'rrUpper'],
                updated: `${summaryDataObj.rrValue && summaryDataObj.rrValue_createdAt ? summaryDataObj.rrValue_createdAt : null}`,
            },
            {
                key:"oxiValue",
                label: "",
                value: `${summaryDataObj.oxiValue ? summaryDataObj.oxiValue : "-"}`,
                description: "O2 Saturation",
                color: summaryDataObj.oxiValue ? getColorByValue(oxiColorData, spO2DataRange, summaryDataObj.oxiValue) : "black",
                icon: "head-side-cough",
                labelData: ['oxiLower', 'oxiUpper'],
                updated: `${summaryDataObj.oxiValue_createdAt ? summaryDataObj.oxiValue_createdAt : null}`,
            },
            {
                key:"tempValue",
                label: "°C",
                value: `${summaryDataObj.tempValue ? summaryDataObj.tempValue : "-"}`,
                description: "Temperature",
                color: summaryDataObj.tempValue ? getColorByValue(tempColorData, temperatureDataRange, summaryDataObj.tempValue) : "black",
                icon: 'temperature-high',
                labelData: ['tempLower', 'tempUpper'],
                updated: `${summaryDataObj.tempValue_createdAt ? summaryDataObj.tempValue_createdAt : null}`,
            },
            {
                key: 'bloodPressure',
                label: "Lying BP / Right Arm",
                value: `
                ${summaryDataObj.syValue ? summaryDataObj.syValue : "-"}  /
                ${summaryDataObj.diValue ? summaryDataObj.diValue : "-"}
                <hr/>
                ${summaryDataObj.bpPosition ? summaryDataObj.bpPosition : "-"} /
                ${summaryDataObj.bpLimb ? summaryDataObj.bpLimb : "-"}`,
                description: "Blood Pressure",
                color: "black",
                icon: 'water',
                updated: `${summaryDataObj.diValue_createdAt ? summaryDataObj.diValue_createdAt : null}`,
            },
            {
                key:"Rhythm",
                label: "",
                value: `${summaryDataObj.Rhythm ? summaryDataObj.Rhythm : "-"}`,
                description: "Heart Rhythm",
                icon: 'wave-square',
                updated: `${summaryDataObj.Rhythm && summaryDataObj.Rhythm_createdAt ?summaryDataObj.Rhythm_createdAt : null}`,
            },
            {
                key: "poValue",
                label: "",
                value: `${summaryDataObj.paValue ? convertValueToLabel(summaryDataObj.paValue) : "-"} /
                ${summaryDataObj.poValue ? convertValueToLabel(summaryDataObj.poValue) : "-"}`,
                description: "Pain At Rest / Pain On Movement",
                icon: 'user-injured',
                density: 2,
                updated: `${summaryDataObj.poValue_createdAt ? summaryDataObj.poValue_createdAt : null}`,
            },
            {
                key:"bgValue",
                label: "mmol/L",
                value: `${summaryDataObj.bgValue ? summaryDataObj.bgValue : "-"}`,
                description: "Blood Glucose Level",
                color: "black",
                icon: 'burn',
                labelData: ['rrLower', 'rrUpper'],
                updated: `${summaryDataObj.bgValue_createdAt ? summaryDataObj.bgValue_createdAt : null}`,
            },
            {
                key:"news",
                label: "",
                value: `${summaryDataObj.news ? summaryDataObj.news : "-"}`,
                description: "NEWS2",
                color: "black",
                icon: 'newspaper',
                updated: `${summaryDataObj.news_createdAt ? summaryDataObj.news_createdAt : null}`,
            },
            {
                key:"steps",
                label: "steps",
                value: `${excerciseData && excerciseData[0] &&  excerciseData[0].steps ? excerciseData[0].steps : "-"}`,
                description: "Steps",
                color: "black",
                icon: 'walking',
                labelData: ['stepsLower', 'stepsUpper'],
                updated : `${excerciseData && excerciseData[0] &&  excerciseData[0].steps ?  excerciseData[0].created_at : "-"}`,
            },
            {
                key:"sleep",
                label: "/hr",
                value: `${excerciseData && excerciseData[0] &&  excerciseData[0].sleep ?  excerciseData[0].sleep : "-"}`,
                description: "Sleep",
                color: "black",
                icon: 'bed',
                labelData: ['sleepLower', 'sleepUpper'],
                updated : `${excerciseData && excerciseData[0] &&  excerciseData[0].sleep ?  excerciseData[0].created_at : ""}`,
            },
            {
                key:"Comment",
                label: "",
                value:  `${summaryDataObj.Comment ? summaryDataObj.Comment : "-"}`,
                description: "Comments",
                icon: 'comment-medical',
                updated: `${summaryDataObj.Comment_createdAt ? summaryDataObj.Comment_createdAt : null}`,
            },
            {
                key:"user",
                label: "",
                value: `${summaryDataObj.doctor_firstname ? summaryDataObj.doctor_firstname : ""} ${summaryDataObj.doctor_lastname ? summaryDataObj.doctor_lastname : "-"}`,
                description: "User",
                icon: 'users',
                
            },
        ]

        let returnedDataList = [];

        if(!summaryData || !summaryParameterData) {
            debugger
            return dataList;
        }
        
        for(let i = 0; i < dataList.length ; i++) {
            if(dataList[i] && dataList[i].labelData && dataList[i].labelData.length === 2) {
                const lowerValue = summaryParameterData[dataList[i].labelData[0]]
                const higherValue = summaryParameterData[dataList[i].labelData[1]]
                
                if(dataList[i].key && lowerValue !== null && higherValue !== null) {
                    const toCmpValue = summaryData[dataList[i].key];

                    if(toCmpValue) {
                        if(toCmpValue <= lowerValue) {
                            dataList[i].statusLabel = "LOW";
                        } else if(toCmpValue >= higherValue) {
                            dataList[i].statusLabel = "HIGH";
                        }
                    }
                }
            }

            returnedDataList.push(dataList[i]);
        }

        return returnedDataList
    }, [summaryData?.length, summaryParameterData?.length, excerciseData?.length])

    const convertedDataList = changeLabel
   
    return (
        
        <div className="grid card-container indigo-container col-12"  style={{maxWidth:"100%"}}>
            {convertedDataList && convertedDataList.length > 0 && convertedDataList.map((item, idx) =>
                <div key={idx} className="p-2 lg:col-4 md:col-3 sm:col-6 ">
                    <div className="font-bold text-center p-4 border-round summary-box clearfix" style={{minHeight:"23vh"}}>
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <h5 className="block text-600 font-medium mb-3 font-bold">
                                    {item.description}
                                </h5>
                                <div className="text-left">
                                    <span className={`product-badge px-3 ${item.statusLabel ? (item.statusLabel === "HIGH" || item.statusLabel === "LOW" ) ? "status-outofstock" : "status-instock" : "status-instock"}`}>
                                        {item.statusLabel ? item.statusLabel : "NORMAL"}
                                    </span>
                                </div>
                            </div>
                            <div 
                                className="flex align-items-center justify-content-center bg-blue-100 border-round" 
                                style={{width: '2.5rem', height: '2.5rem'}}
                            >
                                <i className={`text-blue-500 text-xl fa fa-regular ${item.icon ? `fa-${item.icon}` : ''}`}></i>
                            </div>
                        </div>
                            <div className="flex justify-content-center align-items-center">
                                <div>
                                    <h3 className="text-900 font-bold  text-center ml-1"  style={{color: item.color}} dangerouslySetInnerHTML={innerHtml(item.value ? `${item.value}` : '-')}></h3>
                                </div>
                            </div> 
                        <span className="text-500"> { item.value !== "-" && item.updated ? "Updated" : ""} </span>
                        <span className="text-green-500 font-medium"> { item.value !== "-" && item.updated ? moment(item.updated).startOf('minute').fromNow()  : ""} </span>
                    </div>
                </div>
                
            )}
        </div>
    )
}



export default SummaryDetail;


