import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Dropdown, InputMask, InputText, Password, Sidebar } from 'primereact';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createDoctor, getDoctorId, updateDoctor } from '../../api/CurrentServiceWorker';
import { validEmail } from '../../utils/CommonUtils';
import PageContentWrapper from '../../utils/LayoutUtils';
import { roleTypes } from '../authentication/FirebaseAuthProvider';
import { onAuthChange } from '../authentication/FirebaseAuthService';
import { showNotification } from '../notification/NotificationService';

const DoctorForm = forwardRef(({type = 1}, ref) => {
    useImperativeHandle(ref , () => ({ 
        showForm : (data) => showForm(data)
    }))

    const topFormElement = useRef(null)

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([])
    const [visible, setVisible] = useState(false);
    const showForm = (data) => {
        setVisible(true);
        setData(data ? {
            ...data,
            password: data.password_kmh ? data.password_kmh : ""
        } : {
            type: type === 1 ? 2 : 1
        })
    }   

    const hideForm = () => {
        setVisible(false)
        setData({})
        setErrors([])
    }

    const customIcons = (
        <div 
            className='flex align-items-center px-3 ml-3' 
            style={{cursor: 'pointer', backgroundColor: 'rgba(99,102,241, 0.3)', borderRadius: 6, height: 30}} 
            onClick={() => hideForm()}
        >
            <div className='mr-3'>
                <i className="pi pi-arrow-left"/>
            </div>
            <div className='font-bold'>
              BACK TO LIST
            </div>
        </div>
    )

    let infos = [
        {
            key: 'username',
            label: "Username",
            component: "InputText"
        }, {
            key: 'password',
            label: "Password",
            component: "Password"
        }, {
            key: "email",
            label: "Email",
            component: "InputText"
        }, {
            key: "hospital",
            label: "Hospital",
            component: "InputText"
        }, {
            key: "firstname",
            label: "First Name",
            component: "InputText"
        }, {
            key: "lastname",
            label: "Last Name",
            component: "InputText"
        }
    ]

    const onChangeText = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const onChangeSelection = (e) => {
        setData({
            ...data,
            [e.target.name]: e.value
        })
    }

    const queryClient = useQueryClient();

    const {mutate: creationMutate} = useMutation(toPostData => createDoctor(toPostData), {
        onSuccess: (data, variables, context) => {
            if(data.id) {
                showNotification("success", "Success", "Create successfully !")

                queryClient.setQueryData(
                    ['doctorList', type],
                    oldDataList => oldDataList && Array.isArray(oldDataList) ? [...oldDataList, data] : [data]
                )

                hideForm()
                onAuthChange(data, roleTypes.doctor)
            }
        },
        onError: (data, variables, context) => {
            showNotification("error", "Error", "Fail to create user !")
        }
    })

    const {mutate: updationMutate} = useMutation(toPostData => updateDoctor(toPostData), {
        onSuccess: (data, variables, context) => {
            if(data && data.id) {
                showNotification("success", "Success", "Update successfully !");

                queryClient.setQueryData(
                    ['doctorList', type],
                    oldDataList => {
                        if(!oldDataList || !Array.isArray(oldDataList) || oldDataList.length < 1) {
                            return [data]
                        }

                        return oldDataList.map((item) => (item.id === data.id ? item = data : item))
                    }
                )

                hideForm();
            }
        },
        onError: (data, variables, context) => {
            showNotification("error", "Error", "Fail to update information !");
        }
    })

    const handleUpdateInfo = () => {
        const requiredKeys = ['username', 'password', 'email'];
        let missingKeys = [...requiredKeys]
        for(const key in data) {
        if(requiredKeys.includes(key) && data[key]) {
            if(key === "email" && !validEmail(data[key])) {
            missingKeys.push("invalidEmail")
            }

            if((key === "username" || key === "password") && data[key].length < 6) {
            missingKeys.push(key === "username" ? "invalidUsername" : "invalidPassword")
            }

            missingKeys = missingKeys.filter(x => x !== key)
        }
        }

        let isError = false;
        if(missingKeys.length > 0) {
            setErrors(missingKeys)
            isError = true

            showNotification("error", "Error", "Please fill in required information");
            topFormElement.current.scrollIntoView({ behavior: "smooth" });
        }

        if(!isError) {
            let toPostData = {
                ...data
            }

            if(type === 0) {
                toPostData.manager_id = null
            } else {
                toPostData.manager_id = getDoctorId();
            }

            if(data && data.id) {
                updationMutate(toPostData);
            } else {
                creationMutate(toPostData);
            }
        }
    }

    return(
        <Sidebar className="custom-sidebar" visible={visible} fullScreen onHide={() => hideForm()} dismissable={false}
            icons={customIcons} blockScroll={true}
        >
            <PageContentWrapper>
                <div style={{ float:"left", clear: "both" }} ref={topFormElement}></div>
                <div className='mb-3'>
                    <h1 className="font-bold mb-1">{data && data.id ? type === 0 ? 'Manager Information' : 'Carer Information' : type === 0 ? 'Manager Form' :  'Carer Form'}</h1>
                    <hr style={{width: '7%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
                </div>
                <div className='grid'>
                    {infos.map((item, idx) => (
                        <div key={`${item.key}-${idx}`} className='md:col-6 col-12 mb-3'>
                            {!item.hidden &&
                                <h4 className="font-bold"> {item.label}</h4>
                            }
                            {item.component === "InputText"
                            ?   
                                <div>
                                    <InputText  style={{height: '50px'}}
                                        className='col-12 font-bold p-inputtext-lg' name={item.key} value={data[item.key] || ""} 
                                        onChange={(e) => onChangeText(e)} placeholder={item.label}
                                    />
                                    {(item.key === "username" && errors.includes("invalidUsername")) ? <label className="required-error">Invalid username !!. Must contain at least 6 characters</label> : ""}
                                    {(item.key === "password" && errors.includes("invalidPassword")) ? <label className="required-error">Invalid password !!. Must contain at least 6 characters</label> : ""}
                                    {(item.key === "email" && errors.includes("invalidEmail")) ? <label className="required-error">Invalid email</label> : ""}
                                </div>
                            :
                                (item.component === "Dropdown" && !item.hidden)
                            ?   <Dropdown options={item.options}
                                    className='col-12 font-bold' name={item.key} value={data[item.key] || null}
                                    onChange={e => onChangeSelection(e)} placeholder={`Select ${item.label}`}
                                />
                            :   
                                item.component === "Password"
                            ?   <Password 
                                    name={item.key} inputClassName="col-12 font-bold px-3" inputStyle={{height: '50px', fontSize: "16px"}}
                                    className='col-12 font-bold p-0' placeholder={item.label} toggleMask
                                    value={data[item.key] || ""} onChange={(e) => onChangeText(e)}
                                />
                            :   item.component === "InputMask"
                            ?
                                <InputMask
                                    name={item.key} 
                                    className="col-12 font-bold"
                                    style={{height: '50px', fontSize: "16px"}}
                                    mask="(999) 999-9999"
                                    value={data[item.key] || ""}
                                    placeholder={item.label}
                                    onChange={(e) => setData({...data, [item.key]: e.value})}></InputMask>
                            :   null
                            }
                            
                        </div>
                    ))}
                </div>
                <hr />
                <div className='col-12'>
                    <Button
                        className="col-12 p-3"
                        label="SUBMIT"
                        onClick={() => handleUpdateInfo()}
                    />
                </div>
            </PageContentWrapper>
        </Sidebar>
    )
})

export default DoctorForm;