import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PageContentWrapper from '../../utils/LayoutUtils'

//Prime React
import { SelectButton } from 'primereact/selectbutton';
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Sidebar, Tooltip } from 'primereact';
import { consciousnessOptions, keys, oneSlider, oneSlider1, rhythmOptions, selectBtnGroups, selectBtnGroups1, twoSlider, twoSlider1 , confusionOptions, obsColWrapperData } from './Constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createPatientObs, getAllDoctors, getDoctorId, getObsColors, getPatientFirebaseId, getPatientId } from '../../api/CurrentServiceWorker';
import { reloadPatientObsNotification, showNotification } from '../notification/NotificationService';
import moment from 'moment';
import { getIndexOfValueInMultiCol } from './Utils';
import { useRef } from 'react';
import ObservationsDiaglog from './ObservationsDiaglog';
import { useCallback } from 'react';

const ObservationsForm = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showForm : () => showForm(),
    hideForm : () => hideForm()
  })) 

  const {data : doctorList} = useQuery(['doctorListData'], async() => await getAllDoctors(0))

  const {data : nurseList} = useQuery(['nurseListData'], async() => await getAllDoctors(1))


  const roleList = useMemo(() => {
    if(!doctorList && !nurseList && !Array.isArray(doctorList) && !Array.isArray(nurseList)) {
      return []
    }

    if(doctorList && nurseList && Array.isArray(doctorList) && Array.isArray(nurseList)) {
      return doctorList.concat(nurseList)
    }

    if(doctorList && Array.isArray(doctorList)) {
      return doctorList
    } 
    
    if(nurseList && Array.isArray(nurseList)) {
      return nurseList
    }
  }, [doctorList?.length, nurseList?.length])

  const observationsDiaglogRef = useRef(null)
  
  const queryClient = useQueryClient()

  const [visible, setVisible] = useState(false);
 
  const [data, setData] = useState({})  
  const [news, setNews] = useState(0)
  const [dialogData, setDialogData] = useState({})

  const showForm = () => {
    setData({})
    setVisible(true)
  }

  const hideForm = () => {
    setData({})
    setVisible(false)
  }

  const handleChangeInputSlider = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  } 

  const handleChangeSlider = (key, value) => {
    setData({
      ...data,
      [key]: value
    })
  }

  const handleChangeSelectBtn = (e) => {
    setData({
      ...data,
      [e.target.name]: e.value
    })
  }

  const onNewsValueChange = () => {
    if(data && Object.keys(data).length > 0){
      let newsObj = {
        [keys.consciousness] : data.consciousness,
        [keys.heartRate]: data.hrValue,
        [keys.respiration]: data.rrValue,
        [keys.spO2]: data.oxiValue,
        [keys.temperature]: data.tempValue,
        [keys.bloodPressure]: data.syValue
      }

      let newsValue = 0;
      let holderDialog = []
      for(const key in newsObj ) {
        if(newsObj[key]) {
          if(key === keys.consciousness) {
            const allowedConsciousnessKeys = ["Voice", "Confusion", "Unresponsive", "Pain"];
            if(allowedConsciousnessKeys.includes(newsObj[key])) {
              newsValue += 3
            } else {
              newsValue = 0
            }
          } else {
            
            for(let j = 0; j < obsColWrapperData.length; j++) {
              if(obsColWrapperData[j].key === key && obsColWrapperData[j].colors && obsColWrapperData[j].dataRange) {
                

                const colorValue = getColorByValue(obsColWrapperData[j].colors, obsColWrapperData[j].dataRange, newsObj[key], true);

                const valueOfTheColor = getValueOfTheColor(colorValue)
                
                newsValue += valueOfTheColor;

                if(valueOfTheColor === 3){
                  holderDialog.push(key)
                }
                
                break;
              }
            }
          }
        }
         else {
          newsValue = 0;
          break;
        }
      }
      
      setNews(newsValue);
      setDialogData(holderDialog)
    } 
  }

  useEffect (() => {
    onNewsValueChange()
  }, [data]) 
  
  const handleUpdateObs = () => {
    const now = moment();
    let toPostData = {
      ...data,
      patient_id: getPatientId(),
      Device: "Pc",
      created_at: moment(`${moment(now).format("YYYY-MM-DD")} ${moment(now).format("HH:mm")}`).utc().format("YYYY-MM-DD HH:mm"),
      updated_at: moment.utc().format("YYYY-MM-DD HH:mm"),
      news : news
    }
    const doctorId = localStorage.getItem('doctorId') ? JSON.parse(localStorage.getItem('doctorId')) : null
    debugger
    let filterDoctorList = roleList.filter(x => x.id === doctorId);
    if(Array.isArray(filterDoctorList) && filterDoctorList.length === 1) {
        let doctorData = filterDoctorList[0];
        debugger
        toPostData = {
          ...toPostData,
          doctor_id: doctorData.id,
          doctor_firstname: doctorData.firstname,
          doctor_lastname: doctorData.lastname

        }
    }

    if(dialogData && dialogData.length > 0) {
      let newsLog = {
        fullname:  props.fullname,
        patient_id : props.patientId,
        uid: getPatientFirebaseId(),
        data : dialogData
      }
  
      toPostData.newsLog = JSON.stringify(newsLog)
    } else {
      toPostData.newsLog = null
    }

    mutate(toPostData)
  }

  const {mutate} = useMutation(newData => createPatientObs(newData), {
    onSuccess: (data, variables, context) => {
      if(data && data.id) {
        showNotification("success", "Success", "Obs Created !");

        queryClient.setQueryData(
          ["patientObs", getPatientId(), 0],
          oldDataList => {
            if(oldDataList && Array.isArray(oldDataList)) {
              if(oldDataList.length === 24) {
                oldDataList.shift()
              }
              return [...oldDataList, {...data, created_at: moment().format("yyyy-MM-DD HH:mm")}]
            } else {
              return [{...data, created_at: moment().format("yyyy-MM-DD HH:mm")}]
            }
          }
        )
  
        queryClient.setQueryData(
          ["notificationObsData"],
          oldDataList => oldDataList && Array.isArray(oldDataList) && oldDataList.length > 0 
                              ?   [...oldDataList, data]
                              :   [data]
        )
  
        if(props.reload) {
          props.reload()
        }
  
        hideForm();

        reloadPatientObsNotification()
      } else {
        showNotification("error", "Error", "Fail to create obs !")
      }      
    },
    onError: (data, variables, context) => {
      showNotification("error", "Error", "Fail to create obs !")
    }
  })

  const getColorByValue = (colorList, dataRange, value, isNews = false) => {
    if(!colorList || !Array.isArray(colorList) || !dataRange || !Array.isArray(dataRange) || !value) {
      return ""
    }
   
    const obsColors = getObsColors();
    if(!isNews) {
      if(obsColors && obsColors.lv1) {
        colorList = colorList.map((x) => x.replace(obsColors.lv1, "#44cc61"));
      }
  
      const headColor = colorList[0];
      const tailColor = colorList[colorList.length - 1];
  
      colorList.push(tailColor);
      colorList.unshift(headColor);
    }
    
    let colorValue = "";
    let colorIndex = getIndexOfValueInMultiCol(dataRange, value);
    if(colorIndex !== null) {
      if(!isNews) {
        colorValue = colorList[colorIndex + 1];
      } else {
        colorValue = colorList[colorIndex]
      }
    }
        
    return colorValue;
  }

  const getValueOfTheColor = (color) => {
    const obsColors = getObsColors();
    if(obsColors && Object.keys(obsColors).length > 0) {
      let index = null;
      for(const key in obsColors) {
        if(obsColors[key] === color) {
          index = key;
        }
      }

      if(index) {
        switch(index) {
          case "lv1":
            return 0;
          case "lv2":
            return 1;
          case "lv3":
            return 2;
          case "lv4":
            return 3;
          default:
            return 0;
        }
      }
    }
  }

  return (
    <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen blockScroll >
      <PageContentWrapper>
          <div className='mb-3'>
              <h1 className="font-bold mb-1">Add Observation</h1>
              <hr style={{width: '7%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
          </div>
          <div className='px-0 mb-4 flex flex-column justify-content-center align-items-center'>
            <div>
              <h4 className="font-bold">Level Of Consciousness</h4>
            </div>
            <div>
              <SelectButton
                id={data && 
                  (data.consciousness === "Voice" || data.consciousness === "Pain" || data.consciousness === "Unresponsive"
                   || data.consciousness === "Confusion") ? 'activeObs' :"" }
                name={keys.consciousness}
                value={data.consciousness || null}
                options={consciousnessOptions} 
                placeholder="Select a level"
                onChange={(e) => handleChangeSelectBtn(e)}
              />
            </div>
            {data && data.consciousness === "Confusion" && 
              <div>
              <SelectButton name={keys.confusion} value={data.confusion || null}
                options={confusionOptions} onChange={(e) => handleChangeSelectBtn(e)} />
            </div>
            }
          </div>
          <div className='grid px-0'>
            {twoSlider.map((item, idx ) =>
            <div key={`${item.key}-${idx}`} className='md:col-6 col-12 mb-3'>
              <h4 className="font-bold"> {item.label} </h4>
              <InputText style={{backgroundColor: getColorByValue(item.colors, item.dataRange, data[item.key]) ? getColorByValue(item.colors, item.dataRange, data[item.key]) : "white"}} 
                className='col-12 h-3rem text-center font-bold' name={item.key}  min={item.min} max={item.max} step={item.step} value={data[item.key] || ""} 
                type="number" onChange={(e) => handleChangeInputSlider(e)} placeholder="Input here or Slide beblow"
              />
              <Tooltip target={`.slider-${item.key}>.p-slider-handle`} content={ data[item.key] ? `${data[item.key]} ${item.labelValue}` : 'No Data'} position="top" event="focus" />
              <Slider  className={`slider-${item.key} ml-3`} min={item.min} max={item.max} step={item.step} value={data[item.key] || null} onChange={(e) => handleChangeSlider(item.key, e.value)}/>
            </div>
            )}
          </div>
          <div className='col-12 px-0 mb-3'>
            {oneSlider.map((item, idx ) =>
              <div key={`${item.key}-${idx}`} className='col-12 mb-3'>
                <h4 className="font-bold"> {item.label} </h4>
                <InputText style={{backgroundColor: getColorByValue(item.colors, item.dataRange, data[item.key]) ? getColorByValue(item.colors, item.dataRange, data[item.key]) : "white"}} 
                  className='col-12 h-3rem text-center font-bold' name={item.key} min={item.min} max={item.max} step={item.step} value={data[item.key] || ''} onChange={(e) => handleChangeInputSlider(e)} 
                  type="number" placeholder="Input here or Slide beblow"/>
                <Tooltip target={`.slider-${item.key}>.p-slider-handle`} content={ data[item.key] ? `${data[item.key]} ${item.labelValue}` : 'No Data'} position="top" event="focus" />
                <Slider  className={`slider-${item.key} ml-3`} min={item.min} max={item.max} step={item.step} value={data[item.key] || null} onChange={(e) => handleChangeSlider(item.key, e.value)}/>
              </div>
            )}
          </div>
          <div className="grid px-0 mb-3">
            {selectBtnGroups.map((item, idx ) =>
              <div key={idx} className='md:col-6 col-12  mb-3'>
                <h4 className="font-bold"> {item.label} </h4>
                <div>
                  <SelectButton name={item.key} value={data[item.key]} options={item.options} onChange={(e) => handleChangeSelectBtn(e)} />
                </div>
              </div>
            )}
          </div>
          <div className='grid px-0 mb-3'>
            {twoSlider1.map((item, idx ) =>
              <div key={`${item.key}-${idx}`} className='md:col-6 col-12 mb-3'>
                <h4 className="font-bold"> {item.label} </h4>
                <InputText style={{backgroundColor: getColorByValue(item.colors, item.dataRange, data[item.key]) ? getColorByValue(item.colors, item.dataRange, data[item.key]) : "white"}}
                  className='col-12 h-3rem text-center font-bold' name={item.key}  min={item.min} max={item.max} step={item.step} value={data[item.key] || ""} onChange={(e) => handleChangeInputSlider(e)} type="number" placeholder="Input here or Slide beblow"/>
                <Tooltip target={`.slider-${item.key}>.p-slider-handle`} content={ data[item.key] ? `${data[item.key]} ${item.labelValue}` : 'No Data'} position="top" event="focus" />
                <Slider  className={`slider-${item.key} ml-3`} min={item.min} max={item.max} step={item.step} value={data[item.key] || null} onChange={(e) => handleChangeSlider(item.key, e.value)}/>
              </div>
            )}
          </div>
          <div className="grid px-0 mb-3">
            {selectBtnGroups1.map((item, idx ) =>
              <div key={idx} className='md:col-6 col-12 mb-3'>
                <h4 className="font-bold"> {item.label} </h4>
                <div>
                  
                  <SelectButton
                  id={
                    ((item.key === keys.paValue && data[item.key] !== 25) || (item.key === keys.poValue && data[item.key] !== 25)) ? 'activeObs' :"" }
                   name={item.key} value={data[item.key]} options={item.options} onChange={(e) => handleChangeSelectBtn(e)} />
                </div>
              </div>
            )}
          </div>
          <div className="col-12 px-0 mb-3">
            {oneSlider1.map((item, idx ) =>
              <div key={`${item.key}-${idx}`} className='col-12 mb-3'>
                <h4 className="font-bold"> {item.label} </h4>
                <InputText style={{backgroundColor: getColorByValue(item.colors, item.dataRange, data[item.key]) ? getColorByValue(item.colors, item.dataRange, data[item.key]) : "white"}} 
                  className='col-12 h-3rem text-center font-bold' name={item.key} min={item.min} max={item.max} step={item.step} value={data[item.key] || ''} onChange={(e) => handleChangeInputSlider(e)} 
                  type="number" placeholder="Input here or Slide beblow"
                />
                <Tooltip target={`.slider-${item.key}>.p-slider-handle`} content={ data[item.key] ? `${data[item.key]} ${item.labelValue}` : 'No Data'} position="top" event="focus" />
                <Slider  className={`slider-${item.key} ml-3`} min={item.min} max={item.max} step={item.step} value={data[item.key] || null} onChange={(e) => handleChangeSlider(item.key, e.value)}/>
              </div>
            )}
          </div>
          <div className="col-12 px-0 mb-3">
            <h4 className="font-bold">Rhythm</h4>
              <SelectButton
               id={data && 
                (data.Rhythm === "Irregular" ) ? 'activeObs' :"" }
                name={keys.rhythm}
                value={data.Rhythm || null}
                options={rhythmOptions} 
                placeholder="Select a rhythm"
                onChange={(e) => handleChangeSelectBtn(e)}
              />
          </div>
          <div className="col-12 px-0 mb-3">
            <h4 className="font-bold">Comment</h4>
            <InputTextarea className='col-12' rows={5} cols={30} autoResize 
              value={data.Comment} onChange={(e) => setData({...data, Comment: e.target.value})}
            />
          </div>
          
          
          <div className='flex justify-content-between' style={{marginBottom:'120px'}}>
            <Button  className='px-5' icon="pi pi-check" label='Update' onClick={() => observationsDiaglogRef.current.showFormDiaglog()} />
            <Button className='px-4 p-button-secondary p-button-text' icon="pi pi-times"  label='Cancel' onClick={() => hideForm()}/>
          </div>
          
          <div style={{position: "fixed", bottom:0, left:0, zIndex:10}}>
            <div  
            style={{backgroundColor:'#e2baba', borderTopRightRadius:'15px', borderBottomRightRadius:'15px',padding:'20px', border:"1px solid #f08e8e" , textAlign:'center',color:"#cd5c5c"}}>
                <label className='font-bold'style={{fontSize:'20px'}} >NEWS</label>
                <br/>
                <label className='font-bold'style={{fontSize:'60px'}} >{news}</label>
            </div>
          </div>
          <div>
            <ObservationsDiaglog dialogData={dialogData} handleUpdateObs={handleUpdateObs} ref={observationsDiaglogRef}/>
          </div>
          
      </PageContentWrapper>
    </Sidebar>
  )
})

export default ObservationsForm
