import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyAGZ_0YwCu32Gv42rcVfCG9v9E3ivCiMcQ",
    authDomain: "thinkvitals-5f0f4.firebaseapp.com",
    projectId: "thinkvitals-5f0f4",
    storageBucket: "thinkvitals-5f0f4.appspot.com",
    messagingSenderId: "338859840569",
    appId: "1:338859840569:web:d1389b99e6ed7071d0e9e8",
    measurementId: "G-FWBDN3WMKE"
};

const app = initializeApp(config);

export const auth = getAuth(app)
export const db = getDatabase(app);
export const firestore = getFirestore(app)