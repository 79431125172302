import React, { useState } from 'react'

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Dialog } from 'primereact';
import MedicineTable from './MedicineTable';
import { useQuery } from '@tanstack/react-query';
import { getMedicalData, getPatientId } from '../../api/CurrentServiceWorker';
import moment from 'moment';

const MedicinesCalendar = () => {
  const [select, setSelect] = useState()
  const [selectData, setSelectData] =useState([])
  
  const {data} = useQuery(['medicalData', getPatientId()], async() => await getMedicalData())

  const renderFooter = () => {
    return (
        <div>
          <Button label="Cancel" icon="pi pi-times" onClick={() => setSelect(false)} autoFocus />
        </div>
    );
  }

  const handleOnClick = (e) => {
    setSelect(true)
    if(data && Array.isArray(data)) {
      let placeholder = []
      for(let i = 0 ; i < data.length ; i++){
        if(data[i].startDateTime) {  
          let convertedDate = moment( moment(data[i].startDateTime).toDate() ).format("yyyy-MM-DD");
          if( e.startStr ) {
            try {
              if(convertedDate === e.startStr) {
                placeholder.push(data[i])
              }
            } catch (error) {}
          }
        }
      }
  
      setSelectData(placeholder)
    }
  }
  return (
    <div className='col-12'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        select={(e)=>handleOnClick(e)}
        selectable={true}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        events={data && data.map(item => {
          let calendarData = {
            date: item.startDateTime,
            title: item.title_medicine,
            startStr: item.startDateTime,
            endStr: item.endDateTime ? item.endDateTime : null,
            value: { ...item },             
          }
          return calendarData
        })}
      />
      <div>
        <Dialog
          header="Add Medicine"
          visible={select} 
          style={{ width: '60vw' }}
          footer={renderFooter('displayBasic')}
          onHide={() => setSelect(false)}
          >
          <MedicineTable data={selectData ? selectData : []} />
        </Dialog>
        </div>
    </div>
  )
}

export default MedicinesCalendar
