import { Button} from "primereact";
import React, { useRef } from "react";
import PageContentWrapper from "../../utils/LayoutUtils";

import SummaryDetail from "./SummaryDetail";
import SummaryParameterForm from "./SummaryParameterForm";

const Summary = () => {
    const summaryParameterForm = useRef(null);
    
    return(
        <PageContentWrapper>
            <div className='col-12 flex justify-content-between'>
                <h2 className='font-bold'> Summary</h2>
                <Button label='Add Parameter Override' icon="pi pi-plus" iconPos="left"
                    onClick={(e) => summaryParameterForm.current?.showForm()}
                    />
            </div>
            <div className="col-12">
                <div >
                    <SummaryDetail />
                </div>
            </div> 
            <div>
                <SummaryParameterForm ref={summaryParameterForm} />
            </div>
        </PageContentWrapper>
    )
}
export default Summary;