import EventEmitter from 'eventemitter3';

export const firebaseAuthEvent = new EventEmitter();

export function onAuthChange(data, type) {
    firebaseAuthEvent.emit('AUTHCHANGE', data, type)
}

export function removeAuthChange() {
    firebaseAuthEvent.removeListener('AUTHCHANGE');
}