import { getBatteryLevel } from "../api/CurrentServiceWorker";

export function lowerCase(str) {
    return str.toLowerCase();
} 

export function upperCase(str) {
    return str.toUpperCase();
}

export function capitalize(str) {
    return str.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export function convertKeyToLabel(key) {
    if(!key) {
        return "";
    }

    let convertedLabel = key
        .replace(/(_|-)/g, ' ')
        .trim()
        .replace(/\w\S*/g, function(str) {
        return str.charAt(0).toUpperCase() + str.substr(1)
        })   
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

    if(convertedLabel && convertedLabel.includes("Patient")) {
        return convertedLabel.replace("Patient", "Client")
    }

    return convertedLabel
}

export function phoneFormat(phone) {
    if(!phone) {
        return ""
    }

    return phone.substr(0, 3) + '-' + phone.substr(3, 3)+ '-' + phone.substr(6, 4);
}

export function innerHtml(data) {
    return {__html: data};
}

export function validEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validUsername(username) {
    var validcharacters = '1234567890-_.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (var i = 0, l = username.length; i < l; ++i) {
        if (validcharacters.indexOf(username.substr(i, 1)) === -1) {
            return false;
        }
        return true;
    }
}

export function numberWithCommas(n) {
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}


export const getBatteryIcon = (batteryData) => {
    if(batteryData || (getBatteryLevel() && getBatteryLevel() !== "null")) {
        const batteryLevel = batteryData ? batteryData : JSON.parse(getBatteryLevel());

        if(batteryLevel > 75) {
            return "fa-battery-full";
        } else if(batteryLevel <= 75 && batteryLevel > 50) {
            return "fa-battery-three-quarters"
        } else if(batteryLevel <= 50 && batteryLevel > 25) {
            return "fa-battery-half"
        } else if(batteryLevel <= 25 && batteryLevel > 10) {
            return "fa-battery-quarter"
        } else {
            return "fa-battery-empty"
        }
    } else {
        return "fa-battery-empty"
    }
}