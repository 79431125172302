import React from 'react';
import { Map, Marker } from "pigeon-maps"
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';

const PigeonMap = ({longtitude, latitude}) => {
    if(!longtitude || !latitude) {
        return null; 
    }
    const lattitudeNo = parseFloat(latitude);
    const longtitudeNo = parseFloat(longtitude)
    return (
        <div className='pigeon-map-wrapper'>
            <ReactVirtualizedAutoSizer>
                {({width, height}) => 
                    (   
                        <Map center={"center"} defaultCenter={[lattitudeNo, longtitudeNo]} defaultZoom={15} animations animateMaxScreens={15} height={(1000 * 1.9)} width={1200 * 3}>
                            <Marker color='red' width={50} anchor={[lattitudeNo, longtitudeNo]} />
                        </Map>
                    )
                }            
            </ReactVirtualizedAutoSizer>
        </div>
       
    )
}

export default PigeonMap