import { addDoc, collection, setDoc } from 'firebase/firestore';
import { Button, InputText } from 'primereact';
import React, { useState } from 'react';
import { signup } from '../../ firebase/FirebaseAuth';
import { firestore } from '../../ firebase/FirebaseConfig';
import { authenticateUser, setAdminId, setDoctorFirebaseId, setDoctorId, setDoctorName, setManagerId, setPatientId, setPatientName, setUserType, updateFirebaseUserId } from '../../api/CurrentServiceWorker';
import { openPage } from '../../utils/NavigatorUtils';
import { showNotification } from '../notification/NotificationService';
import { roleTypes } from './FirebaseAuthProvider';
import { onAuthChange } from './FirebaseAuthService';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const initialData = {
        username: "",
        password: ""
    }
    const [account, setAccount] = useState({...initialData})

    const handleChange = (e) => {
        setAccount({
            ...account,
            [e.target.id]: e.target.value
        })
    } 

    const handleSignInKeyPress = (e) => {
        if(e.key === 'Enter'){
            handleAuthenticate()
        }
    }

    // NOTE used for new version authentication
    // const handleAuthenticate = async() => {
    //     setIsLoading(true);

    //     await authenticateUser(account)
    //     .then(data => {
    //         if(!data || data.error) {
    //             showNotification("error", "Error", "Tên đăng nhập hoặc mật khẩu không đúng");
    //             setAccount({...initialData})
    //         } else {
    //             if(data.userInfo && data.userToken) {
    //                 const userInfo = data.userInfo;
    //                 const userToken = data.userToken;
    //                 if(userInfo.type === 3) {
    //                     clearStorage();
    //                     showNotification("error", "Error", "Unauthorized Credentials");
    //                     openPage("/");
    //                 } else {
    //                     setUserInfo(userInfo);
    //                     setUserToken(userToken);

    //                     openPage("/")
    //                 }
                    
    //             }
    //             showNotification("success", "Success", "Success Authentication")
    //         }
    //     })
    //     .finally(() => {
    //         setIsLoading(false)
    //     })
    // }

    const handleAuthenticate = async() => {
        setIsLoading(true);

        await authenticateUser(account)
        .then(data => {
            if(data && Array.isArray(data) && data.length > 0) {
                const userData = data[0];
                if(userData && userData.id) {
                    localStorage.setItem("authenticated", JSON.stringify(true));

                    if(userData.type === 0) {
                        setAdminId(userData.id)

                        openPage("/admin/doctor-management")
                    }

                    if(userData.type === 1 || userData.type === 2) {
                        setUserType(userData.type)

                        setDoctorId(userData.id)
                        setDoctorName(`${userData.firstname} ${userData.lastname}`)
                        if(userData.uid) {
                            setDoctorFirebaseId(userData.uid)
                        }

                        if(userData.type === 2 && userData.manager_id) {
                            setManagerId(userData.manager_id)
                        }
                        openPage("/")
                    }


                    if(userData.type === 3) {
                        setPatientId(userData.id)
                        setPatientName(`${userData.firstname} ${userData.lastname}`)
                        openPage("/registration")
                    }

                    
                } else {
                    showNotification("error", "Error", "Permission Denied !")
                }
            } else {
                showNotification("error", "Error", "Invalid Credential !")
            }
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    return(
        <div style={containerStyle}>
            <div className='xl:col-4 lg:col-6 md:col-8 sm:col-10 col-12 px-3'>
                <div style={cardWrapper}>
                    <div style={cardHeader}>
                        <div className='px-5 py-3' style={{width: "45%"}}>
                            <img 
                                src={require("../../assets/logo/logo.png")}
                                alt="ThinkVitals"
                                style={imageResponsive}
                            />
                        </div>
                    </div>
                    <div style={cardBody}>
                        <div className='col-12 text-center mb-2'>
                            <h2 className='font-bold mb-2'>SIGN IN</h2>
                            <h4 className="m-0">Use your credential to login</h4>
                        </div>
                        <div className='mb-2 md:col-8 sm:col-10 col-10'>
                            <h5>Username</h5>
                            <InputText id="username"
                                className="p-inputtext-lg block col-12"  
                                value={account.username || ''} 
                                onChange={handleChange} 
                                keyfilter={/[^\s]/}
                            />
                        </div>
                        
                        <div className='mb-2 md:col-8 col-10'>
                            <h5>Password</h5>
                            <InputText id="password"
                                className="p-inputtext-lg block col-12"  
                                value={account.password || ''} 
                                onChange={handleChange}
                                type="password" aria-label="Password"
                                onKeyPress={handleSignInKeyPress}
                            />
                        </div>
                    </div>
                    <div style={cardFooter}>
                        <Button  
                            label='LOGIN'   
                            loading={isLoading} 
                            loadingIcon="pi pi-spin pi-sun" 
                            className="p-button-raised"
                            style={btnStyle}
                            onClick={handleAuthenticate}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;

const centerElement = {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
} 

const imageResponsive = {
    width: '100%',
    height: '120px'
}

const containerStyle = {
    ...centerElement,
    minHeight: '100vh'
}

const cardWrapper = {
    minHeight: '40vh',
    borderRadius: 12,
    backgroundColor: 'white',
    padding: 12
}

const cardHeader = {
    ...centerElement,
    minHeight: '10vh',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(79, 70, 229, 0.05)',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12

}

const cardBody = {
    ...centerElement,
    flexDirection: 'column',
    minHeight: '30vh',
    alignItems: "center",
    margin: 5
}

const cardFooter = {
    ...centerElement,
    minHeight: '10vh',
    width: '100%',
    backgroundColor: 'rgba(79, 70, 229, 0.05)',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12
}

const btnStyle = {
    fontSize: 16,
    width: '40%'
}