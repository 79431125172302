import { Button, Dialog, InputNumber } from 'primereact'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createExerciseHeightWeight, getPatientId } from '../../api/CurrentServiceWorker';
import { showNotification } from '../notification/NotificationService';



const ExercisesHeightWeightForm = forwardRef(({exerciseData}, ref) => {
  useImperativeHandle(ref , () => ({ 
    showForm : (data) => showForm(data)
  }) )

  const queryClient = useQueryClient();

  const [visible,setVisible]= useState(false)
  const [data, setData] = useState({
    height: "",
    weight: ""
  })

  const showForm = (data) => {
    setVisible(true)
    setData({
      height: exerciseData && exerciseData.height ? exerciseData.height : "",
      weight: exerciseData && exerciseData.weight ? exerciseData.weight : ""
    })
  }

  const hideForm = () => {
    setData({});
    setVisible(false)
}
  
  const handleCreateHeightWeight = async () => {
    let toPostData = {
      ...data,
      patient_id: getPatientId(),
    }
  // NEW
    mutate(toPostData);
  }
  const { mutate } = useMutation(toAddHeightWeight => createExerciseHeightWeight(toAddHeightWeight), {
    onSuccess: (data, variables, context) => {
      showNotification("success", "Success", "Note Created");

      queryClient.setQueryData(
          ['excerciseData', getPatientId()],
          variables
      )

      hideForm();
    },
    onError: (error, variables, context) => {
      showNotification("error", "Error", "Fail to create note");
    }
})

  const renderFooter = (name) => {
    return (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => handleCreateHeightWeight()} autoFocus />
        </div>
    );
}
  return (
    <div className='col-12'>
      <Dialog
          header="Height / Weight"
          visible={visible} 
          style={{ width: '40vw' }}
          footer={renderFooter('displayBasic')}
          onHide={() => setVisible(false)}
          >
            <InputNumber
              className='col-6'
              onChange={(e) => setData({...data, height: e.value})} 
              value={data.height}
              showButtons buttonLayout="horizontal" 
              step={10}
              suffix=" Cm" 
              prefix="Height " 
              min={0}/>
            <InputNumber
              className='col-6'
              
              onChange={(e) => setData({...data, weight: e.value})} 
              value={data.weight}
              showButtons buttonLayout="horizontal" 
              step={1}
              suffix=" Kg" 
              prefix="Weight " 
              min={0}/>
        </Dialog>
      
    </div>
  )
})

export default ExercisesHeightWeightForm
