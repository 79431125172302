import axiosInstance from "../config/AxiosConfig";

//#region Terra
export function getWidget(data) {
    return axiosInstance.post(`/api/terra/getWidget`, data)
    .then(res => res.data).catch(err => console.log(err))
}
//#endregion

//#region User Account
export function authenticateUser(data) {
    return axiosInstance.post(`/api/authentication/getToken`, data)
    .then(res => res.data).catch(err => console.log(err))
}

export function renewToken() {
    
}
//#endregion