import { TabPanel, TabView } from 'primereact';
import React from 'react';
import { getUserType } from '../../api/CurrentServiceWorker';
import DoctorManagement from '../doctor/DoctorManagement';
import NotificationComponent from '../notification/NotificationComponent';
import PatientDatatable from '../patient/PatientDatatable';

const Home = () => {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return(
        <div className='flex align-items-center justify-content-center'>    
            <div className='lg:col-10 md:col-12 col-12'>
                <div className="grid">
                    <div className="col-12 lg:col-8">
                        <div className="card mb-0">
                            <div>
                                <TabView>
                                    <TabPanel header="Client List">
                                        <h6 className='mb-3'><strong>Select a client to see their information and records</strong></h6>
                                        <PatientDatatable />
                                    </TabPanel>
                                    
                                    {getUserType() !== 2 &&
                                        <TabPanel header="Carer List">
                                            <h6><strong>Select a carer to see their information</strong></h6>
                                            <DoctorManagement type={1} hideText={true}/>
                                        </TabPanel>
                                    } 
                                </TabView>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-4">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                              <NotificationComponent scrollToTop={handleScrollToTop}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
