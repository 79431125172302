import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch } from 'react-router-dom'
import ScrollToTop from '../src/utils/ScrollToTop';
import LoadingOverlay from '../src/components/loading/LoadingOverlay'
import Notification from '../src/components/notification/Notification'
import axiosInstance from '../src/config/AxiosConfig'
import { getAccessToken, isAuthenticated } from './utils/AuthUtils';
import Login from './components/authentication/Login';

// NOTE used for new version of authentication
// isAuthenticated() ? axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}` : delete axiosInstance.defaults.headers.common["Authorization"];

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <ScrollToTop>
                {isAuthenticated() 
                ?   <App />
                :   <Login/>
                }
                
                <Notification />
                <LoadingOverlay />
            </ScrollToTop>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();