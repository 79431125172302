import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { notificationEvent } from './NotificationService';

const Notification = () => {
    const toastRef = useRef(null);

    useEffect(() => {
        notificationEvent.on('NOTIFY', (severity, summary, detail) => showNotification(severity, summary, detail))

        return () => notificationEvent.removeListener('NOTIFY', hideNotification)
    }, [])

    const showNotification = ({severity, summary, detail}) => {
        toastRef.current.show({severity, summary, detail});
    }

    const hideNotification = () => {
        toastRef.current.clear();
    }

    return(
        <Toast ref={toastRef} baseZIndex={3070} />        
    )
}

export default Notification;