export const GroupMedical = [
    "Analgesics",
    "Anesthetics",
    "AntiBacterials",
    "Antivirals",
    "Antidementia",
    "Antidepressants",
    "Blood Glucose",
    "Antifungals",
    "Cardiovascular",
    "Inflamatory Bowel",
    "Antigout",
    "Antinflamatories",
    "Antimigraine",
    "Ophthalmic",
    "Gastrointestinal",
    "Sexual Disorder",
    "Muscle Relaxants",
    "Sleep Disorder",
    "Hormonal",
    "Antiobesity",
    "Vitamins",
    "Dermatology",
    "Dental",
  ];
  
  export const InstructionsMedical = [
    "No alcohol",
    "If dose is missed take the next time do not double up doses",
  ];
  
  export const FrequencyMedical = [
    "Take after meals",
    "Once per day with Food",
    "To be taken before bed",
  ];
  export const RepeatMedical = ["day/s", "week/s", "month/s", "year/s"];
  