import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getAllDoctors, getDoctorId, getListPatients, setBatteryLevel, setPatientFirebaseId, setPatientId, setPatientName } from '../../api/CurrentServiceWorker';
import { Button, Dropdown, Tooltip, TreeTable } from 'primereact';
import { openPage } from '../../utils/NavigatorUtils';
import { useQuery } from '@tanstack/react-query';
import { SpeedDial } from 'primereact/speeddial';
import PatientForm from './PatientForm';
import { convertDataList, converteDataListToTreeTable } from '../doctor/Ultils';

const PatientDatatable = () => {
    const patientForm = useRef(null)
    const [pageable, setPageable] = useState({
        rows: 10,
        pageNumber: 0
    })

    const {data: patientDataList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients()
    )
    const {data: nurseDataList} = useQuery(
        ['doctorList', 1],
        () => getAllDoctors(),
        {
            enabled: !!getDoctorId()
        }
    )

    const {data: carerDataList} = useQuery(
        ['doctorList', 2],
        () => getAllDoctors(),
        {
            enabled: !!getDoctorId()
        }
    )


    const reverseNurseDataList = nurseDataList && Array.isArray(nurseDataList) ? nurseDataList.reverse() : []
    const reversePatientDataList = patientDataList && Array.isArray(patientDataList) ? patientDataList.reverse() : []
    
    const dataList = useMemo(() => {
        return convertDataList(reversePatientDataList, reverseNurseDataList, "nurseList", "patientComp")
    }, [reverseNurseDataList?.length, reversePatientDataList?.length])

    const dataTreeTable = useMemo(() => {
        return converteDataListToTreeTable(dataList,"patient_id", "doctor_id", "nurseList","patient", "nurse")
    }, [reverseNurseDataList?.length, reversePatientDataList?.length])
    
    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];

        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />;
    }

    const renderHeader = () => {
        return(
            <div className='col-12 flex justify-content-between p-0'>
                {/* <Tooltip target=".speeddial-tooltip .speeddial-top .p-speeddial-action" position="top" />
                <SpeedDial className='custom-right-speed-dial speeddial-top' model={items} direction="right" showIcon="pi pi-bars"/> */}
                <Button label='Add Client' onClick={() => patientForm.current?.showForm()} icon='pi pi-plus' iconPos='left'/>
                {paginatorTemplate()}
            </div>
        )
    }

    const handleViewPatient = (patient) => {
        setPatientId(patient.patient_id);  
        setPatientFirebaseId(patient.uid);
        
        let patientName = "";
        patientName += patient.first_name ? patient.first_name : "";
        patientName += patient.last_name ? ` ${patient.last_name}` : "";
        setPatientName(patientName)
        setBatteryLevel(patient.battery_level)
        
        openPage(`/patient/${patient.patient_id}`)
    }

    return(
        <React.Fragment>
            {/* <DataTable 
                header={renderHeader()} responsiveLayout="stack" breakpoint="960px"
                filterDisplay="row" emptyMessage="No patients found"
                value={dataList ? dataList : []} sortField="patient_id" sortOrder={-1}
                paginator onPage={(e) => setPageable({...pageable, pageNumber: e.page})}
                totalRecords={dataList ? dataList.length : 0} first={(pageable.pageNumber * pageable.rows)} rows={pageable.rows}
            >
                <Column header="Patient ID" filterField='patient_id' filter filterPlaceholder="Search by ID" showFilterMenu={false}
                    body={(rowData) => <div>{rowData.patient_id ? `#${rowData.patient_id}` : "-"}</div>}
                />
                <Column header="Full Name" filterField='first_name' filter filterPlaceholder="Search by name" showFilterMenu={false} 
                    body={(rowData) => <div>{!rowData.first_name && !rowData.last_name ? "-" : `${rowData.first_name} ${rowData.last_name}`}</div>}
                />
                <Column header="Date Of Birth" filterField='dob' filter filterPlaceholder="Search by dob" showFilterMenu={false}
                    body={(rowData) => <div>{!rowData.dob ? "-" : `${rowData.dob}`}</div>}
                />
                <Column header="Action" body={(rowData) => <Button type="button" icon="pi pi-search" onClick={() => handleViewPatient(rowData)}></Button>} />
            </DataTable> */}
            <div className="treetable-responsive px-0">
                <div className="card px-0">
                    <TreeTable header={renderHeader()}  value={dataTreeTable ? dataTreeTable : []} rowHover  responsiveLayout="stack" breakpoint="960px" 
                    paginator first={pageable.pageNumber * pageable.rows} rows={pageable.rows}
                    onPage={e => setPageable({...pageable, pageNumber: e.page})} totalRecords={dataList ? dataList.length : 0}
                    >
                        <Column field="id" header="ID" expander></Column>
                        <Column field="fullname" header="Full Name" body={(rowData) => rowData.data.fullname ? rowData.data.fullname : rowData.data.firstname || rowData.data.lastname ? `${rowData.data.firstname} ${rowData.data.lastname}` : "-"}></Column>
                        <Column header="Role" body={(rowData) => rowData.data && rowData.data.type && rowData.data.type === 2 ? "Carer" : "Client"}></Column>
                        <Column field="username" header="Username"></Column>
                        <Column field="email" header="Email" body={(rowData) => <div style={{wordWrap: "break-word"}}>{rowData.data.email}</div>}></Column>
                        <Column header="Action" body={(rowData) =>  rowData.data?.type === "patient" && <Button type="button" icon="pi pi-search" onClick={() => handleViewPatient(rowData.data)}></Button>} />
                    </TreeTable>
                </div>
            </div>
            <PatientForm 
                ref={patientForm}
            />
        </React.Fragment>
    )
}

export default PatientDatatable;