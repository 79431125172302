import { Button } from 'primereact'
import React, {  useRef } from 'react'
import PageContentWrapper from '../../utils/LayoutUtils'
import MedicineForm from './MedicineForm'
import MedicinesCalendar from './MedicinesCalendar'

const Medicine = ({rerender}) => {
  const medicineForm = useRef(null);
  return (
    <PageContentWrapper>
        <div className='col-12 flex justify-content-between'>
          <div>
             <h2 className='font-bold'> Pharmacy </h2>
          </div>
          <div>
            <Button label="Add Medicine" icon="pi pi-external-link" onClick={() => medicineForm.current?.showForm()}/>
          </div>
        </div>
        <div className='col-12'>
          <MedicinesCalendar/>
        </div>
        <div>
          <MedicineForm ref={medicineForm}/>
        </div>
    </PageContentWrapper>
  )
}

export default Medicine
