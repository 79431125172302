import { Button, Dialog, InputNumber, InputTextarea } from 'primereact'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createParameter, getAllDoctors, getDoctorId, getParameterData, getPatientId, getUserType } from '../../api/CurrentServiceWorker'
import { showNotification } from '../notification/NotificationService'
import moment from 'moment'
import SummaryParameterTable from './SummaryParameterTable'

export const initialParameterValue = {
    hrLower: 41,
    hrUpper: 110,
    rrLower: 9,
    rrUpper: 20,
    tempLower: 35.1,
    tempUpper: 39,
    syLower: 101,
    syUpper: 219,
    oxiLower: 94,   
    oxiUpper: 101,
    bgLower: 101,
    bgUpper: 4,
    stepsLower: 1,
    stepsUpper: 2000,
    sleepLower: 6,
    sleepUpper: 10,
}

const SummaryParameterForm = forwardRef(( props, ref) => {
    useImperativeHandle(ref , () => ({ 
        showForm : (data) => showForm(data)
    }) )

    const queryClient = useQueryClient();
    const [visible, setVisible] = useState()
    const {data: parameterData} = useQuery(['summaryParameterData', getPatientId()], async() => await getParameterData())
    const reversedparameterData = parameterData && Array.isArray(parameterData) ? parameterData.sort((a,b) => b.id - a.id) : [];
    const summaryParameterData = reversedparameterData.length > 0 ? reversedparameterData[0] : {}
    const initialValue = summaryParameterData && Object.keys(summaryParameterData).length > 0 ? summaryParameterData : initialParameterValue
    const currentParameterData = initialValue;    
    const [data, setData] = useState(initialValue ? initialValue : {})

    // 0 - Admin -> DoctorList
    // 1 - Doctor -> NurseList
    const {data : doctorList} = useQuery(['doctorListData'], async() => await getAllDoctors(getUserType() === 2 ? 1 : 0))

    const showForm = (data) => {
        setData(initialValue)
        setVisible(true)
      }
    
      const hideForm = () => {
        setVisible(false)
    }
      const handleCreateParameter = async () => {
        let toPostData = {
          ...data,
          patient_id: getPatientId(),
          doctor_id: getDoctorId(),
        }

        // LOG REGION
        let logsList = [];
        if(data.logs) {
            logsList = JSON.parse(data.logs);
        }

        const doctorId = localStorage.getItem('doctorId') ? JSON.parse(localStorage.getItem('doctorId')) : null
        let filterDoctorList = doctorList.filter(x => x.id === doctorId);
        if(Array.isArray(filterDoctorList) && filterDoctorList.length === 1) {
            let doctorData = filterDoctorList[0];
            let logData = {
                ...currentParameterData,
                doctorId: doctorData?.id,
                email: doctorData?.email,
                first_name: doctorData?.firstname,
                last_name: doctorData?.lastname,
                timestamp: moment(new Date()).format("yyyy-MM-DD HH:mm")
            }

            const currentLogs = currentParameterData.logs ? JSON.parse(currentParameterData.logs) : []
            if(currentLogs.length > 0) {
                // old logs
                logsList = [...currentLogs];
                logsList.push({...logData, logs: null})
            } else {
                logsList.push({...logData, logs: null})
            }           
        }

        toPostData.logs = JSON.stringify(logsList);
        // END REGIOn

        // NEW
        mutate(toPostData);
      }
      const { mutate } = useMutation(value => createParameter(value), {
        onSuccess: (data, variables, context) => {
            showNotification("success", "Success", "Paramater Created");

            queryClient.setQueryData(
                ['summaryParameterData', getPatientId()],
                () => [variables]
            )
    
            hideForm();
        },
        onError: (error, variables, context) => {
            showNotification("error", "Error", "Fail to create note");
        }
    })

    
    const classNameParmeter = {
        simple: "col-12",
        double:"col-6"
    }

    const componentTypes = {
        input: "InputText",
        inputArea: "InputArea",
        multiCheckbox:"MultiCheckbox",
        
    }

    const parameterForm = [
        {   
            key: "hrLower",
            title: "Heart Rate Lower (HRL)",
            label: " BPM",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "hrUpper",
            title: "Heart Rate Upper (HRU)",
            label: " BPM",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "rrLower",
            title: "Respiration Rate Lower (RRL)",
            label: " BPM",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "rrUpper",
            title: "Respiration Rate Upper (RRU)",
            label: " BPM",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "tempLower",
            title: "Temperature Lower (TL)",
            label: " °C",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "tempUpper",
            title: "Temperature Upper (TU)",
            label: " °C",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "syLower",
            title: " Systolic BP Lower (SBL)",
            label: " mm Hg",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "syUpper",
            title: "Systolic BP Upper (SBU)",
            label: " mm Hg",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "oxiLower",
            title: "Oxygen Saturation Lower (OSL)",
            label: " %",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "oxiUpper",
            title: "Oxygen Saturation Upper (OSU)",
            label: " %",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "bgLower",
            title: "Blood Glucose Lower (BGL)",
            label: " mmol/L",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "bgUpper",
            title: "Blood Glucose Upper (BGU)",
            label: " mmol/L",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "stepsLower",
            title: "Steps Lower (SL)",
            label: " steps",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "stepsUpper",
            title: "Steps Upper (SU)",
            label: " steps",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "sleepLower",
            title: "Sleep Lower (SLL)",
            label: " /hr",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "sleepUpper",
            title: "Sleep Upper (SLU)",
            label: " /hr",
            classname: classNameParmeter.double,
            type: componentTypes.input
        },
        {
            key: "Comment",
            label: " mmol/L",
            classname: classNameParmeter.simple,
            type: componentTypes.inputArea
        },
    ]
    const handleChangeInput = (e) => {
        
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }    

    const renderHeader= ( ) => {
        return(
            <h3 className='fon-bold'>Parameter Override</h3>
        )
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => handleCreateParameter()} autoFocus />
            </div>
        );
    }
  return (
    <Dialog
        header={renderHeader()}
        visible={visible} 
        style={{ width: '70vw' }}
        footer={renderFooter('displayBasic')}
        onHide={() => setVisible(false)}
        >
        <div className="grid col-12 " >
            {parameterForm.map((item, idx) => (
                item.type === componentTypes.input 
                ?
                <div key={idx} className={item.classname}>
                    <h5 className='col-12 p-0 font-bold'>{ item.title}</h5>
                    <InputNumber
                        className='col-12 p-inputtext-lg font-bold '
                        inputClassName="font-bold text-center"
                        showButtons buttonLayout="horizontal" 
                        rows={3}
                        suffix={item.label} 
                        min={0}
                        name={item.key} 
                        value={data[item.key] || item.initialValue} 
                        onChange={(e) => setData({...data, [item.key]: e.value})}/>
                </div>
                :
                item.type === componentTypes.inputArea
                &&
                <div key={idx} className={item.classname}>
                    <h5 className='col-12 p-0'>{ item.key}</h5>
                    <InputTextarea
                        autoResize
                        className='col-12 p-inputtext-lg'
                        rows={3}
                        placeholder={""}
                        name={item.key} 
                        value={data[item.key] || item.initialValue} 
                        onChange={(e) => handleChangeInput(e)}/>
                </div>
            ))}
            <hr className='col-12'/>
            <div className='col-12'>
                <SummaryParameterTable data={summaryParameterData.logs}/>
            </div>
        </div>
    </Dialog> 
  )
})

export default SummaryParameterForm
