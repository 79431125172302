import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column, DataTable } from 'primereact'
import React from 'react'
import { getPatientId, getSummaryData } from '../../api/CurrentServiceWorker'

const ObservationTable = () => {
    const {data} = useQuery(['summaryData', getPatientId()], async() => await getSummaryData())
    const columns = [
        {key: 'rrValue',label:'RR'},
        {key: 'oxiValue',label:'SpO2'},
        {key: 'hrValue',label:'HR'},
        {key: 'tempValue',label:'Temp'},
        {key: 'bgValue',label:'G'},
        // {key: 'fall',label:'Fall'},
      ];
      const dynamicColumns = columns.map((col) => {
        return <Column key={col.key} header={col.label} body={(rowData) => <strong>{rowData[col.key] ? rowData[col.key] : "-"}</strong>}/>;
    });
        
      return (
        <div>
            <div className='font-bold m-2'>
              Last updated at {data && data[0] && moment(data[0].created_at).format("YYYY-MM-DD HH:ss")} 
              - Made by 
              { data && data[0] 
              ? (data[0].doctor_firstname && data[0].doctor_lastname) 
                ? ` ${data[0].doctor_firstname} ${data[0].doctor_lastname}` 
                : data[0].doctor_firstname
                  ? ` ${data[0].doctor_firstname}` 
                : ` ${data[0].doctor_lastname}` 
              : " No Data"}
            </div>
            <DataTable className='col-12' value={data} responsiveLayout="scroll">
                {dynamicColumns}
            </DataTable>
        </div>
      )
}

export default ObservationTable
