import React, { createRef, useEffect, useRef, useState } from 'react'
import PageContentWrapper from '../../utils/LayoutUtils'
import { bloodPressureDataRange, consciousnessOptions, dataCols, displayCols, headerData, holderValue, keys, obsColWrapperData, painOptions, pulseDataRange, respirationDataRange, rhythmOptions, shortenValueKeys, spO2DataRange, temperatureDataRange } from './Constants';

//Prime React
import { Button } from 'primereact/button';

import ObservationsForm from './ObservationsForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQuery } from '@tanstack/react-query';
import { getIncrement, getPatientId, getPatientInfoById, getPatientObs, setIncrement, setLineChartDrawn } from '../../api/CurrentServiceWorker';
import { capitalize, lowerCase } from '../../utils/CommonUtils' 
import moment from 'moment';
import { getIndexOfValueInMultiCol, getLevelLabelByValue, getShortenFromValue, getValueByKeyAtPosition, getValueByKeyForChart } from './Utils';
import useWindowSize from '../../utils/useWindowSize'
import { v4 as uuidv4 } from 'uuid';
import ObservationConfig from './ObservationConfig';
import { menuEvent, removeOnMenuChanged } from '../menu/MenuService';
import ObservationTable from './ObservationTable';
import { useScreenshot } from 'use-react-screenshot'
import {showLoading, stopLoading} from '../loading/LoadingService';
import ObservationPdfPreview from './ObservationPdfPreview';
import { showNotification } from '../notification/NotificationService';

const  Observations = () => {
  const chartWrapper = useRef(null)
  const obsForm = useRef(null)
  const obsConfig = useRef(null)
  const width = useWindowSize();

  // PDF REGION
  const obsPdf = useRef(null)
  const screenshotRef = useRef(null)
  const [image, takeScreenshot] = useScreenshot()
  const getImage = () => takeScreenshot(screenshotRef.current)

  const handleTakeScreenshot = () => {
    showNotification("info", "Information", "Your request is processing !")

    // const tempSvgHolder = window.document.getElementById("obs-svg");
    // if(tempSvgHolder) {
    //   const lineChartWrapper = window.document.getElementById("leader-line-defs");
    //   if(lineChartWrapper) {
    //     const clone = lineChartWrapper.cloneNode(true)
    //     tempSvgHolder.appendChild(clone)
    //   }    
  
    //   const lines = window.document.getElementsByClassName("leader-line");
    //   if(lines && lines.length > 0) {
    //     const linesTmp = [...lines]
    //     for(let i = 0; i < linesTmp.length; i++) {
    //       if(linesTmp[i]) {
    //         const clone = linesTmp[i].cloneNode(true)
    //         tempSvgHolder.appendChild(clone)
    //       }
    //     }
    //   }
    // }

    getImage()
  }
  // EBD REGION
  
  const [showColors, setShowColors] = useState(true)
  const [reDraw, setReDraw] = useState(0)

  const {data: patientInfoList} = useQuery(
    ['patientDetail', getPatientId()],
    async() => await getPatientInfoById()
  )

  const patientInfo = patientInfoList && Array.isArray(patientInfoList) ? patientInfoList[0] : {}

  const [pageNumber, setPageNumber] = useState(0)
  const {data : obsData, isLoading: isLoadingObs} = useQuery(
    ["patientObs", getPatientId(), pageNumber],
    async() => await getPatientObs(pageNumber) 
  )

  const convertObsData = (obsData) => {
    if(!obsData) {
      return [];
    } 

    let lastestObs = obsData.slice(0, displayCols);

    return lastestObs.sort((a,b) => {
      return a.id - b.id
    })
  }

  const data = convertObsData(obsData || []);

  const drawChart = (data, key) => {
    if(!data || !key) {
      return;
    }

    let chartData = getValueByKeyForChart(data, key);
    if(chartData && chartData.length > 0) {
      
      const lastIndex = chartData.lastIndexOf(chartData[chartData.length - 1]);
      const colData = obsColWrapperData.find(x => x.key === key);

      for(let i = 0; i < chartData.length; i++) {
        if(key === keys.bloodPressure) {
          const syIndex = getIndexOfValueInMultiCol(colData.dataRange, chartData[i].syValue);
          const diIndex = getIndexOfValueInMultiCol(colData.dataRange, chartData[i].diValue);

          if(syIndex !== null && diIndex !== null) {
            try {
              const start = `${key}-${chartData[i].syIndex}-${syIndex}`;
              const end = `${key}-${chartData[i].diIndex}-${diIndex}`;
              const line = new window.LeaderLine(
                document.getElementById(start),
                document.getElementById(end),{
                  size: 2,
                  path: 'straight',
                  color: 'black',
                  endPlug: 'behind',
                  startLabel: window.LeaderLine.captionLabel(
                    `${chartData[i].bpLimb && chartData[i].bpLimb !== "undefined" ? chartData[i].bpLimb.substring(0, 2) : ""} 
                    ${chartData[i].bpPosition && chartData[i].bpPosition !== "undefined" ? chartData[i].bpPosition.substring(0, 2) : ""}`, 
                    {color: 'black', fontSize: 12, stroke: 'red',  offset: [-15, -10]})
                }
              );
            } catch (error) {}
          }
        } else {
          if((lastIndex !== i) && (chartData[i].index === (chartData[i + 1].index - 1))) {
            const dataIndexInCol = getIndexOfValueInMultiCol(colData.dataRange, chartData[i].value);
            const dataIndexInColIncreased = getIndexOfValueInMultiCol(colData.dataRange, chartData[i + 1].value);
            if(dataIndexInCol !== null) {
              try {
                const start = `${key}-${chartData[i].index}-${dataIndexInCol}`;
                const end = `${key}-${chartData[i + 1].index}-${dataIndexInColIncreased}`;
                new window.LeaderLine(
                  document.getElementById(start),
                  document.getElementById(end),{
                    size: 2,
                    path: 'straight',
                    color: 'black'
                  }
                );
              } catch (error) {}
            }
          }    
        }
      }
    }
  }

  const drawLineCharts = () => {
    removeExistingLineCharts();
    
    if(data && data.length > 0) {
      const chartKeys = [keys.respiration, keys.spO2, keys.bloodPressure, keys.heartRate, keys.temperature];
      for(let i = 0; i < chartKeys.length; i++) {
        drawChart(data, chartKeys[i]);
      }

      setLineChartDrawn(true)
    }
  }

  const removeExistingLineCharts = () => {
    const lineChartWrapper = window.document.getElementById("leader-line-defs");
    if(lineChartWrapper) {
      lineChartWrapper.remove();
    }    

    const lines = window.document.getElementsByClassName("leader-line");
    if(lines && lines.length > 0) {
      for (var i = lines.length - 1; i >= 0; i--) {
        lines[i].remove();
      }
    }
  }

  useEffect(() => {
    drawLineCharts();

    return () => {
      removeExistingLineCharts();
    }
  }, [getIncrement(), width])

  useEffect(() => {
    menuEvent.on("menuChanged", () => {
      if(data && data.length > 0) {
        setTimeout(() => {
          setReDraw(getIncrement() + 1)
        }, 200)
      } else {
        setTimeout(() => {
          setReDraw(getIncrement() + 1)
        }, 200)
      }
    })

    return () => removeOnMenuChanged()
  }, [data])
  //#region OBS TABLE 
  

  const convertSingleColData = (key, data, type, isShorten = false) => {
    let returnValue = ""; 

    switch(key) {
      case keys.createdAt: 
        if(data[key]) {
          if(type === "time") {
            if( data.Device === "watch" || data.Device === "mobile") {
              returnValue = data[key].substring(11, 16)
            } else {
              returnValue = moment(data[key]).format("HH:mm")
            }
          } else {
            returnValue = data[key] ? moment(data[key]).format("DD/MM") : "";
          }
        }
        break;
      case keys.painAtRest:
        returnValue = isShorten ? getShortenFromValue(data[key], painOptions) : getLevelLabelByValue(data[key]);
        break;
      case keys.painOnMovement:
        returnValue = isShorten ? getShortenFromValue(data[key], painOptions) : getLevelLabelByValue(data[key]);
        break;
      case keys.rhythm:
        returnValue = isShorten ? getShortenFromValue(data[key], rhythmOptions) : data[key];
        break;
      case keys.device:
        returnValue = data[key] && data[key][0] ? capitalize(data[key][0]) : data[key];
        break;
      case keys.initials:
        let firstname = data.doctor_firstname && data.doctor_firstname[0] ? capitalize(data.doctor_firstname[0]) : ""
        let lastname = data.doctor_lastname && data.doctor_lastname[0] ? capitalize(data.doctor_lastname[0]) : "";

        let initials = firstname && lastname ? `${firstname}.${lastname}` : firstname ? firstname : lastname;

        returnValue = initials;
        break;
      default:
        returnValue = data[key] 
        break;
    }

    return returnValue;
  }

  const convertMultipleColData = (key, data, idx, isShorten = false) => {
    let returnValue = "";
    switch(key) {
      case keys.respiration:
        returnValue = getValueByKeyAtPosition(respirationDataRange, key, data, idx);
        break;
      case keys.spO2:
        returnValue = getValueByKeyAtPosition(spO2DataRange, key, data, idx);
        break;
      case keys.heartRate:
        returnValue = getValueByKeyAtPosition(pulseDataRange, key, data, idx);
        break;
      case keys.bloodPressure:
        returnValue = getValueByKeyAtPosition(bloodPressureDataRange, "diValue", data, idx);
        if(!returnValue) {
          returnValue = getValueByKeyAtPosition(bloodPressureDataRange, "syValue", data, idx);
        }
        break;
      case keys.consciousness:
        returnValue = idx === 0 ? (data[key] && isShorten) ? getShortenFromValue(data[key], consciousnessOptions) : data[key] : "";
        break;
      case keys.temperature:
        returnValue = getValueByKeyAtPosition(temperatureDataRange, key, data, idx);;
        break;
    }

    if(showColors && returnValue === "") {
      return holderValue;
    } else {
      return returnValue;
    }
  }

  const renderColData = (rowData, data, dateTime, i) => (
    <div>
      {rowData.colNumber > 1 && rowData.extraColData && rowData.extraColData.length > 0
      ?
        <div className='col-12 p-0 flex flex-column align-items-center justify-content-between' style={{height: rowData.extraColData.length * 23}}>
          {rowData.extraColData.map((row, idx) => {
            const dataValue = convertMultipleColData(rowData.key, data, idx, true);

            return(
              <div key={`${rowData.key}-${row}-colData-${idx}`} className={`font-bold ${dataValue && dataValue !== holderValue ? 'px-2' : 'px-3'} tooltip px-0 text-center`} 
                style={{
                  backgroundColor: rowData.colors ? rowData.colors[idx] : "", 
                  cursor: 'pointer', height: '23px', width: '100%', 
                  marginBottom: '0.5px', paddingTop: '2px', 
                  border: '0.1px solid rgba(204, 204, 204, 0.1)'
                }}
              >
                {dataValue && dataValue !== holderValue && 
                  <span className="tooltiptext-top">
                    {rowData.label ? <div>{rowData.label}</div> : ""}
                    {rowData.extraColData[idx] ? <div><div>{rowData.extraColData[idx]}</div> {shortenValueKeys.includes(rowData.key) ? <div>{convertMultipleColData(rowData.key, data, idx, false)}</div> : ""}</div> : ""} 
                    {dateTime ? <div>{dateTime}</div> : ""}
                  </span>
                }
                <span id={`${rowData.key}-${i}-${idx}`}>{dataValue}</span>
              </div>
            )
          })}
        </div>
      :   
        <div className='flex justify-content-center align-items-center'>
          <div className={`${shortenValueKeys.includes(rowData.key) ? 'tooltip' : ''} text-center font-bold`}>
            {
              shortenValueKeys.includes(rowData.key) &&
              <span className="tooltiptext-top">
                <div className='text-center'><p>{convertSingleColData(rowData.key, data, lowerCase(rowData.label), false)}</p></div>
              </span>
            }
            
            {rowData.key === "Comment" && convertSingleColData(rowData.key, data, lowerCase(rowData.label)) 
            ? <i className='pi pi-bookmark'></i> 
            : convertSingleColData(rowData.key, data, lowerCase(rowData.label), true)}
          </div>
        </div>
      } 
    </div>
  )

  const renderEmptyColData = (rowData) => (
    <div>
      {rowData.colNumber > 1 && rowData.extraColData && rowData.extraColData.length > 0
      ?
        <div className='col-12 p-0 flex flex-column align-items-center justify-content-between' style={{height: rowData.extraColData.length * 23}}>
          {rowData.extraColData.map((row, idx) => {
            return(
              <div key={`${uuidv4()}`} className={`font-bold px-3 tooltip px-0 text-center`} 
                style={{
                  backgroundColor: rowData.colors ? rowData.colors[idx] : "", 
                  cursor: 'pointer', height: '23px', width: '100%', 
                  marginBottom: '0.5px', paddingTop: '2px', 
                  border: '0.1px solid rgba(204, 204, 204, 0.1)'
                }}
              >
                <span>{` `}</span>
              </div>
            )
          })}
        </div>
      :   
        <div className={`${rowData.key === "Comment" ? 'tooltip' : ''} text-center`}>
          {` `}
        </div>
      } 
    </div>
  )

  const renderColBody = () => {
    let cols = [];
   
    for(let i = 0; i < dataCols; i++) {
      cols.push(
        <Column key={`${data[i]}-${i}`}
          style={{width: '3%'}}
          body={(rowData) => {
            let dateTime = "";
            if(data[i] && data[i].created_at) {
              let date = moment(data[i].created_at).format("DD/MM/yyyy");

              let time = "";
              if(data[i].Device === "watch" || data[i].Device === "mobile") {
                time = data[i].created_at.substring(11, 16)
              } else {
                time = moment(data[i].created_at).format("HH:mm")
              }

              if(date && time) {
                dateTime = `${date} ${time}`;
              }
            }

            return(
              data[i] ? renderColData(rowData, data[i], dateTime, i) : renderEmptyColData(rowData)
            )
          }}
        />
      )

      setIncrement();
    }

    return cols;
  }

  const renderColWrapper = (rowData, type) => {
    return (
      <React.Fragment>
        <div style={{backgroundColor: "rgba(99, 102, 241, 0.5)"}}>
            {rowData.colNumber > 1 
            ?
              <div className='flex flex-row'>
                <div className='flex justify-content-center align-items-center font-bold p-0' style={{minWidth: 120}}>
                  {rowData.label}
                </div>
                <div className='p-0' style={{backgroundColor: "white", minWidth: 90}}>
                  {rowData.extraColData.map((extraData, eIdx) => (
                    <div key={`${type}-${extraData}-${eIdx}`} className="p-1 text-center  font-bold">
                      {extraData}
                    </div>
                  ))}
                </div>
              </div>
            :
              <div className='flex justify-content-center align-items-center font-bold' style={{minWidth: 40, padding: 10}}>{rowData.label}</div>
            }          
        </div>
      </React.Fragment>
    )
  }

  const renderTableHeader = () => {
    return(
      <div className='col-12 p-0'>
        <PageContentWrapper mb={0}>
          <div className='grid'>
            {headerData.map((item, idx) => (
              <div key={`${item.key}-${idx}`} className="col flex justify-content-center align-items-center">
                {item.key === keys.ratingScale
                ?  
                  // Scale Info
                  <div className='flex'>
                    {item.data.map((cItem, cIdx) => (
                      <div 
                        key={`${item.key}-${idx}-${cIdx}`}
                        style={{
                          border: '1px solid rgba(221, 221, 221, 0.35)',
                          backgroundColor: cItem,
                          textAlign: "center",
                          padding: "5px 10px",
                          cursor: "default"
                        }}
                      >
                        {cIdx}
                      </div>  
                    ))}
                  </div>
                :
                  // Patient Info
                  <div className='text-center'>
                    <h5 className='mb-0'>
                      {item.label}: 
                      {item.key === keys.patientName
                      ? 
                        ` ${patientInfo.first_name} ${patientInfo.last_name}` 
                      :
                        item.key === keys.hospitalNumber
                        ?   
                          ` ${patientInfo.hospitalNumber ? patientInfo.hospitalNumber : "No Data"}`
                        :
                          item.key === keys.dob
                          ?
                            ` ${patientInfo.dob ? `${moment().year() - moment(patientInfo.dob, 'DD-MM-yyyy').year()}` : "No Data"}`
                          :
                            item.key === keys.arrivalDate
                            &&
                              ` ${patientInfo.created_at ? moment(patientInfo.created_at).format("yyyy-MM-DD") : "No Data"}`
                      }
                    </h5>
                  </div>
                }
              </div>
            ))}
          </div>
        </PageContentWrapper>
        <div className='col-12 flex justify-content-between align-items-center'>
          {/* <div> */}
            <Button label="Previous" style={{width: 120}} 
              className="mr-3" icon="pi pi-angle-left"
              onClick={() => setPageNumber((prevState) => prevState + 1)}
            />
            <Button label="Next" style={{width: 120}} 
              icon="pi pi-angle-right" iconPos='right' 
              onClick={() => setPageNumber(prevState => prevState - 1)}
              disabled={pageNumber === 0}
            />
          {/* </div> */}
        </div>
      </div>
    )
  }

  const renderTableFooter = () => {
    return null;
  }
  //#endregion

  const handleScrollTable = () => {
    drawLineCharts();
  }

  useEffect(() => {
    if(image) {
      obsPdf.current.showForm(image)
    }
  }, [image])

  return (
    <PageContentWrapper>
        <div className='col-12 flex justify-content-between align-items-center'>
          <div>
            <h2 className='font-bold'> Observations</h2>
          </div>
          <div>
            <Button  className="p-button-sm" label='ADD OBS' iconPos='left' icon="pi pi-plus" onClick={() => obsForm.current?.showForm()}/>
            <Button  className="p-button-success p-button-sm ml-2" label='EXPORT PDF' iconPos='left' icon="pi pi-file-export" onClick={() => handleTakeScreenshot()}/>
            <Button className="p-button-info p-button-sm ml-2" icon="pi pi-cog" label="SETTINGS" onClick={() => obsConfig.current?.showForm()}/>
          </div>
        </div>
        <div ref={screenshotRef}>
          {/* <div id='obs-svg'></div> */}
          <div>
            {/* Obs table */}
            <ObservationTable />
          </div>
          {/* OBS DATATABLE */}
          <div className='col-12 obs-table-chart'>
            <DataTable 
              ref={chartWrapper}
              className="obs-table-chart"
              onScroll={handleScrollTable}
              value={obsColWrapperData} 
              header={renderTableHeader()} 
              footer={renderTableFooter()} 
              showGridlines responsiveLayout="stack" 
              rowHover loading={isLoadingObs}
            >
              {/* <Column body={(rowData) => renderColWrapper(rowData, "head")} style={{position: 'sticky', left: 0, zIndex: isLoadingObs ? 0 : 10, backgroundColor: "white", width: '8%'}}/> */}
              <Column body={(rowData) => renderColWrapper(rowData, "head")} style={{backgroundColor: "white", width: '8%'}}/>
              {renderColBody()}
            </DataTable>
          </div>  
        </div>     

        {/* OBS FORM */}
       
          <ObservationsForm ref={obsForm} fullname={`${patientInfo.first_name} ${patientInfo.last_name}`} patientId={patientInfo.patient_id} reload={() => setPageNumber(0)}/>
          <ObservationConfig ref={obsConfig} reload={() => window.location.reload()}/>
          <ObservationPdfPreview ref={obsPdf} 
            fileName={`PatientObs-${getPatientId()}`}
          />
    </PageContentWrapper>
  )
}

export default Observations
