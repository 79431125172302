export const titleList = ["MISS", "MR", "MRS", "MS", "MX"];
export const identityList = [
  "Record anonymised for legal/statutory reasons",
  "Record anonymised at request of Caldicott Guardian",
  "Record anonymised at request of Client",
  "Record anonymised for other reason",
];
export const nhsNumberStatusList = [
  "Number present and verified",
  "Number present but not traced",
  "Trace required",
  "Trace attempted - No match or multiple match found",
  "Trace needs to be resolved - (NHS NUMBER or Client detail conflict)",
  "Trace in progress",
  "Number not present and trace not required",
  "Trace postponed (baby under six weeks old)",
];
export const genderList = ["Male", "Female", "Indeterminate", "Unknown"];
export const materialStatusList = [
  "A (Separated)",
  "D (Divorced)",
  "M (Married/Civil Partner)",
  "S (Single)",
  "U (Not Known)",
  "W (Widowed)",
  "X (Not Disclosed)",
];
export const methodOfAdmissionList = [
  "AE (ED)",
  "GP (Emergency GP Referral)",
  "TR (Transfer out of district)",
];
export const religionList = [
  "BA (Baptist)",
  "BU (Buddhist)",
  "CE (Church of England)",
  "CH (Christian Scientist)",
  "CI (Church of Ireland)",
  "CS (Church of Scotland)",
  "CT (Christian)",
  "CW (Church of Wales)",
  "HI (Hindu)",
  "JE (Jewish)",
  "JW (Jehovahs Witness)",
  "ME (Methodist)",
  "MO (Mormon)",
  "MU (Muslim)",
  "NC (Non Conformist)",
  "NK (Not Known)",
  "NR (No Religion)",
  "OF (Other Free Church)",
  "OT (Other)",
  "PB (Plymouth Brethren)",
  "PR (Presbyterian)",
  "QU (Quaker)",
  "RC (Roman Catholic)",
  "SA (Salvation Army)",
];
export const languageList = [
  "English",
  "French",
  "German",
  "Polish",
  "Spanish",
  "Chinese",
  "Welsh",
  "Irish",
  "Gaelic",
  "Cornish",
  "Romany",
  "Albanian",
  "Arabic",
  "Belarusian",
  "Bengali",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Cantonese",
  "Catalan",
  "Chechen",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  "Ethiopian",
  "Filipino",
  "Finnish",
  "Flemish",
  "Georgian",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
];
export const accessibleList = [
  "Interpreter not needed",
  "Interpreter needed",
  "Interpreter declined",
];
export const accomondationStatusList = [
  "Client has own stable accommodation e.g. home/flat",
  "Warden controlled accommodation",
  "Residential institution WITHOUT routine nursing care",
  "Residential institution WITH routine nursing care",
  "Medical area",
  "Homeless in night shelter",
  "Homeless without accommodation",
  "Usual accommodation not given : Client refused",
  "Usual accommodation not given : Client physically unable",
  "Usual accommodation not known",
];
export const overseaList = [
  "Charging category A: Standard NHS -funded Client",
  "Charging category B: Immigration Health Surcharge payee",
  "Charging category C: Charge-exempt Overseas Visitor (European Economic Area)",
  "Charging category D: Chargeable European Economic Area Client ",
  "Charging category E: Charge-exempt Overseas Visitor (non-European Economic Area)",
  "Charging category F: Chargeable non-European Economic Area Client",
  "Decision Pending",
  "OVERSEAS VISITOR CHARGING CATEGORY Not Known (Not Recorded)",
];
export const ethnicOriginList = [
  "White British",
  "White Irish",
  "Any other White background",
  "White and Black Caribbean",
  "White and Black Caribbean",
  "White and Black African",
  "White and Asian",
  "Any other mixed background",
  "Indian",
  "Pakistani",
  "Bangladeshi",
  "Any other Asian background",
  "Caribbean",
  "African",
  "Any other Black background",
  "Chinese",
  "Any other ethnic group",
  "Not stated e.g. unwilling to state",
  "Not known e.g. unconscious",
];
export const locationList = [
  "Chelsea Pensioners Home",
  "West Wing (F1)",
  "West Wing (F2)",
  "West Wing (F3)",
  "East Wing (F1)",
  "East Wing (F2)",
  "East Wing (F3)",
];
export const nurseList = [
  {label: 'Carer 1', value: '1'},
  {label: 'Carer 2', value: '2'},
  {label: 'Carer 3', value: '3'},
  {label: 'Carer 4', value: '4'},
  {label: 'Carer 5', value: '5'}
];
// export const locationList = [
//   "Tất Cả",
//   "1",
//   "2",
//   "3",
//   "4",
//   "5",
//   "6",
//   "7",
//   "8",
//   "9",
//   "10",
//   "11",
//   "12",
//   "Hóc Môn",
//   "Bình Tân",
//   "Tân Bình",
//   "Tân Phú",
//   "Nhà Bè",
//   "Thủ Đức",
//   "Bình Chánh",
//   "Củ Chi",
//   "Cần Giờ",
//   "Phú Nhuận",
// ];
export const presenting_complaint_list = [
  "Airway / Breathing",
  "Circulation / Chest",
  "Gastrointestinal",
  "Neurological",
  "Skin",
  "Head and Neck",
  "Eye",
  "Trauma / Musculoskeletal",
  "Genitourinary",
  "ObGyn",
  "Environmental",
  "Psychosocial / Behaviour Change",
  "General / Minor / Admin",
  "Chest Pain",
  "Palpitations",
  "Electrical Exposure (inc. lightning)",
  "Hypothermia",
  "Frostbite",
  "Cardiac Arrest",
  "Near Drowning",
  "Exposure to Communicable Disease (inc. needlestick / body fluids)",
  "Chemical Exposure",
  "Noxious Inhalation - Gas / Fumes / Vapour / Smoke",
  "Poisoning from Any Source",
  "Red Eye",
  "Foreign Body on Eye",
  "Pain In / Around Eye",
  "Discharge from Eye",
  "Visual Disturbance",
  "Photophobia",
  "Eye Injury",
  "Eye Review",
  "Abdominal Pain",
  "export Constipation",
  "Diarrhoea",
  "Vomiting + / - Nausea",
  "Nausea Without Vomiting",
  "Hiccoughs",
  "Jaundice",
  "Loss of Appetite",
  "Vomiting Blood",
  "Rectal Pain",
  "Blood in Stools",
  "Injury of Anus",
  "Food / Foreign Body in Oesophagus",
  "Foreign Body in Digestive Tract",
  "Foreign Body in Rectum",
  "Abdominal Distension",
  "Difficulty Swallowing",
  "Fever",
  "Hyperglycaemia",
  "Hypoglycaemia",
  "Postoperative / wound care (no complication)",
  "Postoperative / Medical Device with Complication",
  "Crying Infant",
  "Generalised Weakness",
  "Pale Colour",
  "Asymptomatic, No Symptoms",
  "Blue Colour (cyanosis)",
  "Social Problem (medically well)",
  "Direct Referral to Inpatient Unit",
  "Requesting Prescription",
  "Pain on Passing Urine",
  "Frequent Urination",
  "Unable to Pass Urine",
  "Low Urine Output",
  "Blood in Urine",
  "Flank Pain",
  "Pain in Scrotum / Testes",
  "Abnormal Swelling Groin Area",
  "Pain in Genital Area (generalised)",
  "Injury to Genital Area",
  "Problem Related to Penis",
  "States Victim of Sexual Assault",
  "Facial Pain (inc. toothache)",
  "Neck Pain",
  "Ear : Pain",
  "Ear : Injury",
  "Ear : Discharge",
  "Ear : Hearing Loss",
  "Ear : Ringing in Ears (tinnitus)",
  "Ear : Foreign Body",
  "Nose : Bleeding from Nose",
  "Nose : Injury",
  "Nose : Congestion",
  "Nose : Foreign Body",
  "Throat : Swelling",
  "Throat : Sore",
  "Throat : Cough",
  "Throat : Foreign Body in Throat / Mouth",
  "Headache",
  "Confusion",
  "Drowsy (altered level of consciousness)",
  "Limb Weakness",
  "Facial Weakness",
  "Speech Disturbance",
  "Seizure (fit)",
  "Dizziness",
  "Numbness / Tingling (parasthesia)",
  "Tremor",
  "Falls / Unsteady on Feet",
  "Insomnia",
  "Short of Breath",
  "Difficulty Breathing",
  "Noisy Breathing",
  "Collapse / Fainting Episode",
  "Collapse / Fainting Episode with Loss of Consciousness",
  "Coughing up Blood (haemoptysis)",
  "Swollen Legs (both)",
  "Swollen Leg (single)",
  "Cold Painful Limb",
  "Foreign Body in Respiratory Tract",
  "Cardiac Arrest Due to Trauma",
  "Infant with Episodes not Breathing (apnoea)",
  "Respiratory Arrest",
  "Pregnancy Related : Less Than 20 Weeks",
  "Pregnancy Related : Greater Than 20 Weeks",
  "Vaginal Bleeding (abnormal)",
  "Foreign Body in Vagina",
  "Problem Related to Vagina",
  "Problem Related to Breast",
  "Drug / Alcohol Intoxication or Withdrawal",
  "Self-harm",
  "Suicidal Thoughts",
  "Depressive Feelings",
  "Feeling Anxious",
  "Behaviour : Unusual",
  "Behaviour : Agitated / Violent",
  "Hallucinations / Delusions",
  "Wound : Abrasion",
  "Wound : Laceration",
  "Wound : Puncture",
  "Wound : Sting",
  "Wound : Bite",
  "Burn",
  "Rash",
  "Localised Swelling / Redness / Lumps / Bumps",
  "Itching",
  "Spontaneous Bruising",
  "Foreign Body in Skin / Subcutaneous Tissue",
  "Head Injury",
  "Facial Injury",
  "Injury of Neck",
  "Injury of Shoulder / Arm / Elbow / Wrist / Hand",
  "Injury of Hip / Leg / Knee / Ankle / Foot",
  "Injury of Thorax",
  "Injury of Abdomen",
  "Injury of Cervical Region of Back (disorder)",
  "Injury of Upper Back",
  "Injury of Lower Back",
  "Backache (no recent injury)",
  "Pain in Shoulder / Arm / Elbow / Wrist / Hand",
  "Pain in Hip / Leg / Knee / Ankle / Foot",
  "Joint Swelling",
  "Major Trauma (serious injury &gt;1 body area)",
  "Traumatic Amputation",
];
export const nokRelationList = [
  "AU (Aunt)",
  "BR (Brother)",
  "CL (Common Law)",
  "CO (Cousin)",
  "DA (Daughter)",
  "FA (Father)",
  "FP (Foster Parent)",
  "FR (Friend)",
  "GC (Grandchild)",
  "GM (Grandmother)",
  "GP (Grandfather)",
  "GU (Guardian)",
  "HU (Husband)",
  "MO (Mother)",
  "NE (Nephew)",
  "NI (Neice)",
  "ON (Other Not Related)",
  "OR (Other Relative)",
  "PA (Parent)",
  "PT (Partner)",
  "SI (Sister)",
  "SO (Son)",
  "UN (Uncle)",
];
export const emergency_care_list = [
  "Unplanned First Emergency Care Attendance for a new clinical condition (or deterioration of a chronic condition).",
  "Unplanned Follow-up Emergency Care Attendance for the same or a related clinical condition and within 7 days of the First Emergency Care Attendance  at  THIS Emergency Care Department",
  "Unplanned Follow-up Emergency Care Attendance for the same or a related clinical condition and within 7 days of the First Emergency Care Attendance  at ANOTHER Emergency Care Department",
  "Planned Follow-up Emergency Care Attendance  within 7 days of the First Emergency Care Attendance at THIS Emergency Care Department",
  "Not Applicable (Client dead on arrival at Emergency Care Department)",
];
export const placeOfIncidentList = [
  "1 (Home - Living Room)",
  "2 (Home - Kitchen)",
  "3 (Home - Garage)",
  "4 (Home - Hallway)",
  "5 (Home - Stairway)",
  "6 (Home - Bathroom)",
  "7 (Home - Bedroom)",
  "8 (Home - Utility Room)",
  "9 (Home - Study)",
  "10 (Home - Dining Room)",
  "11 (Home - Home Gym)",
  "12 (Home - Conservatory)",
  "13 (Home - Garden)",
  "14 (Home - Outbuilding / Shed)",
  "15 (Home - Swimming Pool)",
  "16 (Home - Pond)",
  "17 (Residential Building - Residential / Nursing Home)",
  "18 (Residential Building - Hotel / Bed and Breakfast)",
  "19 (Residential Building - Student Accommodation )",
  "20 (Residential Building - Hostel Homeless)",
  "21 (Entertainment - Inside Licensed Premises : Pub Club Bar)",
  "22 (Entertainment - Outside Licensed Premises : Pub Club Bar)",
  "23 (Entertainment - Music Venue e.g. Nightclub)",
  "24 (Entertainment - Live Music Venue e.g. Concert Hall)",
  "25 (Entertainment - Music Festival Site)",
  "26 (Entertainment - Cinema)",
  "27 (Entertainment - Theatre)",
  "28 (Public Area - Educational Establishment)",
  "29 (Public Area - Retail Service Area e.g. Shop )",
  "30 (Public Area - Workplace)",
  "31 (Public Area - Public Building)",
  "32 (Public Area - Sports Facility)",
  "33 (Public Area - Medical / Clinical Area)",
  "34 (Transport - Private Vehicle)",
  "35 (Transport - Private Hire Vehicle / Taxi)",
  "36 (Transport - Bus)",
  "37 (Transport - Tram)",
  "38 (Transport - Train)",
  "39 (Transport - Underground Train )",
  "40 (Transport - Transport Interchange : Airport / Station)",
  "41 (Outdoor - Road / Pavement)",
  "42 (Outdoor - Recreational Area)",
  "43 (Outdoor - Countryside)",
  "44 (Outdoor - Farm)",
  "45 (Outdoor - export Construction Area)",
  "46 (Outdoor - Military Barracks Army)",
  "47 (Outdoor - Water / Waterside)",
  "48 (Outdoor - Derelict Building)",
  "49 (Custodial Services - Police)",
  "50 (Custodial Services - Prison)",
  "51 (Custodial Services - Detention Centre)",
  "52 (Public Area - Ice Rink)",
  "BF (Entertainment - Boomtown Festival)",
  "X (Refused to Answer)",
];
export const ambulance_organisation_identifier_list = ["SCAS", "Other"];
export const RtcInfoList = [
  "Cyclist without Helmet",
  "Driver with Seat Belt",
  "Driver - no Seat Belt",
  "Front Seat Passenger Wearing Seat Belt",
  "Front Seat Passenger - No Seat Belt",
  "Cyclist with Helmet",
  "Motorcuclist - Driver",
  "Not Relevant",
  "Motorcyclist - Pillion Passanger",
  "Rear Sear Passenger with Seat Belt",
  "Rear Sear Passenger - No Belt",
  "Pedestrian",
  "Refused to Answer",
];
export const attendanceSourceList = [
  "Self / family / friends / education / work colleague",
  "232",
  "Non-NHS telephone / internet advice",
  "GP / practice Carer",
  "Out of hours GP service",
  "NHS telephone / internet advice e.g. NHS 111",
  "Emergency department",
  "Urgent care service",
  "Outpatient service inc. ambulatory care",
  "Inpatient",
  "Private specialist",
  "Community Carer (not practice Carer)",
  "Health visitor",
  "Midwife",
  "School Carer",
  "Community mental health Carer",
  "Mental health assessment team",
  "Social services",
  "Older persons day care centre",
  "Homeless persons drop in centre",
  "Custodial services : prison ",
  "Custodial services : detention centre",
  "Pharmacist (including community pharmacist)",
  "Dentist (including community dentist)",
  "Optician / optometrist",
  "Advanced care practitioner",
  "Ambulance service - patient in transit",
  "Police service / forensic medical officer",
  "Fire service",
  "Search and rescue",
  "Coastguard",
  "Mountain rescue",
];
export const modeOfArrivalList = [
  "Patient arranged own transport / walk-in",
  "Public transport / taxi",
  "Emergency road ambulance",
  "Emergency road ambulance with medical escort",
  "Non-emergency road ambulance",
  "Helicopter",
  "Fixed wing / medical repatriation by air",
  "Custodial services : prison / detention centre transport",
  "Police transport",
];

export function convertLabelListToObjectList(dataList) {
  if(!dataList || !Array.isArray(dataList)) {
    return [];
  }

  let returnedList = [];
  for(let i = 0; i < dataList.length; i++) {
    if(dataList[i]) {
      let item = {
        label: dataList[i],
        value: dataList[i]
      }

      returnedList.push(item);
    }
  }
  return returnedList;
}


// Patient Form
export const componentTypes = {
  input: "InputText",
  inputArea: "InputArea",
  inputNumber: "InputNumber",
  dropdown: "DropDown",
  checkbox: "CheckBox",
  calendar: "Calendar",
  multiCheckbox: "MultiCheckbox",
  upload: "Upload",
  inputMask: "InputMask",
  multiSelect: "MultiSelect"
};
export const classnameTypes = {
  simple: "col-12",
  double: "col-6",
  tripble: "col-4",
  quarable: "col-3",
  pentable: "col-9",
  grid: "grid",
};
export  const leftPatientForm = [
    {
      label: "Title",
      key: "title",
      component: componentTypes.dropdown,
      className: classnameTypes.tripble,
      defaultValue: "",
      options: convertLabelListToObjectList(titleList),
    },
    {
      
      label: " First Name",
      key: "first_name",
      component: componentTypes.input,
      className: classnameTypes.tripble,
    },
    {
      
      label: "Last Name",
      key: "last_name",
      component: componentTypes.input,
      className: classnameTypes.tripble,
    },
    {
      
      label: "Choose Carer",
      key: "chooseNurse",
      component: componentTypes.multiSelect,
      className: classnameTypes.simple
    },
    {
      label: "Picture",
      key: "avatar",
      component: componentTypes.upload,
      className: classnameTypes.simple,
    },
    {
      
      label: "Identity With Held Reason",
      key: "identity_withheld_reason",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(identityList),
    },
    {
      
      label: "NHS Number",
      key: "nhs_number",
      component: componentTypes.inputNumber,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "NHS Number Status",
      key: "nhs_number_status",
      component: componentTypes.dropdown,
      className: classnameTypes.tripble,
      options: convertLabelListToObjectList(nhsNumberStatusList),
    },
    {
      
      label: "Hospital Number",
      key: "hospital_number",
      component: componentTypes.inputNumber,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "Gender",
      key: "gender",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(genderList),
    },
    {
      
      label: "DOB",
      key: "dob",
      component: componentTypes.calendar,
      className: classnameTypes.double,
      options: "",
    },
    {
      
      label: "Postal Code",
      key: "post_code",
      component: componentTypes.inputNumber,
      className: classnameTypes.double,
      options: "",
    },
    {
      
      label: "Address line 1 ",
      key: "address_line_1",
      component: componentTypes.input,
      className: classnameTypes.double,
      options: "",
    },
    {
      
      label: "Address line 2",
      key: "address_line_2",
      component: componentTypes.input,
      className: classnameTypes.double,
      options: "",
    },
    {
      
      label: "Address line 3",
      key: "address_line_3",
      component: componentTypes.input,
      className: classnameTypes.double,
      options: "",
    },
    // {
      
    //   label: "Lane line",
    //   key: "laneline",
    //   component: componentTypes.input,
    //   className: classnameTypes.double,
    //   options: "",
    // },
    {
      
      label: "Mobile",
      key: "mobile_number",
      component: componentTypes.inputMask,
      className: classnameTypes.double,
      options: "",
      formatPhone: true
    },
    {
      
      label: "Occupation",
      key: "occupation",
      component: componentTypes.input,
      className: classnameTypes.simple,
      options: "",
    },
  ];

  export const rightPatientForm = [
    {
      
      label: "Marital Status",
      key: "material_status",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(materialStatusList),
    },
    {
      
      label: "Religion",
      key: "religion",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(religionList),
    },
    // {
      
    //   label: "Material Status",
    //   key: "material_status",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(materialStatusList),
    // },
    // {
      
    //   label: "Interpreter Language",
    //   key: " interpreter_language ",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(languageList),
    // },
    // {
      
    //   label: "Accessible Infomation Professional Required",
    //   key: "acccessibleInformationProfessionalRequired",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(accessibleList),
    // },
    {
      
      label: "Accommodation Status",
      key: "accom_status",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(accomondationStatusList),
    },
    // {
      
    //   label: "Overseas  Visitor",
    //   key: "overseas_visitor",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(overseaList),
    // },
    // {
      
    //   label: "District",
    //   key: "district_id",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(locationList),
    // },
    {
      
      label: "Diagnostic",
      key: "diagnostic",
      component: componentTypes.dropdown,
      className: classnameTypes.simple,
      options: convertLabelListToObjectList(presenting_complaint_list),
    },
    {
      
      label: "",
      key: "checkbox_sick",
      component: componentTypes.multiCheckbox,
      className: classnameTypes.simple,
      
    },
    // {
      
    //   label: "Emergency Care Attendace Category",
    //   key: "emergency_care",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.simple,
    //   options: convertLabelListToObjectList(emergency_care_list),
    // },
    // {
      
    //   label: "Ambulance Incident Code",
    //   key: "ambulance_incident_code",
    //   component: componentTypes.input,
    //   className: classnameTypes.double,
    //   options: "",
    // },
    // {
      
    //   label: "Emergency Care Attendance Category",
    //   key: "emergencyCareAttendanceCategory",
    //   component: componentTypes.dropdown,
    //   className: classnameTypes.double,
    //   options:convertLabelListToObjectList(emergency_care_list),
    // },
  ];
  export const nokForm = [
    {
      
      label: "NOK Name ",
      key: "nok_name",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "NOK Relationship",
      key: "nok_relationship",
      component: componentTypes.dropdown,
      className: classnameTypes.tripble,
      options: convertLabelListToObjectList(nokRelationList),
    },
    {
      
      label: "NOK Address Line",
      key: "nok_address_line_1",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "NOK Postal Code",
      key: "nok_post_code",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "NOK Contact Number ",
      key: "nok_mobile_number",
      component: componentTypes.inputMask,
      className: classnameTypes.tripble,
      options: "",
      formatPhone: true
    },
    {
      
      label: "NOK Comment",
      key: "nok_comments",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
  ];
  export const gpForm = [
    {
      
      label: "GP Surname",
      key: "gp_surname",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Code",
      key: "gpfh_code",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Initials",
      key: "gp_initials",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Title",
      key: "gp_title",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP National Code",
      key: "gp_national_code",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Address Line 1",
      key: "gp_address_line_1",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Adrress Line 2",
      key: "gp_address_line_2",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Postal Code",
      key: "gp_address_line_3",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "GP Clinic",
      key: "gp_clinic",
      component: componentTypes.input,
      className: classnameTypes.tripble,
      options: "",
    },
    {
      
      label: "Gp Telephone",
      key: "gp_telephone",
      component: componentTypes.inputMask,
      className: classnameTypes.tripble,
      options: "",
      formatPhone: true
    },
  ];