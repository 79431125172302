import { Column, DataTable, Dropdown } from 'primereact';
import React from 'react'
import { useState } from 'react';

const SummaryParameterTable = ({data}) => {
    const [pageable, setPageable] = useState({
        rows: 10,
        pageNumber: 0
    })

    const convertedDataList = (data ? JSON.parse(data) : []) 
    const dataList = convertedDataList ? convertedDataList.reverse() : []

    const columns = [
        {key: 'rrLower',label:'RRL'},
        {key: 'rrUpper',label:'RRU'},
        {key: 'hrLower',label:'HRL'},
        {key: 'hrUpper',label:'HRU'},
        {key: 'oxiLower',label:'OSL'},
        {key: 'oxiUpper',label:'OSU'},
        {key: 'syLower',label:'SL'},
        {key: 'syUpper',label:'SU'},
        {key: 'bgLower',label:'BGL'},
        {key: 'bgUpper',label:'BGU'},
        {key: 'tempLower',label:'TL'},
        {key: 'tempUpper',label:'TU'},
        {key: 'stepsLower',label:'STL'},
        {key: 'stepsUpper',label:'TU'},
        {key: 'sleepLower',label:'SLL'},
        {key: 'sleepUpper',label:'SLU'},
        {key: 'timestamp',label:'Time'},
        {key: 'addBy',label:'By'},
    ];

    const dynamicColumns = columns.map((col) => {
        return <Column key={col.key}  header={col.label} 
        body={(rowData) => (col.key === "addBy" ? ( `${rowData.first_name} ${rowData.last_name}`) : rowData[col.key])} />;
    });

    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];

        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />
    }

    const renderHeader = () => {
        return(
            <div className='col-12 flex justify-content-between align-items-center p-0'>
                <h5 className='mb-0'><strong>Audit Logs</strong></h5>
                {paginatorTemplate()}
            </div>
        )
    }

    return (
        <div>
            <DataTable className='col-12' value={dataList} responsiveLayout="stack" breakpoint="960px"
                paginator onPage={(e) => setPageable({...pageable, pageNumber: e.page})} header={renderHeader()} emptyMessage="No records"
                totalRecords={dataList ? dataList.length : 0} first={pageable.pageNumber * pageable.rows} rows={pageable.rows}
            >
                {dynamicColumns}
            </DataTable>
        </div>
    )
}

export default SummaryParameterTable
