import { auth } from "./FirebaseConfig";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";

export async function signup(email, password) {
    return await (await createUserWithEmailAndPassword(auth, email, password));
}

export async function signin(email, password) {
  return await signInWithEmailAndPassword(auth, email, password);
}

export async function logout() {
  return await signOut(auth);
}
