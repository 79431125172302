import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
    GroupMedical,
    InstructionsMedical,
    FrequencyMedical,
    RepeatMedical,
  } from "./MedicineOptions";
import { Button, Calendar, Checkbox, Dialog, Dropdown, InputText } from 'primereact';
import { InputNumber } from 'primereact/inputnumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMedical, getPatientId } from '../../api/CurrentServiceWorker';
import { showNotification } from '../notification/NotificationService';
import moment from 'moment';

const MedicineForm = forwardRef(( props, ref) => {
    useImperativeHandle(ref , () => ({ 
        showForm : (data) => showForm(data)
    }))
    
    const queryClient = useQueryClient();

    const [visible,setVisible]= useState(false)
    const [data, setData] = useState({})

    const showForm = (data) => {
        setVisible(true)
    }
    
    const hideForm = () => {
        setData({});
        setVisible(false)
    }

    const componentTypes = {
        input: "InputText",
        inputArea: "InputArea",
        inputNumber: "InputNumber",
        dropdown: "DropDown",
        checkbox: "CheckBox",
        calendar: "Calendar",
        multiCheckbox:"MultiCheckbox",
        fileUpload:"FileUpload"
        
    }

    const classnameTypes = {
        simple: "col-12",
        double: "col-6",
        tripble: "col-4",
        quarable: "col-3",
        pentable: "col-9",
        grid: "grid"
    }

    const dialogMedicineContent = [
        { 
            key: "group_medical",
            label: "Group",
            component: componentTypes.dropdown, 
            className:  classnameTypes.simple,
            defaultValue: "",
            options: GroupMedical
        },
        { 
            key: "title_medicine",
            label: "Title",
            component: componentTypes.dropdown, 
            className:  classnameTypes.simple,
            defaultValue: "",
            options: GroupMedical 
        },
        { 
            key: "des_medical",
            label: "Description",
            component: componentTypes.input, 
            className:  classnameTypes.simple,
            defaultValue: ""
        },
        { 
            key: "frequency",
            label: "Frequency",
            component: componentTypes.dropdown, 
            className:  classnameTypes.simple,
            defaultValue: "",
            options: FrequencyMedical
        },
        { 
            key: "instructions",
            label: "Instructions",
            component: componentTypes.dropdown, 
            className:  classnameTypes.simple,
            defaultValue: "",
            options: InstructionsMedical
        },
        { 
            key: "startDateTime",
            label:"Start Date",
            component: componentTypes.calendar, 
            className:  classnameTypes.simple,
            defaultValue: ""
        },
        { 
            key: "picture",
            label: "Image",
            component: componentTypes.fileUpload, 
            className:  classnameTypes.simple,
            defaultValue: ""
        },
        { 
            key: "isPeriodic",
            label :"Is Periodic ?",
            component: componentTypes.checkbox, 
            className:  classnameTypes.simple,
            defaultValue: "",
            
        },
        { 
            key: "periodic_time",
            label :"Periodic Time",
            component: componentTypes.inputNumber, 
            className:  `${classnameTypes.double} mb-3`,
            defaultValue: "",
            replyOn: "isPeriodic",
        },
        { 
            key: "periodic_frequency",
            label :"Frequency",
            component: componentTypes.dropdown, 
            className:  classnameTypes.double,
            defaultValue: "",
            options: RepeatMedical,
            replyOn: "isPeriodic",
        },
        { 
            key: "endDateTime",
            label :"End Date Time",
            component: componentTypes.calendar, 
            className:  classnameTypes.simple,
            defaultValue: "",
            replyOn: "isPeriodic",
        }
    ]
    
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => handleCreateMedical()} autoFocus />
            </div>
        );
    }

    const handleChangeInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
            
        })
    }
    const handleChangeDropdown = (e) => {
        setData({
            ...data,
            [e.target.name]: e.value
        })
    }

    const handleCreateMedical = async () => {
        if(!data.startDateTime) {
            showNotification("info", "Information", "Please select a start date");
            return;
        }

        let toPostData = {
          ...data,
          patient_id: getPatientId(),
          created_at: moment(new Date()).format("yyyy-MM-DD HH:mm"),
          startDateTime: data.startDateTime ? moment(data.startDateTime).format("yyyy-MM-DD HH:mm") : null,
          endDateTime: data.endDateTime ? moment(data.startDateTime).format("yyyy-MM-DD HH:mm") : null
        }
        // NEW
        mutate(toPostData);
    }

    const { mutate } = useMutation(toAddHeightWeight => createMedical(toAddHeightWeight), {
        onSuccess: (data, variables, context) => {
            showNotification("success", "Success", "Note Created");
    
            queryClient.setQueryData(
                ['medicalData', getPatientId()],
                oldDataList => oldDataList && Array.isArray(oldDataList) ? [...oldDataList, variables] : [variables]
            )
    
            hideForm();
        },
        onError: (error, variables, context) => {
            showNotification("error", "Error", "Fail to create note");
        }
    })

    return (
        <Dialog
            header="Add Medicine"
            visible={visible} 
            style={{ width: '40vw' }}
            footer={renderFooter('displayBasic')}
            onHide={() => setVisible(false)}
            blockScroll
            >
        <div className='grid col-12'>
            {dialogMedicineContent.map((item, idx) => (
                (item.component === componentTypes.input && (!item.replyOn || data[item.replyOn]))
                ? 
                    <div key={idx} className={item.className}>
                        <h5 className='col-12 p-0'>{ item.label}</h5>
                        <InputText 
                            className='col-12 p-inputtext-lg'
                            rows={3}
                            name={`${item.key}`} 
                            value={data[item.key] || item.initialValue} 
                            placeholder={`Input ${item.label}`}
                            onChange={(e) => handleChangeInput(e)}/>
                    </div>
                : 
                (item.component === componentTypes.dropdown && (!item.replyOn || data[item.replyOn]))
                ? 
                    <div key={idx} className={`${item.className}`}>
                        <h5 className='col-12 mb-2'>{ item.label}</h5>
                        <Dropdown 
                            className='col-12'
                            name={`${item.key}`}
                            style={{height: '46px'}}
                            filter filterBy='label' 
                            value={data[item.key] || item.initialValue}
                            options={item.options}
                            onChange={(e) => handleChangeDropdown(e)}
                            placeholder={`Select ${item.label}`}
                        />
                    </div>
                :
                (item.component === componentTypes.calendar  && (!item.replyOn || data[item.replyOn]))
                ? 
                    <div key={idx} className={`${item.className}`}>
                        <h5>{ item.label }</h5>
                        <Calendar
                            className='col-12 px-0 p-inputtext-lg'
                            name={`${item.key}`}
                            value={data[item.key] || item.initialValue}
                            onChange={(e) => handleChangeDropdown(e)}
                            showTime showSeconds
                            placeholder={`Select ${item.label}`}
                        />
                    </div>
                : 
                (item.component === componentTypes.checkbox && (!item.replyOn || data[item.replyOn]))
                ? 
                    <div key={idx} className={`${item.className} mb-3`}>
                        <Checkbox  className='mr-3' inputId="binary" checked={data[item.key]} onChange={e => setData({...data, [item.key]: e.checked})} />
                        <label htmlFor="binary" className='font-bold'>{item.label}</label>
                    </div>
                : 
                (item.component === componentTypes.inputNumber && (!item.replyOn || data[item.replyOn]))
                && 
                    <div key={idx} className={`${item.className}`}>
                        <h5>{ item.label }</h5>
                        <InputNumber 
                            className='col-12 px-0 p-inputtext-lg'
                            rows={3}
                            name={`${item.key}`} 
                            placeholder={`Input ${item.label}`}
                            value={data[item.key] || item.initialValue} 
                            onChange={(e) => setData({...data, [item.key]: e.value})}
                            />
                    </div>
                
            ))}
        </div>
    </Dialog>
  )
})

export default MedicineForm
