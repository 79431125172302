// ACCESS TOKEN
export function setUserToken({
    accessToken,
    refreshToken
}) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
}

export function setAccessToken(accessToken) {localStorage.setItem("access_token", accessToken)}
export function getAccessToken() { return localStorage.getItem("access_token") }
export function removeAccessToken() {localStorage.removeItem("access_token")}

// REFRESH TOKEN
export function setRefreshToken() { localStorage.setItem('refresh_token') }
export function getRefeshToken() { return localStorage.getItem("refresh_token")}
export function removeRefreshToken() {localStorage.removeItem("refresh_token")}

// CLEAR AUTH
export function clearStorage() {localStorage.clear()}

export function isAuthenticated(){
    let authenticated = localStorage.getItem("authenticated") ? JSON.parse(localStorage.getItem("authenticated")) : null;
    
    return authenticated;
}

// USER INFO
export function setUserInfo({
    id,
    username,
    email,
    fullname,
    type,
    hospitalName
}){
    localStorage.setItem('id', id)
    localStorage.setItem('username', username)
    localStorage.setItem('email', email)
    localStorage.setItem('fullname', fullname)
    localStorage.setItem('type', type)
    localStorage.setItem('hospitalName', hospitalName)
}

export function getUserId(){
    return JSON.parse(localStorage.getItem("id"));
}

export function getUsername(){
    return localStorage.getItem("username");
}

export function getUserEmail(){
    return localStorage.getItem("email")
}

export function getUserType(){
    return localStorage.getItem("type")
}

export function getHospitalName(){
    return localStorage.getItem('hospitalName')
}