import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { convertKeyToLabel } from '../../utils/CommonUtils'
import PageContentWrapper from '../../utils/LayoutUtils'
import video from "./../../assets/video/mov_bbb.mp4"
import overviewVideo from "./../../assets/video/TVHH_1-Overview.mp4"
import registrationVideo from "./../../assets/video/TVHH_2-Registration.mp4"
import obsVideo from "./../../assets/video/TVHH_3-Obs.mp4"
import pharmacyVideo from "./../../assets/video/TVHH_4-Pharmacy.mp4"
import sumstatVideo from "./../../assets/video/TVHH_5-SumStat.mp4"
const TutorialsForm = forwardRef((props, ref) => {
    const [visibleFullScreen, setVisibleFullScreen] = useState(false);
    const [visibleVideo, setVisibleVideo] = useState("https://www.youtube.com/embed/KZhzxhEOgzY")
    const [title, setTitle] = useState("overview")
    useImperativeHandle(ref , () => ({ 
        showForm : (data) => showForm(data)
      }) )
      const showForm = (data) => {
        setVisibleFullScreen(true)
      }
    
      const hideForm = () => {
        setVisibleFullScreen({});
        setVisibleFullScreen(false)
    }


    const videoList = [
        {
            key : "overview",
            video : "https://www.youtube.com/embed/KZhzxhEOgzY"
        },
        {
            key : "registration",
            video : "https://www.youtube.com/embed/X-KN8lpWFZg"
        },
        {
            key : "observation",
            video : "https://www.youtube.com/embed/Q5l9uJipJEA"
        },
        {
            key : "pharmacy",
            video : "https://www.youtube.com/embed/TNoJoKdsnbk"
        },
        {
            key : "sumstat",
            video : "https://www.youtube.com/embed/nGfhHB9CqkU"
        }
    ]

    const handleView = ({video, key}) => {
        setTitle(key)

        const videoContainer = document.getElementById("video-container");
        if(videoContainer) {
            videoContainer.src = video;
        }
    }

    return (
        <div>
            <Sidebar visible={visibleFullScreen} fullScreen onHide={() => setVisibleFullScreen(false)}>
                
                <PageContentWrapper>
                
                <div className='flex col-12'>
                    <div className='col-2 text-center m-1 border-round summary-box clearfix' style={{background:""}}>
                    <h3 className='font-bold'>Tutorials</h3>
                        {videoList.map((item, idx)=> (
                            <div key={item.key} className='col-12' style={{cursor: "pointer"}} >
                                <div style={{
                                    background: item.key === title ? "rgb(99, 102, 241)" : "rgba(99, 102, 241,0.2)",
                                    color: item.key === title ? 'white' : 'grey', 
                                    borderRadius:"4px", 
                                    padding:5, 
                                    marginBottom:3,
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} 
                                onClick={()=> handleView(item)}
                                >
                                    <h5 className='mb-0'>{convertKeyToLabel(item.key)}</h5>
                                </div>
                            </div>
                        ) )}
                    </div>
                    <div className='col-10 text-center m-1 border-round summary-box clearfix' >
                            <h4>{convertKeyToLabel(title)}</h4>
                            <div className='p-2' style={{border:"1px solid #eaeaea", borderRadius:"5px", }}>
                            <iframe 
                                id="video-container"
                                width="100%" 
                                height="700" 
                                src={visibleVideo}
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            />
                            </div>
                            
                    </div>
                </div>
                </PageContentWrapper>
            </Sidebar>
        </div>
    )
})

export default TutorialsForm
