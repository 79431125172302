import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

const MedicineTable = ({data}) => {
  const columns = [
    {key: 'startDateTime', label: "Start Date Time" },
    {key: 'des_medical', label: "Description"},
    {key: 'frequency', label: "Frequency" },
    {key: 'instructions', label: "Instructions" },
    // {key: 'image_url', label: "Image"},
    {key: 'status_medical', label: "Medical Status"}
  ];
  const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.key} header={col.label}
      body={(rowData) => (col.key === "startDateTime" && rowData.startDateTime) 
                            ? moment(rowData.startDateTime).format("YYYY-MM-DD HH:mm") 
                            : col.key === "status_medical" 
                              ?  rowData[col.key] === 2
                                ? "Medical Taken"
                                : "Not yet/Forgot"
                            :rowData[col.key]} />;
});
    
  return (
    <div>
        <DataTable className='col-12' value={data} responsiveLayout="scroll">
            {dynamicColumns}
        </DataTable>
    </div>
  )
}

export default MedicineTable
