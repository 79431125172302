import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { Button, Dialog, Dropdown, ProgressSpinner } from 'primereact'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { discardExerciseMessage, discardObsMessage, getAllExercises, getAllPatientObs, getDoctorId, getListPatients, setPatientFirebaseId, setPatientId, setPatientName } from '../../api/CurrentServiceWorker'
import PigeonMap from '../map/PigeonMap'
import { Paginator } from 'primereact/paginator';
import { getLabelFromKey } from '../observations/Utils'
import { openPage } from '../../utils/NavigatorUtils'
import { notificationEvent } from './NotificationService'
import { useAppContext } from '../../context/AppContext'

const NotificationComponent = (props) => {
    const queryClient = useQueryClient();

    const {patientManagedIds} = useAppContext();

    const [pageable, setPageable] = useState({
        pageNumber: 0,
        rows: 10
    })
    const [mapData, setMapData] = useState({})
    const [showMaps, setShowMaps] = useState(false);
    const {data} = useQuery(
        ['notificationData' ],
        () => getAllExercises()
    )

    const {data: patientObsList, refetch} = useQuery(
        ['notificationObsData'],
        async() => await getAllPatientObs()
    )

    const {data: patientList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients(),
        {
            enabled: !!getDoctorId()
        }
    )

    const getFilteredObsData = (dataList) => {
        let returnedDataList = [];
        if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
            return returnedDataList;
        }

        for(let i = 0; i < dataList.length; i++) {
            if(dataList[i].discard === 0 && dataList[i].news_log) {
                const newsLogData = JSON.parse(dataList[i].news_log);

                if(newsLogData && Object.keys(newsLogData).length > 0) {
                    returnedDataList.push({...newsLogData, id: dataList[i].id, type: "news", date: dataList[i].created_at ? moment(dataList[i].created_at).toDate() : dataList[i].created_at, created_at: dataList[i].created_at})
                }
            }
        }

        return returnedDataList;
    }

    const filterObsDataList = useMemo(() => {
        if(patientObsList && Array.isArray(patientObsList)) {
            return getFilteredObsData(patientObsList)
        }

        return []
    }, [patientObsList?.length])

    const filterDataList = useMemo(() => {
        if(data && Array.isArray(data)) {
            return data.filter(x => (x.fall === "true" || x.epileptic === "true") && x.discard === 0)
        }

        return [];
    }, [data?.length]);

    const convertDataList = useMemo(() => {
        if(filterDataList && filterDataList.length > 0 && patientList && patientList.length > 0) {
            return filterDataList.map((item) => {
                    for(let i = 0; i < patientList.length; i++) {
                        if(item.patient_id === patientList[i].patient_id) {
                            item.type = "exercise"
                            item.actionType = item.fall === "true" ? "fall" : item.epileptic === "true" ? "epileptic" : ""
                            item.patient_name = `${patientList[i].first_name} ${patientList[i].last_name}`;
                            item.date = patientList[i].created_at ? moment(patientList[i].created_at).toDate() : patientList[i].created_at
                            break;
                        }
                    }
    
                    return item;
                }
            ) 
        }

        return [];
    }, [filterDataList?.length, patientList?.length])

    const mergedDataList = filterObsDataList.concat(convertDataList);
    const sortedMergedDataList = mergedDataList.sort((a,b) => a.date - b.date)

    const finalDataList = useMemo(() => {
        return sortedMergedDataList.slice(pageable.pageNumber * pageable.rows, (pageable.pageNumber * pageable.rows) + pageable.rows)
    }, [sortedMergedDataList?.length, pageable.pageNumber])
    
    const handleViewInfo = async(data) => {
        if(data && data.type) {
            if(data.type === "exercise") {
                setMapData(data);
                setShowMaps(true);
                
                mutateExercise(data.id)

                await discardExerciseMessage(data.id)
            } else {
                setPatientId(data.patient_id);
                setPatientFirebaseId(data.uid);
                setPatientName(data.fullname);

                mutatePatientObs(data.id)
                
                await discardObsMessage(data.id)

                openPage(`/patient/${data.patient_id}/observation`)
            }
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <Button className='mt-3 px-3' label="Close" icon="pi pi-times" onClick={() => setShowMaps(false)} autoFocus />
            </div>
        );
    }

    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10},
            { label: 30, value: 30 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];

        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />;
    }

    const convertNewsKeyToLabel = (dataList) => {
        if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
            return "-"
        }

        let returnedLabel = "";
        for(let i = 0; i < dataList.length; i++) {
            if(dataList[i]) {
                if(i === 0) {
                    returnedLabel = getLabelFromKey( dataList[i] )
                } else {
                    returnedLabel += ` - ${getLabelFromKey( dataList[i] )}`
                }
            }
        }

        return returnedLabel
    }

    const {mutate: mutateExercise} = useMutation(id => () => {}, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(
                ["notificationData"],
                oldDataList => oldDataList && Array.isArray(oldDataList) && oldDataList.length > 0 
                                    ?   oldDataList.filter(x => x.id !== variables)
                                    :   []
            )
        }
    })


    const {mutate: mutatePatientObs, refetch: refetchMutatePatientObs} = useMutation(id => () => {}, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(
                ["notificationObsData"],
                oldDataList => oldDataList && Array.isArray(oldDataList) && oldDataList.length > 0 
                                    ?   oldDataList.filter(x => x.id !== variables)
                                    :   []
            )
        }
    })

    useEffect(() => {
        notificationEvent.on('PATIENT_OBS_NOTIFICATION', () => refetch())

        return () => notificationEvent.removeListener('PATIENT_OBS_NOTIFICATION')
    }, [])


    return (
        <div className='col-12'>
            <div className='col-12 flex justify-content-between p-0 mb-3'>
                <h4>Notifications</h4>
                {paginatorTemplate()}
            </div>
            {finalDataList && finalDataList.length > 0 ?
                finalDataList.map((item, idx) => (
                    <div key={`${item.id}-${idx}`} className='flex justify-content-between mb-3 p-1 py-3' style={{backgroundColor: 'rgba(255, 87, 87, 0.2)', borderRadius :'5px', height: 100}}>
                        <div className='flex flex-column justify-content-around font-bold px-3'>
                            {item.type === "exercise" 
                            ?
                                <React.Fragment>
                                    <label>Client Name: {item.patient_name ? item.patient_name : "-"}</label>
                                    <div>Event: {item.actionType === "fall" ? "FALL" : "SEIZURE"} {item.created_at ? moment(item.created_at).format("DD-MM-yyyy HH:mm") : "-"}</div>
                                </React.Fragment>

                            :
                                <React.Fragment>
                                    <label>Client Name: {item.fullname ? item.fullname : "-"}</label>
                                    <div>Event: News Alert</div>
                                    <div>Date: {item.created_at ? moment(item.created_at).format("DD-MM-yyyy HH:mm") : "-"}</div>
                                    <div>Fields: {item.data && Array.isArray(item.data) ? convertNewsKeyToLabel(item.data) : "-"}</div>
                                </React.Fragment>
                            }
                            
                        </div>
                        <div className='flex justify-content-center align-items-center'>
                            <div className='p-1 mx-2' style={{backgroundColor: 'white', borderRadius: 6}}>
                                <Button icon="pi pi-search"
                                    onClick={() => handleViewInfo(item)}
                                />
                            </div>
                        </div>
                    </div>
                ))
            :
                <div className='col-12'>
                    <div className='mb-3'>
                        <img 
                            src={require("../../assets/images/Empty-Notification.svg")}
                            style={{height: 150, width: '100%'}}
                        />
                    </div>
                    <h5 className='text-center'>No notifications</h5>
                </div>
            }
            
            {sortedMergedDataList && sortedMergedDataList.length > 0 &&
                <Paginator first={pageable.pageNumber * pageable.rows} rows={pageable.rows} totalRecords={sortedMergedDataList ? sortedMergedDataList.length : 0} onPageChange={(e) => {setPageable({...pageable, pageNumber: e.page}); props.scrollToTop()}}></Paginator>
            }

            <Dialog
                header="Map"
                visible={showMaps} 
                style={{ width: '60vw' }}
                footer={renderFooter('displayBasic')}
                onHide={() => setShowMaps(false)}
            >
                {/* <Map 
                    longtitude={mapData?.longitude}
                    latitude={mapData?.latitude}/> */}
                    <PigeonMap 
                        longtitude={mapData?.longitude}
                        latitude={mapData?.latitude}
                    />
            </Dialog>
        </div>
    )
}

export default NotificationComponent
