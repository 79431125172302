import React, { useMemo, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getListNotes, getPatientId, getUserType } from '../../api/CurrentServiceWorker';
import { Button, Dropdown } from 'primereact';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PatientNoteForm from './PatientNoteForm';

const PatientNotes = ({patientFirstName, patientLastName}) => {
    const patientNoteForm = useRef(null);
    const [pageable, setPageable] = useState({
        rows: 10,
        pageNumber: 0
    })

    const {data: noteList} = useQuery(
        ['patientNoteList', getPatientId()],
        async() => await getListNotes()
    )
    
    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];

        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />;
    }

    const renderHeader = () => {
        return(
            <div className='col-12 flex justify-content-between p-0'>
                <Button tooltip='Add note' icon="pi pi-plus" className="p-button-rounded" aria-label="Add Note" onClick={() => patientNoteForm.current?.showForm()}/>
                <div className='flex align-items-center justify-content-center'>
                    <h5 className='mb-0'>Notes</h5>
                </div>
                {paginatorTemplate()}
            </div>
        )
    }

    const createdBodyTemplate = (rowData) => {
        const pattern = "yyyy-MM-DD HH:mm:ss"
        var noteCreatedAtUTC = moment(rowData.created_at).format(pattern);
        return moment.utc(noteCreatedAtUTC).local().format(pattern);
    }

    const updatedBodyTemplate = (rowData) => {
        if(!rowData || !rowData.updated_at) {
            return "-"
        }

        const pattern = "yyyy-MM-DD HH:mm:ss"
        var noteCreatedAtUTC = moment(rowData.updated_at).format(pattern);
        return moment.utc(noteCreatedAtUTC).local().format(pattern);
    }

    return(
        <React.Fragment>
            <DataTable 
                header={renderHeader()}
                responsiveLayout="stack"
                breakpoint="960px" rowHover sortOrder={-1} sortField="id"
                value={noteList ? noteList : []}   
                paginator onPage={(e) => setPageable({...pageable, pageNumber: e.page})}
                totalRecords={noteList ? noteList.length : 0} first={(pageable.pageNumber * pageable.rows)} rows={pageable.rows}
            >
                <Column field='note_type' header="Type"/>
                <Column field="note" header="Note Preview" />
                <Column header="Created Date" body={createdBodyTemplate}/>
                <Column header="Updated Date" body={updatedBodyTemplate}/>
                <Column header="By" body={(rowData) => <div>{rowData.doctor_firstname} {rowData.doctor_lastname}</div>} />
                <Column header="Action" body={(rowData) => <Button type="button" icon="pi pi-search" onClick={() =>  patientNoteForm.current?.showForm(rowData)}></Button>} />
            </DataTable>
            <PatientNoteForm
                ref={patientNoteForm} 
                patientFirstName={patientFirstName}
                patientLastName={patientLastName}
            />
        </React.Fragment>
    )
}

export default PatientNotes;