import { Badge, Button, Dialog } from 'primereact'
import React from 'react'
import { useImperativeHandle } from 'react'
import { forwardRef } from 'react'
import { useState } from 'react'
import { keys } from './Constants'

const ObservationsDiaglog = forwardRef(({dialogData, handleUpdateObs}, ref) => {
    useImperativeHandle(ref , () => ({ 
        showFormDiaglog : () => showFormDiaglog(),
        hideFormDiaglog : () => hideFormDiaglog()
    }))
    const [visible, setVisible] = useState(false)    

    const showFormDiaglog = (data) => {
        setVisible(true)
    }
    const hideFormDiaglog = () => {
        setVisible(false)
    }
    const handleUpdate = () => {
        handleUpdateObs()
        hideFormDiaglog()
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => handleUpdate()} autoFocus />
            </div>
        );
    }
    const convertKey = (e) => {
        if(e === "hrValue") {
            return "Heart Rate"
        } if(e === "rrValue") {
            return "Respiration Rate"
        } if(e === "tempValue") {
            return "Temperature"
        } if(e === "bpValue") {
            return "Systolic Blood Pressure"
        }if(e === "oxiValue") {
            return "Oxygen Saturation"
        }
    }
  return (
       <Dialog
        header="Total"
        visible={visible} 
        style={{ width: '50vw' }}
        footer={renderFooter('displayBasic')}
        onHide={() => setVisible(false)}
        >
            
                {dialogData && Array.isArray(dialogData) && dialogData.length > 0 
                ? 
                    <div className='p-0' style={{border: "1px solid rgb(240, 142, 142)",backgroundColor: "rgb(226, 186, 186)", borderRadius: '5px', fontSize:'20px' }}>
                        {dialogData.map(item => 
                            <div  key={item} className=" m-3">
                                <div className='font-bold m-3 p-1'
                                style={{color: "rgb(205, 92, 92)"}} >
                                    {/* 3 {convertKey(item)} */}
                                    <span className='p-2 m-1'
                                    style={{ border: "2px solid rgb(240, 142, 142)", borderRadius: '5px'  }}> 3 </span>
                                    <span> {convertKey(item)} </span>
                                </div>
                                
                            </div>
                        )}
                    </div>
                :
                    <h5 className='text-center mt-3'> No Warnings </h5>
                }
        </Dialog>
  )
})

export default ObservationsDiaglog
