import { keys, levels, obsColWrapperData, operators } from "./Constants";

export function getLevelLabelByValue(data) {
    if(!data) {
        return " ";
    }

    let returnedLabel = ""
    for(let i = 0; i < levels.length; i++) {
        if(levels[i].value === data) {
            returnedLabel = levels[i].label
            break;
        }
    }

    return returnedLabel
}

export function getValueByKeyForChart(dataList, key) {
    if(!dataList || !Array.isArray(dataList)) {
        return [];
    }

    let returnedList = [];
    for(let i = 0; i < dataList.length; i++) {
        if(key === keys.bloodPressure) {
            let item = {};
            if(dataList[i]) {
                if(dataList[i][keys.syValue]) {
                    item.syIndex = i;
                    item.syValue = dataList[i][keys.syValue]
                } 
    
                if(dataList[i][keys.diValue]) {
                    item.diIndex = i;
                    item.diValue = dataList[i][keys.diValue];
                }

                if(dataList[i][keys.bpLimb]) {
                    item.bpLimb = dataList[i][keys.bpLimb];
                }

                if(dataList[i][keys.bpPosition]) {
                    item.bpPosition = dataList[i][keys.bpPosition];
                }
            }

            if(dataList[i][keys.syValue] && dataList[i][keys.diValue]) {
                returnedList.push(item);
            }
        } else {
            if(dataList[i] && dataList[i][key]) {
                let item = {
                    index: i,
                    value: dataList[i][key]
                }
    
                returnedList.push(item);
            }
        }
    }

    return returnedList;
}

export function getIndexOfValueInMultiCol(dataList, value) {
    if(!dataList || !Array.isArray(dataList) || !value) {
        return null;
    }

    let returnedIndex = null;
    for(let i = 0; i < dataList.length; i++) {
        if(dataList[i].operator === operators.between) {
            if((dataList[i].start && dataList[i].end)
                && (dataList[i].start <= value && value <= dataList[i].end)
            ) {
                returnedIndex = i;
                break;
            }
        } else if(dataList[i].value) {
            if(dataList[i].operator === operators.greaterOrEqualTo) {
                if((value >= dataList[i].value)) {
                    returnedIndex = i;;
                    break;
                }
            } else if(dataList[i].operator === operators.lessOrEqualTo) {
                if((value <= dataList[i].value)) {
                    returnedIndex = i;;
                    break;
                }
            } else if(dataList[i].operator === operators.greaterThan) {
                if((value > dataList[i].value)) {
                    returnedIndex = i;;
                    break;
                }
            } else if(dataList[i].operator === operators.lessThan) {
                if((value < dataList[i].value)) {
                    returnedIndex = i;;
                    break;
                }
            }
        }
    }

    return returnedIndex;
}

export function getValueByKeyAtPosition(dataList, key, data, index) {
    if(!data || !data[key] || !dataList || !Array.isArray(dataList) || dataList.length < 1) {
        return "";
    }

    let returnedValue = "";
    const dataValue = data[key];

    for(let i = 0; i < dataList.length; i++) {
        if(dataList[i].operator === operators.between) {
            if((dataList[i].start && dataList[i].end)
                && (dataList[i].start <= dataValue && dataValue <= dataList[i].end)
                && (dataList.indexOf(dataList[i]) === index)
            ) {
                returnedValue = dataValue;
                break;
            }
        } else if(dataList[i].value){
            if(dataList[i].operator === operators.greaterOrEqualTo) {
                if((dataValue >= dataList[i].value)
                && (dataList.indexOf(dataList[i]) === index)
            ) {
                returnedValue = dataValue;
                break;
                }
            } else if(dataList[i].operator === operators.lessOrEqualTo) {
                if((dataValue <= dataList[i].value)
                && (dataList.indexOf(dataList[i]) === index)
            ) {
                returnedValue = dataValue;
                break;
                }
            } else if(dataList[i].operator === operators.greaterThan) {
                if((dataValue > dataList[i].value)
                && (dataList.indexOf(dataList[i]) === index)
            ) {
                returnedValue = dataValue;
                break;
                }
            } else if(dataList[i].operator === operators.lessThan) {
                if((dataValue < dataList[i].value)
                && (dataList.indexOf(dataList[i]) === index)
            ) {
                returnedValue = dataValue;
                break;
                }
            }
        }
    }

    return returnedValue;
}

export function getShortenFromValue(value, dataRange) {
    if(!value) {
        return;
    }

    if(!dataRange || !Array.isArray) {
        return value;
    }

    let returnedShorten = "";
    for(let i = 0; i < dataRange.length; i++) {
        if(dataRange[i] && dataRange[i].value && dataRange[i].value === value) {
            if(dataRange[i].shorten) {
                returnedShorten = dataRange[i].shorten;
                break;
            }
        }
    }

    return returnedShorten;
}

export function getLabelFromKey(label) {
    if(!label) {
        return "";
    }

    let returnedLabel = "";
    for(let i = 0; i < obsColWrapperData.length; i++) {
        if(obsColWrapperData[i].key === label) {
            returnedLabel = obsColWrapperData[i].label;
            break;
        }
    }

    return returnedLabel;
}

