import React from 'react';

const PageContentWrapper = ({children, mb, px}) => {
    return (
        <div className="grid">
            <div className="col-12">
                <div className={`card ${px ? px : ""}`} style={{marginBottom: mb ? mb : 'inherit'}}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageContentWrapper;