import { useQuery } from '@tanstack/react-query';
import { Avatar } from 'primereact';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getAllDoctors, getBatteryLevel, getDoctorId, getListPatients, getPatientId, setBatteryLevel, setPatientFirebaseId, setPatientId, setPatientName } from '../../api/CurrentServiceWorker';
import { getBatteryIcon } from '../../utils/CommonUtils';
import { openPage } from '../../utils/NavigatorUtils';
import { convertDataList } from '../doctor/Ultils';


const PatientListView = () => {
    const {data: patientDataList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients(),
        {
            enabled: !!getDoctorId()
        }
    )
    const {data: nurseDataList} = useQuery(
        ['doctorList', 1],
        () => getAllDoctors(),
        {
            enabled: !!getDoctorId()
        }
    )
    
    
    const dataList = useMemo(() => {
        return convertDataList(patientDataList, nurseDataList, "nurseList", "patientComp")
    }, [nurseDataList?.length, patientDataList?.length])

    const handleViewPatient = (patient) => {
        setPatientId(patient.patient_id); 
        setPatientFirebaseId(patient.uid); 

        let patientName = "";
        patientName += patient.first_name ? patient.first_name : "";
        patientName += patient.last_name ? patient.last_name : "";
        setPatientName(patientName)

        setBatteryLevel(patient.battery_level)

        openPage(`/patient/${patient.patient_id}`)
    }

    const setBatteryForCurrentPatient = useCallback(() => {
        if(patientDataList && patientDataList.length > 0 && getPatientId()) {
            let patientData = patientDataList.filter(x => x.patient_id === getPatientId())
            if(patientData && patientData.length > 0) {
                setBatteryLevel(patientData[0].battery_level)
            }
        }
    }, [])

    useEffect(() => {
        const setBatteryTimer = setInterval(() => {
            setBatteryForCurrentPatient()
        }, 5 * 60 * 1000)

        return () => {
            clearInterval(setBatteryTimer)
        }
    }, [])

    return(
        <div className='col-12 my-2 px-0'>
            {dataList && dataList.length > 0 &&
                dataList.map((patient, idx) => {
                    let assignedNurses = "";
                    if(patient.nurseList && Array.isArray(patient.nurseList) && patient.nurseList.length > 0) {
                        for(let i = 0; i < patient.nurseList.length; i++) {
                            if(i === 0 && patient.nurseList[i]) {
                                assignedNurses = patient.nurseList[i].firstname + " " + patient.nurseList[i].lastname;
                            } else if(patient.nurseList[i]) {
                                assignedNurses += `, ${patient.nurseList[i].firstname + " " + patient.nurseList[i].lastname}`
                            }
                        }
                    }

                    return (
                        <div key={`${patient?.patient_id}-${idx}`} className='flex align-items-center mb-4' style={{cursor: 'pointer'}} onClick={() => handleViewPatient(patient)}>
                            <Avatar size='large' icon="pi pi-user" shape='circle' key={patient?.patient_id} className="mr-3" />
                            <div className='flex align-items-center flex-column'>
                                <div>
                                    <p className='font-bold mb-0'>{patient.first_name} {patient.last_name}</p>
                                    <div>
                                        <i className={`fas ${getBatteryIcon(patient.battery_level)} mr-2`}></i>
                                        <span>{patient.battery_level && patient.battery_level !== "null" ? `${patient.battery_level}%` : "No Data"}</span>
                                    </div>
                                    <div>
                                        {assignedNurses && 
                                            <p>Carer(s): {assignedNurses}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PatientListView;