import { Chart } from 'primereact';
import React, { useEffect, useState, useRef } from 'react'
import { SelectButton } from 'primereact/selectbutton';
import { useQuery } from '@tanstack/react-query';
import { getExerciseTableData, getPatientId } from '../../api/CurrentServiceWorker';
import moment from 'moment';
import { capitalize } from '../../utils/CommonUtils';
import { revertStringToTime } from '../../utils/DateTimeUtils'


const ExercisesChart = () => {
    const chartRef = useRef(null)
    const [selectedBtnValue, setSelectedBtnValue] = useState("steps");

    const [viewMode, setViewMode] = useState('week')
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedDateData, setSelectedDateData] = useState({
        label: [],
        datasets: [{
            label: 'First Dataset',
            data: [],
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
        }]
    })
    
    const generateDayTimeLabels = () => {
        let tmp = [];

        for(let i = 0; i < 25; i++) {
            let timeLabel = i < 10 ? `0${i}` : i;
            timeLabel += ":00";

            tmp.push(timeLabel);
        }

        return tmp;
    }
    const dayTimeLabels = generateDayTimeLabels();

    const {data : chartData} = useQuery(['excerciseTable', getPatientId()], async() => await getExerciseTableData())
    const data = chartData && Array.isArray(chartData) ? chartData.sort((a,b) => b.id - a.id) : []

    // steps || sleep
    const getDataFromKey = (key) => {
        if(!data || !Array.isArray(data)) {
            return []
        }

        let maxValue = 0;
        let returnedValues = [];

        for(let i = 0; i < data.length; i++) {
            if(data[i] && data[i].created_at && data[i][key]) {
                if(returnedValues.length < 1) {
                    let item = {
                        label: moment(data[i].created_at).format("DD MMMM"),
                        value: data[i][key]
                    }

                    maxValue = data[i][key];

                    returnedValues.push(item);
                } else {
                    let label = moment(data[i].created_at).format("DD MMMM");
                    let matchLabel = false;
                    for(let j = 0; j < returnedValues.length; j++) {
                        if( returnedValues[j].label && returnedValues[j].label === label) {
                            matchLabel = true;

                            if(maxValue > returnedValues[j].value) {
                                returnedValues[j].value = maxValue;                               
                            }

                            break;
                        }
                    }

                    if(!matchLabel) {
                        let item = {
                            label: moment(data[i].created_at).format("DD MMMM"),
                            value: data[i][key]
                        }

                        maxValue = data[i][key];
                        returnedValues.push(item);
                    }
                }
            }
        }

        return returnedValues;
    }

    const [basicData, setBasicData] = useState({
        labels: [],
        datasets: [
            {
                label: 'First Dataset',
                data: [],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },
        ]}
    );
    
    
    const loadData = () => {
        if(selectedBtnValue) {
            const keyPairDataList = getDataFromKey(selectedBtnValue);
            const labelList = keyPairDataList.length > 0 ? keyPairDataList.map((item) => item.label) : []
            const valueList = keyPairDataList.length > 0 ? keyPairDataList.map((item) => item.value) : []

            setBasicData({
                ...basicData,
                labels: labelList && labelList.length > 0 ? labelList.reverse() : [],
                datasets: [
                    {
                        ...basicData.datasets[0],
                        label: selectedBtnValue ? capitalize(selectedBtnValue) : "",
                        data: valueList && valueList.length > 0 ? valueList.reverse() : [...Array(10).keys()]
                    }
                ]
            })
        } 
    }

    useEffect(() => {
        loadData();
    }, [selectedBtnValue, data?.length])

    const chartOptions = [
        {label: 'Steps', value:"steps"},
        {label: 'Sleep', value:"sleep"},
        {label: 'Calories', value:"calos"},
        {label: 'Heart Rate', value:"hrValue"},
        {label: 'SP.02', value:"oxiValue"},
    ]

    const handleSelectButtonChange = (e) => {
        if(e.value) {
            setSelectedBtnValue(e.value)
        }
        
        setViewMode("week")
        setSelectedDate(null)
        setSelectedDateData({
            label: [],
            datasets: [{
                label: 'First Dataset',
                data: [],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            }]
        })
    }

    const calculateDatasets = (datasets) => {
        if(!datasets || !Array.isArray(datasets) || datasets.length < 1) {
            return [];
        }

        let returnedDatasets = [];
        let skipIndexes = [];

        for(let i = 0; i < datasets.length; i++) {
            if(i !== 0 && datasets[i] && datasets[i + 1]) {
                let nextValue = datasets[i + 1];
                let currentValue = datasets[i];

                if(nextValue > currentValue) {
                    let value = nextValue - currentValue;
                    returnedDatasets.push(value);
                } else if(nextValue === currentValue) {
                    if(!skipIndexes.includes(i)) {
                        returnedDatasets.push(currentValue);
                    }

                    returnedDatasets.push(0);
                    skipIndexes.push(i + 1);
                } else {
                    returnedDatasets.push(currentValue)
                }
            } else {
                if(skipIndexes.includes(i) && datasets[i]) {
                    returnedDatasets.push(0)
                } else {
                    returnedDatasets.push(datasets[i])
                }
            }
        }

        return returnedDatasets;
    }

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        legend: {
            display: false
        },
        'onClick': function (evt, item) {
            if(viewMode === 'day') {
                return;
            }
            
            const eventDate = evt.chart?.tooltip?.title ? evt.chart?.tooltip?.title[0] : "";
            if(eventDate) {
                let dateFmt = eventDate.replace(/ /g, " ");

                if(dateFmt) {
                    let dateDataFilter = data.filter(x => {
                        let updatedAtFmt = x.created_at ? moment(x.created_at).format('DD MMMM') : "";
                        return updatedAtFmt === dateFmt
                    })

                    let dateData = dateDataFilter.sort((a,b) => a.id - b.id)

                    let datasets = [];

                    if(dateData && dateData.length > 0) {
                        for(let i = 0; i < dayTimeLabels.length; i++) {
                            let datasetValue = 0;

                            if((i + 1 !== dayTimeLabels.length) && dayTimeLabels[i]) {
                                let currentValueFlag = 0;
                                const startLabelTimeFmt = revertStringToTime(dayTimeLabels[i]);
                                const endLabelTimeFmt = revertStringToTime(dayTimeLabels[i + 1]);

                                for(let j = 0; j < dateData.length; j++) {
                                    if(dateData[j].created_at) {
                                        let toCompTimeFmt = revertStringToTime(moment(dateData[j].created_at).format("HH:mm"));

                                        if(startLabelTimeFmt <= toCompTimeFmt && toCompTimeFmt < endLabelTimeFmt) {
                                            const dataValue = dateData[j][selectedBtnValue];
                                            
                                            if(dataValue > currentValueFlag) {
                                                datasetValue += dataValue - currentValueFlag

                                                currentValueFlag = dataValue;
                                            } else {
                                                datasetValue += i === 0 ? 0 : (currentValueFlag - dataValue)
                                            }
                                        }
                                    }
                                }
                            }

                            datasets.push(datasetValue);
                            datasetValue = 0;
                        }
                    }

                    setSelectedDate(dateFmt)
                    setViewMode('day')
                    setSelectedDateData({
                        labels: dayTimeLabels,
                        datasets: [
                            {
                                ...selectedDateData.datasets[0],
                                backgroundColor: '#42A5F5',
                                label: selectedBtnValue ? capitalize(selectedBtnValue) : "",
                                data: datasets && selectedBtnValue === "steps" ? calculateDatasets(datasets) : datasets
                            }
                        ]
                    })
                }
            }
        }
    };

    return (
        <div className='grid font-bold text-center p-0 border-round summary-box m-2 clearfix'>       
            <div className='col-12 mb-3'>
                <SelectButton
                    value={selectedBtnValue}
                    options={chartOptions} 
                    onChange={handleSelectButtonChange}
                />
            </div>

            {viewMode === "day" && selectedDate &&
                <div className='col-12 mb-3'>
                    <h5>Selected Date : {selectedDate}</h5>
                </div>
            }

            <Chart ref={chartRef} className='col-12' type={viewMode === "week" ? 'line' : 'bar'} data={viewMode === "week" ? basicData : selectedDateData}
                options={options} 
            />
        </div>
    )
}

export default ExercisesChart
