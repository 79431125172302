import React, { useEffect, useState } from 'react';
import { loadingEvent } from './LoadingService';
import { ProgressSpinner } from 'primereact';

const LoadingOverlay = () => {
    const [show, setShow] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        loadingEvent.on('LOADING', (show, text) => showLoading(show, text))

        return () => loadingEvent.removeListener('LOADING', (show) => showLoading(show))
    }, [])

    const showLoading = (show, text = '') => {
        if(show) {
            document.body.classList.add("noscroll-loading");
            setText(text ? text : '')
        } else {
            document.body.classList.remove("noscroll-loading");
            setText('')
        }

        setShow(show)
    }

    return (
        <div style={{...styles.overlay, display: show ? 'block': 'none'}}>
            <div style={styles.spinnerBlock}>
                <ProgressSpinner strokeWidth="4"/>
            </div>     
            
            {text &&
                <div className='pp-spinner-text'>
                    <h3>{text}</h3> 
                </div>
            }
        </div>
    )
}

export default LoadingOverlay

const styles = {
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2050,
        textAlign: 'center'
    },
    spinnerBlock: {
        position: 'relative',
        top: '50%',
        zIndex: 1080,
        textAlign: 'center'
    }
}