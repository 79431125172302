import jsPDF from 'jspdf';
import { Button } from 'primereact';
import { Sidebar } from 'primereact/sidebar';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ObservationPdfPreview = forwardRef((props, ref) => {
    useImperativeHandle(ref , () => ({ 
        showForm : (data) => showForm(data)
    }))

    const [imageData, setImageData] = useState(null)
    const [visible, setVisible] = useState(false);

    const showForm = (data) => {
        setVisible(true)
        setImageData(data)
    }

    const hideForm = () => {
        setVisible(false)
    }

    const handleExportToPDF = () => {
        if(imageData) {
            var doc = new jsPDF('p', 'pt', 'a4');
            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imageData, null, 0, 0, width, height, null, 'FAST');
            doc.save(`${props.fileName}.pdf`);
    
            hideForm();
        }
    }

    return(
        <Sidebar visible={visible} fullScreen onHide={() => hideForm()} blockScroll>
            <div className='px-3'>
                <h2 className='font-bold mb-0'> Preview </h2>
                <hr style={{width: '3%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
            </div>
            <div className='col-12'><hr/></div>
            <div className='flex justify-content-center align-items-center pt-3'>
                <img 
                    src={imageData}
                    style={{width: "40vw", height: "80vh"}}
                    alt={"Patient Obs PDF"}
                />
            </div>
            <div className='col-12'><hr/></div>

            <div className='mb-5 flex justify-content-end'>
                <Button className='p-button-secondary p-button-text px-3' label="Cancel" onClick={() => hideForm()}/>
                <Button label="Export to PDF" onClick={() => handleExportToPDF()}/>
            </div>
           
        </Sidebar>
    )
})

export default ObservationPdfPreview

