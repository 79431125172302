import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

//#region Global StyleSheet
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/components/flags/flags.css';
import './assets/components/Components.scss';
import './assets/layout/layout.scss';
import './App.scss';
import './custom.css'


//#endregion 

//#region Import Page Components
import { clearStorage, isAuthenticated } from './utils/AuthUtils';
import Login from './components/authentication/Login';
import Summary from './components/summary/Summary';
import Infomation from './components/infomation/Infomation';
import Home from './components/home/Home';
import { OverflowPatientListView } from './components/patient/OverflowPatientListView';
import Observations from './components/observations/Observation';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { deleteIncrement, getAdminId, getDoctorFirebaseId, getDoctorId, getLineChartDrawn, getPatientId, isAdmin, removeAdminId, removeBatteryLevel, removeDoctorFirebaseId, removeDoctorId, removeDoctorName, removePatientFirebaseId, removePatientId, removePatientName, setLineChartDrawn } from './api/CurrentServiceWorker';
import Exercises from './components/exercise/Exercises';
import Medicine from './components/medicine/Medicine';
import AppContextProvider, { AppContext } from './context/AppContext';
import { onMenuChanged } from './components/menu/MenuService';
import ChatView from './components/chat/ChatView';
import DoctorManagement from './components/doctor/DoctorManagement';
import FirebaseAuthProvider from './components/authentication/FirebaseAuthProvider';
import { openPage } from './utils/NavigatorUtils';
import GPDatatable from './components/gp/GPDatatable';
import Registration from './components/registration/Registrations';
import Registrations from './components/registration/Registrations';
//#endregion

const queryClient = new QueryClient()

const App = () => {
    const copyTooltipRef = useRef();
    const location = useLocation();
    const shouldHideSideBar = (location.pathname === "/") || (!!isAdmin() && location.pathname === "/admin/doctor-management");
    const [layoutMode, setLayoutMode] = useState(shouldHideSideBar ? 'overlay' : 'static');
    // const [layoutMode, setLayoutMode] = useState('overlay');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        document.documentElement.style.fontSize = '13px';
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        if(location.pathname !== `/patient/${getPatientId()}/observation`) {
            if(location.pathname === "/" || location.pathname === "" || location.pathname === "/admin/doctor-management") {
                setLayoutMode("overlay");

                removePatientId();
                removePatientFirebaseId();
                removePatientName();
                removeBatteryLevel();
                deleteIncrement();
                
                if(location.pathname === "/" || location.pathname === "") {
                    if(!getDoctorId() && !getAdminId()) {
                        clearStorage();

                        openPage("/")
                    }  else if(!getDoctorId() && getAdminId()) {
                        openPage("/admin/doctor-management")
                    }
                }

                if(location.pathname === "/admin/doctor-management") {
                    removeDoctorId()
                    removeDoctorFirebaseId()
                    removeDoctorName()
                }
            }  else {
                setLayoutMode("static")
            }
        }

        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if(location.pathname !== `/patient/${getPatientId()}/observation`) { 
            onMenuChanged();
        }
        
    }, [staticMenuInactive])

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        onMenuChanged()
        
        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
           
            items: [{
                label: 'Information', icon: 'far fa-address-book', to: `/patient/${getPatientId()}`
            }]
        },
        {
            
            items: [{
                label: 'Summary', icon: 'fas fa-poll-h', to: `/patient/${getPatientId()}/summary`
            }]
        },
        {
           
            items: [{
                label: 'Obs Chart', icon: 'fas fa-sliders-h', to: `/patient/${getPatientId()}/obs-chart`
            }]
        },
        {
           
            items: [{
                label: 'Activities', icon: 'fas fa-chart-bar', to: `/patient/${getPatientId()}/activities`
            }]
        },
        {
           
            items: [{
                label: 'Pharmacy', icon: 'fas fa-calendar-plus', to: `/patient/${getPatientId()}/pharmacy`
            }]
        },
        {
           
            items: [{
                label: 'Messages', icon: 'fas fa-comment-medical', to: `/patient/${getPatientId()}/chat`
            }]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light',
        'layout-theme-dark': layoutColorMode !== 'light'
    });

    return (
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                <FirebaseAuthProvider>
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} shouldHideMenuIcon={shouldHideSideBar}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                    
                    {!shouldHideSideBar &&
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                    }

                    <div className="layout-main-container">
                        <div className="layout-main">
                            {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                            
                            <Route path="/" exact component={Home} />
                            <Route path="/patient/:id/obs-chart" component={Observations} />
                            <Route path="/patient/:id/summary" component={Summary} />
                            <Route path="/patient/:id/activities" component={Exercises} />
                            <Route path="/patient/:id/pharmacy"  render={() => <Medicine />}  />
                            <Route path="/patient/:id/chat" component={ChatView} />
                            <Route path="/patient/:id" exact component={Infomation}/>
                            {isAdmin() &&
                                <>
                                    <Route path="/admin/doctor-management" render={() => <DoctorManagement type={0} />} />
                                    <Route path="/admin/gp-management" render={() => <GPDatatable />}/>
                                </>
                            }
                            {
                                <Route path="/registration" render={() => <Registrations type={3} />} />
                            }
                            
                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    {!shouldHideSideBar &&
                        <OverflowPatientListView 
                            rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                            layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} 
                        />
                    }

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>

                {!shouldHideSideBar &&
                    <OverflowPatientListView 
                        rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} 
                    />
                }

                {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
                <ReactQueryDevtools initialIsOpen={false} />
                </FirebaseAuthProvider>
            </AppContextProvider>
        </QueryClientProvider>
    );

}

export default App;
