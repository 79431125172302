import { Column, DataTable } from 'primereact'
import React from 'react'

const PatientAssignedNurses = ({data}) => {
  return (
    <div>
      {console.log(data)}
        <DataTable value={data ? data : []} responsiveLayout="scroll">
          <Column field="id" header="ID"></Column>
          <Column header="Full Name" body={(rowData) => rowData.firstname + " " + rowData.lastname }></Column>
          <Column field="username" header="User Name"></Column>
          <Column field="email" header="Email"></Column>
        </DataTable>
    </div>
  )
}

export default PatientAssignedNurses
