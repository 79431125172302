import { addDoc, collection } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect } from 'react'
import { signup } from '../../ firebase/FirebaseAuth';
import { firestore } from '../../ firebase/FirebaseConfig';
import { updateFirebaseUserId, updatePatientFirebaseUserId } from '../../api/CurrentServiceWorker';
import { capitalize } from '../../utils/CommonUtils';
import { openPage } from '../../utils/NavigatorUtils';
import { firebaseAuthEvent, removeAuthChange } from './FirebaseAuthService';

export const roleTypes = {
    doctor: "doctor",
    patient: "patient"
}

const FirebaseAuthProvider = ({children}) => {
    useEffect(async() => {
        await firebaseAuthEvent.on('AUTHCHANGE', handleAuthChange)

        return () => removeAuthChange();
    }, [])
   
    const handleAuthChange = async(data, type = roleTypes.patient) => {
        if(data && !data.uid && data.email) {
            try{
                const firebaseSignUpData = await signup(`${data.email}`, `${data.password}`);
                if(firebaseSignUpData && firebaseSignUpData.user && firebaseSignUpData.user.uid) {
                    if(type === roleTypes.doctor) {
                        await updateFirebaseUserId(data.id, firebaseSignUpData.user.uid);
                    } else {
                        await updatePatientFirebaseUserId(data.id, firebaseSignUpData.user.uid);
                    }
                    
                    // Add user to Firestore
                    try {
                        let firstName = type === roleTypes.doctor 
                                            ?   data.firstname 
                                                    ? data.firstname : "No Data"
                                            :   data.first_name 
                                                    ? data.first_name : "No Data"

                        let lastName = type === roleTypes.doctor 
                                            ?   data.lastname 
                                                    ? data.lastname : "No Data"
                                            :   data.last_name 
                                                    ? data.last_name : "No Data"
                        
                        let createdAt = moment().format("yyyy-MM-DD")

                        await addDoc(collection(firestore, "users"), {
                            uid: firebaseSignUpData.user.uid,
                            hospitalName: data.hospital ? data.hospital : "No Data",
                            firstName: firstName,
                            lastName: lastName,
                            createdAt: createdAt,
                            email: data.email,
                            role: type ? capitalize(type) : capitalize(roleTypes.doctor)
                        });
                      } catch (e) {
                        console.error("Error adding document: ", e);
                    }
                }
            } catch (error) {
                console.error("Error creating user in Firebase: ", error);
            }
        }
    }

    return children;
}

export default FirebaseAuthProvider;