import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, InputTextarea } from 'primereact';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { firestore } from '../../ firebase/FirebaseConfig';
import { getDoctorFirebaseId, getPatientFirebaseId, getPatientId } from '../../api/CurrentServiceWorker';
import PageContentWrapper from '../../utils/LayoutUtils';
import './chat.css'
import { collection, addDoc, getDoc, query, orderBy, where, onSnapshot, doc, serverTimestamp } from "firebase/firestore"; 
import moment from 'moment';
import { uuidv4 } from '@firebase/util';
import { Skeleton } from 'primereact/skeleton';

const ChatView = () => {
    const bottomChatElement = useRef(null) 
    const [loaded, setLoaded] = useState(false)
    const [messageList, setMessageList] = useState([])
    const [message, setMessage] = useState("")
    const [error, setError] = useState({
        readError: false,
        writeError: false
    });

    useEffect(async() => {  
        const unsub = await onSnapshot(collection(firestore, "rooms", `${getDoctorFirebaseId()}-${getPatientFirebaseId()}`, 'messages'), async(doc) => {
            let messages = []
            if(doc && doc.docs && Array.isArray(doc.docs)) {
                await doc.docs.forEach((item, idx) => {
                    let messageData = item.data();
                    return messages.push({key: idx, uid: messageData.uid, message: messageData.message, timestamp: messageData.timestamp, epochTime: messageData.epochTime})
                })
                setMessageList(orderMessagesByTime(messages))
                setLoaded(true)
                
                if(bottomChatElement && bottomChatElement.current && Object.keys(bottomChatElement.current) > 0) {
                    bottomChatElement.current.scrollIntoView({ behavior: "smooth" });
                }
            }
        });

        return () => unsub()
    }, [])

    const orderMessagesByTime = (dataList) => {
        if((!dataList || !Array.isArray(dataList)) || dataList.length < 1) {
            return [];
        }

       return dataList.sort((a, b) => a.epochTime - b.epochTime)
    }

    const handleSubmit = async() => {
        if(message) {
            setMessageList(prevState => [...prevState, {key: uuidv4(), message: message, uid: getDoctorFirebaseId(), timestamp: serverTimestamp(), epochTime: parseInt(new Date().getTime() / 1000)}])
            setMessage('')

            await addDoc(collection(firestore, 'rooms', `${getDoctorFirebaseId()}-${getPatientFirebaseId()}`, 'messages'), {
                uid: getDoctorFirebaseId(),
                message: message,
                timestamp: serverTimestamp(),
                epochTime: parseInt(new Date().getTime() / 1000)
            });
        }
    }

    return(
        <PageContentWrapper>
            <div style={{minHeight: '75vh'}}>
                <div className='mb-3'>
                    <h2 className='font-bold'>Conversation</h2>
                </div>
                <div className='flex flex-column justify-content-between' style={{minHeight: '70vh'}}>
                    <div className="chat-area">
                        {   
                            !loaded
                        ?
                            <div>
                                <Skeleton width="25rem" height='5rem' className="mb-2" borderRadius="8px"></Skeleton>
                                <Skeleton width="25rem" height='5rem' className="mb-2 chat-bubble current-user" borderRadius="8px"></Skeleton>
                                <Skeleton width="25rem" height='5rem' className="mb-2" borderRadius="8px"></Skeleton>
                                <Skeleton width="25rem" height='5rem' className="mb-2 chat-bubble current-user" borderRadius="8px"></Skeleton>
                                <Skeleton width="25rem" height='5rem' className="mb-2" borderRadius="8px"></Skeleton>
                                <Skeleton width="25rem" height='5rem' className="mb-2 chat-bubble current-user" borderRadius="8px"></Skeleton>
                            </div>                    
                        :
                                messageList && messageList.length > 0 
                            ? 
                                messageList.map(chat => {
                                    return <h5 key={chat.key} className={`chat-bubble ${getDoctorFirebaseId() === chat.uid ? "current-user" : ""}`}>
                                    <strong>{chat.message}</strong>
                                    <br />
                                    <span className="chat-time float-right">{chat.epochTime ? moment(moment.unix(chat.epochTime)).startOf('minute').fromNow() : 'Just now'}</span>
                                    </h5>
                                })
                            :  
                                <div className='flex flex-column justify-content-center align-items-center'>
                                    <div className='mb-3'>
                                        <img 
                                            src={require("../../assets/images/Start-Convo.svg")}
                                            style={{
                                                height: 250,
                                                width: '100%'
                                            }}
                                        />
                                    </div>
                                    <h5>Let's start a conversation with the CLient</h5>
                                </div>
                        }
                        <div style={{ float:"left", clear: "both" }}
                            ref={bottomChatElement}>
                        </div>
                    </div>
                
                    <div className='flex'>
                        <div style={{width: '85%', padding: 5, position: 'relative'}}>
                            <InputTextarea 
                                rows={3}
                                value={message} autoResize
                                className="col-12 px-3 py-2"
                                placeholder='Input your message here'
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div style={{width: '15%', padding: 5}}>
                            <Button className="col-12 px-5" style={{height: '45px'}} icon="pi pi-send" iconPos='right'
                                onClick={() => handleSubmit()} label="SEND"/>
                        </div>
                    </div>
                </div>
            </div>
        </PageContentWrapper> 
    )
}

export default ChatView