import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Dialog, Dropdown, InputTextarea } from 'primereact';
import { createPatientNote, getDoctorId, getPatientId } from '../../api/CurrentServiceWorker';
import moment from 'moment';
import { showNotification } from '../notification/NotificationService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const PatientNoteForm = forwardRef(({patientFirstName, patientLastName}, ref) => {
    useImperativeHandle(ref, () => ({
        showForm : (data) => showForm(data),
        hideForm : () => hideForm()
    }))

    // NEW
    const queryClient = new useQueryClient();

    const typeOptions = [
        {label: "Medical Notes", value: "Medical Notes"},
        {label: "Carer Notes", value: "Carer Notes"},
        {label: "AHP Notes", value: "AHP Notes"},
        {label: "RAT", value: "RAT"},
        {label: "Client Review", value: "Client Review"},
        {label: "Investigation Results", value: "Investigation results"},
        {label: "Frailty Contact", value: "Frailty Contact"}
    ]

    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({});

    const showForm = (data) => {
        setData(data ? {...data} : {})
        setIsEdit((data && data.id));
        setVisible(true)
    }

    const hideForm = () => {
        setData({});
        setIsEdit(false);
        setVisible(false)
    }

    const renderFooter = () => {
        return (
            <div>
                {data && data.id ?
                    <Button label="Close" icon="pi pi-times" onClick={() => setVisible(false)} autoFocus />
                :   
                    <>
                        <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={() => handleCreateNote()} autoFocus />
                    </>
                }
            </div>
        );
    }

    const renderHeader = () => {
        return(
            <div className='col-12'>
                <h4 className='mb-0'>{isEdit ? "Patient's note" : "Add a new note"}</h4>
            </div>
        )
    }
    const doctorId = localStorage.getItem('doctorId') ? JSON.parse(localStorage.getItem('doctorId')) : null
    const handleCreateNote = async () => {
        let toPostData = {
            ...data,
            doctor_id: doctorId,
            patient_id: getPatientId(),
            created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
            updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss")
        }

        // NEW
        mutate(toPostData);
    }

    // NEW
    const { mutate } = useMutation(newNote => createPatientNote(newNote), {
        onSuccess: (data, variables, context) => {
            if(data && data.id) {
                showNotification("success", "Success", "Note Created");

                queryClient.setQueryData(
                    ['patientNoteList', getPatientId()],
                    oldDataList => oldDataList && Array.isArray(oldDataList) ? [...oldDataList, data] : [data]
                )
    
                hideForm();
            } else {
                showNotification("error", "Error", "Fail to create note");
            }
        },
        onError: (error, variables, context) => {
            showNotification("error", "Error", "Fail to create note");
        }
    })
    
    return( 
        <Dialog visible={visible} header={renderHeader} footer={renderFooter} onHide={() => hideForm()} blockScroll={true}>
            <div className='grid'>
                <div className='col-12'>
                    <Dropdown 
                        className='col-12 px-0'
                        name={'noteType'}
                        filter filterBy='label' filterPlaceholder='Find a type'
                        placeholder={"Select a type"}
                        value={data.note_type}
                        options={typeOptions}
                        onChange={(e) => setData({...data, note_type: e.value})}
                    />
                </div>
                <div className='col-12'>
                    <InputTextarea 
                        className='col-12'
                        value={data.note} rows={7}
                        onChange={(e) => setData({...data, note: e.target.value})}
                    />
                </div>
            </div>
        </Dialog>
    )
})

export default PatientNoteForm;