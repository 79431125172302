import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useQuery } from '@tanstack/react-query';
import { getExerciseTableData, getPatientId } from '../../api/CurrentServiceWorker';
import { Button, Dialog, Dropdown, TreeTable } from 'primereact';
import Map from '../map/Map';
import moment from 'moment';
import PigeonMap from '../map/PigeonMap';
import { useMemo } from 'react';

const ExercisesTable = () =>{
  const [showMaps, setShowMaps] = useState(false)
  const [locationData, setLocationData] = useState({})
  const [pageable, setPageable] = useState({
    pageNumber: 0,
    rows: 25
  })
  const [expandedRows, setExpandedRows] = useState([]);

  const {data} = useQuery(['excerciseTable', getPatientId()], async() => await getExerciseTableData())

  const convertDataList = () => {
    if(data && Array.isArray(data)){
      let dataList = []
      for(let i = 0 ; i < data.length ; i++){
        if(data[i] && data[i].created_at) {
          let item = {
            ...data[i],
            "groupBy": moment(data[i].created_at).format("yyyy-MM-DD")
          }

          dataList.push(item)
        }
      }

      return dataList
    }

    return [];
  }

  const dataList = useMemo(() => {
    return convertDataList()
  }, [data?.length])

  const columns = [
    {key: 'created_at',label:'Time'},
    {key: 'steps',label:'Steps'},
    {key: 'sleep',label:'Sleep' , time : "hr"},
    {key: 'calos',label:'Calories'},
    {key: 'hrValue',label:'Heart Rate'},
    {key: 'oxiValue',label:'SpO2'},
    {key: 'fall',label:'Fall'},
    {key: 'epileptic', label: 'Seizure'}
  ];
  const handleClick = (rowData) => {
    setShowMaps(true);
    setLocationData(rowData ? rowData : {})
  }
  const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.key} header={col.label}
      body={(rowData) => (
        col.key === "created_at" && rowData.created_at) 
          ? moment(rowData.created_at).format("HH:mm") 
          :  (!rowData[col.key] || (rowData[col.key] === "undefined")) 
            ? "-"
            : (col.key === "fall" || col.key === "epileptic")
              ? rowData[col.key] === "true" ? "X" : "-"
              : col.key === "sleep"
                ?  convertToSleepValue(rowData[col.key])
                :  rowData[col.key] 
        }
      />;
  });
  const actionBodyTemplate = (rowData) => {
    return <Button onClick={() => handleClick(rowData)} type="button" icon="pi pi-bookmark"
          disabled={(!rowData.longitude || !rowData.latitude)} 
      />;
  }
  const renderFooter = () => {
    return (
        <div>
            <Button className='mt-3 px-3' label="Close" icon="pi pi-times" onClick={() => setShowMaps(false)} autoFocus />
        </div>
    );
  }
  const convertToSleepValue = (value) => {
    if(!value) {
      return "-"
    }

    let convertedValue = parseFloat(value);

    let hourValue = convertedValue ? parseInt(convertedValue) : 0
    let hourValueString = hourValue ? `${hourValue}h ` : "";

    let minuteValue = (convertedValue && (convertedValue - hourValue) > 0) ? parseInt(((convertedValue - hourValue).toFixed(2) * 60)) : 0
    let minuteValueString = minuteValue ? `${minuteValue}m` : ""

    let returnedValue = `${hourValueString} ${minuteValueString}`
    return returnedValue
  }

  const paginatorTemplate = () => {
    const dropdownOptions = [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
    ];

    return <Dropdown
        value={pageable.rows || 10} options={dropdownOptions} 
        onChange={(e) => setPageable({...pageable, rows: e.value})} 
    />;
  }

  const renderHeader = () => {
    return(
      <div className='col-12 flex justify-content-between align-items-center p-0'>
        <h4><strong>Activity Records</strong></h4>
        {paginatorTemplate()}
      </div>
    )
  }

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="image-text">{data.groupBy}</span>
      </React.Fragment>
    );
  };

  return (
    <div className='col-12 font-bold text-center p-0 border-round summary-box clearfix'>
      <DataTable
          header={renderHeader()}
          value={dataList ? dataList : []}
          rowGroupMode="subheader"
          groupRowsBy="groupBy"
          sortMode="single"
          sortField="groupBy"
          sortOrder={-1}
          responsiveLayout="scroll"
          expandableRowGroups rowHover
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowGroupHeaderTemplate={headerTemplate}
          paginator first={pageable.pageNumber * pageable.rows} rows={pageable.rows}
          onPage={e => setPageable({...pageable, pageNumber: e.page})} totalRecords={dataList ? dataList.length : 0}
        >
          <Column header="Date"></Column>
          {dynamicColumns}
          <Column body={actionBodyTemplate}/>
      </DataTable>
      <div>
        <Dialog
          header="Map"
          visible={showMaps} 
          style={{ width: '60vw' }}
          footer={renderFooter('displayBasic')}
          onHide={() => setShowMaps(false)}
        >
          <PigeonMap 
            longtitude={locationData?.longitude}
            latitude={locationData?.latitude}
          />
          </Dialog>
        </div>
    </div>
  )
}

export default ExercisesTable
