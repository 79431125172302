import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "primereact/avatar";
import PageContentWrapper from "../../utils/LayoutUtils";
import { convertKeyToLabel, phoneFormat } from "../../utils/CommonUtils";
import { useQuery } from "@tanstack/react-query";
import {
  getAllDoctors,
  getPatientId,
  getPatientInfoById,
  getUserType,
} from "../../api/CurrentServiceWorker";
import PatientNotes from "../patient/PatientNotes";
import { Button, TabPanel, TabView } from "primereact";
import PatientForm from "../patient/PatientForm";
import moment from "moment";
import fallsIcon from "./../../assets/icon/fallsIcon.png"
import lowMoodIcon from "./../../assets/icon/lowmood.png"
import dementiaIcon from "./../../assets/icon/dementia.png"
import learningDisabilityIcon from "./../../assets/icon/learningDisability.png"
import { Tooltip } from 'primereact/tooltip'
import { useMemo } from "react";
import PatientAssignedNurses from "../patient/PatientAssignedNurses";

const Infomation = () => {
  const patientForm = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0);
  const { data } = useQuery(
    ["patientDetail", getPatientId()],
    async () => await getPatientInfoById()
  );

  // List of all Nurses
  const {data: nurseList} = useQuery(
    ["doctorList", 1],
    () => getAllDoctors()
  )
  
  // IDs of assigned Nurses
  const assignedNurses = data && data[0] && data[0].chooseNurse ? JSON.parse(data[0].chooseNurse) : [];

  const getAssignedNursesList = () => {
    if(!nurseList || !Array.isArray(nurseList) || !assignedNurses || !Array.isArray(assignedNurses)) {
      return [];
    }

    let returnedList = [];
    for(let i = 0; i < assignedNurses.length; i++) {
      if(assignedNurses[i]) {
        let nurseData = nurseList.filter(x => x.id === assignedNurses[i]);
        if(nurseData && nurseData.length > 0) {
          returnedList.push(nurseData[0]);
        }
      }
    }

    return returnedList;
  }

  const assignNursesList = useMemo(() => {
    return getAssignedNursesList()
  }, [nurseList?.length, assignedNurses?.length])

  const iconSickList = [
    {
      key: 'dementia',
      label:'Dementia',
      img: dementiaIcon
    },
    {
      key: 'maternityWithCovid',
      label:'Maternity With Covid',
      icon:'fas fa-viruses'
    },
    {
      key: 'copd',
      label:'COPD',
      icon:'fas fa-lungs-virus'
    },
    {
      key: 'heartCondition',
      label:'Heart Condition',
      icon:'fas fa-heartbeat'
    },
    {
      key: 'diabetics',
      label:'Diabetics',
      icon:'fas fa-burn'
    },
    {
      key: 'learningDisability',
      label:'Learning Disability',
      img: learningDisabilityIcon
    },
    {
      key: 'fall',
      label:'Falls',
      img : fallsIcon
    },
    {
      key: 'lowMood',
      label:'Low Mood',
      img : lowMoodIcon
    }
  ]
  

  const leftInfoData = {
    patient_id: data && data[0] && data[0].patient_id ? data[0].patient_id : "" ,
    patientName:`${data && data[0] && data[0].first_name ? data[0].first_name : "-"} ${data  && data[0]  && data[0].last_name ? data[0].last_name : "-"}`,
    dateOfBirth: data && data[0] && data[0].dob ? data[0].dob : "",
    age: data && data[0] && data[0].dob ? moment().year() - moment(data[0].dob, 'DD-MM-yyyy').year() : "0",
    gender: data && data[0] && data[0].gender ? data[0].gender : "",
    mobile: data && data[0] && data[0].mobile_number ? data[0].mobile_number : "",
    addressLine1: data && data[0] && data[0].address_line_1 ? data[0].address_line_1 : "",
    addressLine2: data && data[0] && data[0].address_line_2 ? data[0].address_line_2 : "",
    addressLine3: data && data[0] && data[0].address_line_3 ? data[0].address_line_3 : "",
    addressLine4: data && data[0] && data[0].address_line_4 ? data[0].address_line_4 : "",
  }

  const rightInfoData ={
    postCode: data && data[0] && data[0].post_code ? data[0].post_code : "",
    occupation: data && data[0] && data[0].occupation ? data[0].occupation : "",
    materialStatus: data && data[0] && data[0].material_status ? data[0].material_status : "",
    religion:data && data[0] && data[0].religion ? data[0].religion : "",
    diagnostic: data && data[0] && data[0].diagnostic ? data[0].diagnostic : "",
    accommodationStatus: data && data[0] && data[0].accom_status ? data[0].accom_status : "",
    NOKName: data && data[0] && data[0].nok_name ? data[0].nok_name : "",
    NOKRelationship: data && data[0] && data[0].nok_relationship ? data[0].nok_relationship : "",
    NOKAddressLine: data && data[0] && data[0].nok_address_line_1 ? data[0].nok_address_line_1 : "",
    NOKPostCode: data && data[0] && data[0].nok_post_code ? data[0].nok_post_code : "",
    NOKContactNumber: data && data[0] && data[0].nok_mobile_number ? data[0].nok_mobile_number : "",
    NOKComment: data && data[0] && data[0].nok_comments ? data[0].nok_comments : "",
  };

  const mapIconList = () => {
    if(data && data[0] && data[0].checkbox_sick) {
      const checkBoxesSick = JSON.parse(data[0].checkbox_sick);
      
      if(Array.isArray(checkBoxesSick) && checkBoxesSick.length > 0) {
        let returnedIconList = [];
        for(let i = 0; i < checkBoxesSick.length; i++) {
          if(checkBoxesSick[i]) {
            for(let j = 0; j < iconSickList.length; j++) {
              if(iconSickList[j].key === checkBoxesSick[i]) {
                returnedIconList.push(iconSickList[j]);

                break;
              }
            }
          }
        }

        return returnedIconList;
      }
    }

    return [];
  }

  const iconList = mapIconList();

  return (
    <PageContentWrapper>
      <h1>Infomation</h1>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Information">
          <div
            className="grid card-container indigo-container"
            style={{ maxWidth: "100%" }}
          >
            <div className="p-2 lg:col-4 md:col-3 sm:col-6 ">
              <div className=" text-center p-4 border-round summary-box clearfix">
                
                <h4 className="block text-600 font-medium mb-3 font-bold">
                  {leftInfoData.patientName}
                </h4>
              <div className="col-12" >
              <div className="col-12">
                <hr/>
                <div className="flex justify-content-center">
                  {iconList && iconList.length > 0 
                  ?  iconList.map((item) => (
                      item.key && item.img
                      ? 
                        <div key={item.key} className="p-2" style={{height: "30px"}}>
                          <Tooltip target={`.avatar-${item.key}`} content={`${item.label}`} position="top" />
                          <Avatar  className={`avatar-${item.key}`} size="small"  shape="circle" image={item.img}/>
                        </div>
                      :
                        <Button 
                          key={item.key}
                          className="p-button-rounded p-button-text px-3"  
                          icon={item.icon} tooltip={item.label} 
                          tooltipOptions={{position: "top"}}
                          style={{fontSize: '22px'}}
                        />
                      ))
                    : <h5 className="mb-0">No health history</h5>
                  }
                </div>
                <hr/>
              </div>
                  {Object.entries(leftInfoData).map(([key, value], idx) => (
                    <div key={`${key}-${idx}`} className="m-2 flex">
                      <div 
                        style={{color : "#42506b"}}
                        className="col-6 text-left p-2">
                        <h6>{convertKeyToLabel(key)}:</h6>
                      </div>
                      <div
                      style={{color : "#556376"}}
                      className="col-6 text-left  p-2">
                        <h5>{value ? value : "-"}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="p-2 lg:col-8 md:col-9 sm:col-6 ">
              <div className=" text-center p-4 border-round summary-box clearfix">
                {Object.entries(rightInfoData).map(([key, value], idx) => (
                  <div key={`${key}-${idx}`} className="m-2 flex">
                    <div 
                    style={{color : "#42506b"}}
                     className="col-6 text-left  p-2">
                      <h6>{convertKeyToLabel(key)}:</h6>
                    </div>
                    <div 
                    style={{color : "#556376"}}
                     className="col-6 text-left  p-2">
                      <h5>{value ? value : "-"}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </TabPanel>
        {getUserType() !== 2 && 
        <TabPanel header="Carers">
          <div className="col-12">
            <PatientAssignedNurses data={assignNursesList} />
          </div>
        </TabPanel>
        }
        
        <TabPanel header="Notes">
          <div className="col-12">
            <PatientNotes
              patientFirstName={data?.firstname}
              patientLastName={data?.lastname}
            />
          </div>
        </TabPanel>
      </TabView>

      <div className="col-12">
        {activeIndex === 0 &&
          <Button label="Edit Profile" className="px-5" onClick={() => patientForm.current.showForm(data)}/>
        }
      </div>

      <PatientForm 
        ref={patientForm}
      />
    </PageContentWrapper>
  );
};

export default Infomation;
