import { Button, Sidebar } from 'primereact';
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { getObsColors, setIncrement, setObsColors } from '../../api/CurrentServiceWorker';
import { TwitterPicker } from 'react-color'

const ObservationConfig = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        showForm: () => showForm()
    }))

    const [colorData, setColorData] = useState(getObsColors())
    const [showPicker, setShowPicker] = useState(null);

    const [visible, setVisible] = useState(false);

    const showForm = () => {
        setVisible(true);
    }

    const hideForm = () => {
        setVisible(false)
    }

    return(
        <Sidebar visible={visible} className="p-sidebar-md" position="right" onHide={() => setVisible(false)}>
            <div className="col-12">
                <div className='mb-3'>
                    <h1 className="font-bold mb-1">Settings</h1>
                    <hr style={{width: '10%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className='flex justify-content-between'>
                    <div>
                        <h5>Normal color: {colorData.lv1}</h5>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() =>  showPicker === "lv1" ? setShowPicker(null) : setShowPicker("lv1")}>
                        {showPicker === "lv1" ? <i className='pi pi-times'></i> : <i className='pi pi-pencil'></i>}
                    </div>
                </div>
                <div style={{backgroundColor: colorData.lv1, border: '1px solid black', height: 30, width: '100%'}}></div>
                {showPicker === "lv1" &&
                    <TwitterPicker onChangeComplete={({hex}) => setColorData({...colorData, lv1: hex})} />
                }
            </div>
            <div className="col-12 mb-3">
                <div className='flex justify-content-between'>
                    <div>
                        <h5>Mild color: {colorData.lv2}</h5>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() =>  showPicker === "lv2" ? setShowPicker(null) : setShowPicker("lv2")}>
                        {showPicker === "lv2" ? <i className='pi pi-times'></i> : <i className='pi pi-pencil'></i>}
                    </div>
                </div>
                <div style={{backgroundColor: colorData.lv2, border: '1px solid black', height: 30, width: '100%'}}></div>
                {showPicker === "lv2" &&
                    <TwitterPicker onChangeComplete={({hex}) => setColorData({...colorData, lv2: hex})} />
                }
            </div>
            <div className="col-12 mb-3">
                <div className='flex justify-content-between'>
                    <div>
                        <h5>Moderate color: {colorData.lv3}</h5>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() =>  showPicker === "lv3" ? setShowPicker(null) : setShowPicker("lv3")}>
                        {showPicker === "lv3" ? <i className='pi pi-times'></i> : <i className='pi pi-pencil'></i>}
                    </div>
                </div>
                <div style={{backgroundColor: colorData.lv3, border: '1px solid black', height: 30, width: '100%'}}></div>
                {showPicker === "lv3" &&
                    <TwitterPicker onChangeComplete={({hex}) => setColorData({...colorData, lv3: hex})} />
                }
            </div>
            <div className="col-12 mb-3">
                <div className='flex justify-content-between'>
                    <div>
                        <h5>Severe color: {colorData.lv4}</h5>
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={() =>  showPicker === "lv4" ? setShowPicker(null) : setShowPicker("lv4")}>
                        {showPicker === "lv4" ? <i className='pi pi-times'></i> : <i className='pi pi-pencil'></i>}
                    </div>
                </div>
                <div style={{backgroundColor: colorData.lv4, border: '1px solid black', height: 30, width: '100%'}}></div>
                {showPicker === "lv4" &&
                    <TwitterPicker onChangeComplete={({hex}) => setColorData({...colorData, lv4: hex})} />
                }
            </div>

            <div className='col-12'>
                <Button className="p-button-sm" icon="pi pi-check" label="UPDATE" onClick={() => {setObsColors(colorData); props.reload(); hideForm()}}/>
            </div>
        </Sidebar>
    )
})

export default ObservationConfig;