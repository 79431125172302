import axios from 'axios';
import { renewToken } from '../api/ServiceWorker';
import { showNotification } from '../components/notification/NotificationService';
import {API_BASE_URL} from '../Constants';
import {clearStorage, getAccessToken, getRefeshToken, setAccessToken} from '../utils/AuthUtils'
import { openPage } from '../utils/NavigatorUtils';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 5000
});

// axiosInstance.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     return config;
// }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });

// // Add a response interceptor
// axiosInstance.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
// },async function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     let res = error.response;
//     let originalRequest = error.config;
//     if (res) {
//         switch(res.status) {
//             case 500:
//                 showNotification("error", "Error", "Problem occurs. Please try again !")
//                 break;
//             case 400:
//                 showNotification("error", "Error", "Your request is invalid !");
//                 break;
//             case 404:
//                 openPage("/404");
//                 break;
//             case 401:
//                 if(res.config && res.config.url !== `${API_BASE_URL}/api/authenticate/getToken`) {
//                     if(!originalRequest._retry) {
//                         originalRequest._retry = true;

//                         delete axiosInstance.defaults.headers.common['Authorization']

//                         await renewToken({refreshToken: getRefeshToken()})
//                         .then(async data => {
//                             if(data && data.token) {
//                                 setAccessToken(data.token);

//                                 axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}`

//                                 originalRequest.headers.Authorization = `Bearer ${getAccessToken()}`

//                                 error = await Promise.resolve(await axiosInstance.request(originalRequest));
//                             } else {
//                                 clearStorage();
            
//                                 openPage("/401");
//                             }
//                         })
//                     }                    
//                 }
//                 break;

//             case 403:
//                 clearStorage();
//                 break;
//             default:
//         }
//     }

//     if(originalRequest._retry) {
//         return Promise.resolve(error);
//     }
//     return Promise.reject(error);
// });

export default axiosInstance;