import { useQuery } from '@tanstack/react-query';
import { Button, Column, DataTable, Dropdown, SpeedDial, Tooltip, TreeTable } from 'primereact';
import React, { useMemo, useRef, useState } from 'react';
import { getAllDoctors, getDoctorId, getListPatients, setDoctorFirebaseId, setDoctorId, setDoctorName } from '../../api/CurrentServiceWorker';
import PageContentWrapper from '../../utils/LayoutUtils';
import { openPage } from '../../utils/NavigatorUtils';
import DoctorForm from './DoctorForm';
import { convertDataList, converteDataListToTreeTable } from './Ultils';

const DoctorManagement = ({type = 1, hideText}) => {
    const [pageable, setPageable] = useState({
        rows: 10,
        pageNumber: 0
    })

    const doctorForm = useRef(null)
    const {data: nurseDataList} = useQuery(
        ['doctorList', type],
        () => getAllDoctors(type),
        {
            enabled: (!!getDoctorId() && type === 1) || type === 0 
        }
    )
    
    const {data: patientDataList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients(),
        {
            enabled: !!getDoctorId()
        }
    )

    const reverseNurseDataList = nurseDataList && Array.isArray(nurseDataList) ? nurseDataList.reverse() : []
    const reversePatientDataList = patientDataList && Array.isArray(patientDataList) ? patientDataList.reverse() : []
    
    const dataList = useMemo(() => {
        return convertDataList(reverseNurseDataList, reversePatientDataList , "patientList", "doctorComp")
    }, [reverseNurseDataList?.length, reversePatientDataList?.length])

    const dataTreeTable = useMemo(() => {
        // [], doctor_id, patient_id, patientList, "nurse", "patient"
        return converteDataListToTreeTable(dataList, "doctor_id","patient_id", "patientList", "nurse","patient")
    }, [reverseNurseDataList?.length, reversePatientDataList?.length])

    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10},
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];
    
        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />;
      }
    
      const renderHeader = () => {
        return(
          <div className='col-12 flex justify-content-between align-items-center p-0'>
            {type === 0 
            ?
                <h4><strong>Data List</strong></h4>
            :
                <Button label='Add Carer' onClick={() => doctorForm.current.showForm()} icon='pi pi-plus' iconPos='left'/>
            }
            
            {paginatorTemplate()}
          </div>
        )
    }

    const handleAccessDoctor = (rowData) => {
        setDoctorId(rowData.id)
        setDoctorName(`${rowData.firstname} ${rowData.lastname}`)
        
        if(rowData.uid) {
            setDoctorFirebaseId(rowData.uid)
        }

        openPage("/")
    }

    return(
        <PageContentWrapper px={hideText ? "p-0" : ""}>
            {type === 0 &&
                <div className="flex justify-content-between mb-5">
                    <div>
                        <h3 className='mb-1'>{type === 0 ? 'Manager' : 'Carer'} Management</h3>
                        <hr style={{width: '35%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
                    </div>
                    <div>
                        <Button 
                            label={`Add New ${type === 0 ? 'Manager' : 'Carer'}`} 
                            tooltipOptions={{position: 'left'}}
                            onClick={() => doctorForm.current.showForm()}
                        />
                    </div>
                </div>
            }
            
            
            {type === 0
            ?
                // FOR ADMIN 
                <DataTable value={nurseDataList ? nurseDataList : []} responsiveLayout="stack" breakpoint="960px"
                    header={renderHeader()} sortOrder={-1} sortField={"id"}
                    paginator onPage={(e) => setPageable({...pageable, pageNumber: e.page})}
                    totalRecords={nurseDataList ? nurseDataList.length : 0} first={pageable.pageNumber * pageable.rows} rows={pageable.rows}
                >
                    <Column field="id" header="ID" />
                    <Column header="Full Name" body={(rowData) => !rowData.firstname && !rowData.lastname ? "-" : `${rowData.firstname ? rowData.firstname : ""} ${rowData.lastname ? rowData.lastname : ""}`}/>
                    <Column header="Role" body={rowData => rowData.type === 1 ? "Manager" : rowData.type === 2 ? "Carer" : "-"} />
                    <Column header="Hospital" body={rowData => !rowData.hospital ? "-" : rowData.hospital} />
                    <Column field="username" header="Username" />
                    <Column header="Email" body={(rowData) => !rowData.email ? "-" : <div style={{wordWrap: "break-word"}}>{rowData.email}</div>}/>
                    <Column header="Action" body={(rowData) => {
                        return(
                            <div>
                                <Button className={`mr-2 ${type === 0 ? 'p-button-outlined' : ''}`} tooltipOptions={{position: "top"}} tooltip='View Information' type="button" icon="pi pi-search" onClick={() => doctorForm.current.showForm(rowData)}></Button>
                                {type === 0 &&
                                    <Button tooltipOptions={{position: "top"}} tooltip='Access Profile' type="button" icon="pi pi-arrow-right" onClick={() => handleAccessDoctor(rowData)}></Button>
                                }
                            </div>
                        )
                    }} />
                </DataTable>
            :   
                type === 1 
                ?
                // FOR DOCTOR
                <div className="treetable-responsive px-0">
                    <TreeTable header={renderHeader()} value={dataTreeTable ? dataTreeTable : []} rowHover responsiveLayout="stack" breakpoint="960px" 
                    paginator first={pageable.pageNumber * pageable.rows} rows={pageable.rows}
                    onPage={e => setPageable({...pageable, pageNumber: e.page})} totalRecords={dataList ? dataList.length : 0}
                    >
                        <Column field="id" header="ID" expander></Column>
                        <Column field="fullname" header="Full Name"></Column>
                        <Column header="Role" body={(rowData) => rowData.data && rowData.data.type && rowData.data.type === 2 ? "Carer" : "Client"}></Column>
                        <Column field="username" header="Username"></Column>
                        <Column field="email" header="Email" body={(rowData) => <div style={{wordWrap: "break-word"}}>{rowData.data.email}</div>}></Column>
                        <Column header="Action" body={(rowData) => {
                            return(
                                rowData.data && rowData.data.type === 2 &&
                                <div>
                                    <Button className={`mr-2`} tooltipOptions={{position: "top"}} tooltip='View Information' type="button" icon="pi pi-search" onClick={() => doctorForm.current.showForm(rowData?.data)}></Button>
                                </div>
                            )
                        }} />
                    </TreeTable>
                </div>
                : {}
            }

            <DoctorForm
                ref={doctorForm}
                type={type}
            />
        </PageContentWrapper>
    )
}

export default DoctorManagement;