import axios from 'axios';
import { IMG_END_POINT } from '../Constants';
import { openPage } from '../utils/NavigatorUtils';

//#region ADMIN REGION
export function setAdminId(adminId) {
    localStorage.setItem("adminId", adminId);
}

export function getAdminId() {
    return localStorage.getItem("adminId") ? JSON.parse(localStorage.getItem('adminId')) : null
}

export function removeAdminId() {
    localStorage.removeItem("adminId");
}

export const isAdmin = () => {
    if(getAdminId() !== null) {
        return true;
    }

    return false
}
//#endregion

export function setLayoutLoaded(isLoaded = false) {
    localStorage.setItem('layoutLoaded', JSON.stringify(isLoaded))
} 

export function getLayoutLoaded() {
    return localStorage.getItem('layoutLoaded') ? JSON.parse(localStorage.getItem('layoutLoaded')) : false
}

export function getIncrement() {
    return localStorage.getItem("increment") ? JSON.parse(localStorage.getItem("increment")) : localStorage.getItem("increment");
}

export function setIncrement() {
    localStorage.setItem("increment", localStorage.getItem("increment") ? JSON.parse(localStorage.getItem("increment")) + 1 : JSON.stringify(0))
}

export function deleteIncrement() {
    localStorage.removeItem('increment')
}

export function setPatientId(patientId) {
    localStorage.setItem('patientId', JSON.stringify(patientId));
}

export function getPatientId() {
    return localStorage.getItem('patientId') ? JSON.parse(localStorage.getItem('patientId')) : null;
}

export function removePatientId(){
    localStorage.removeItem('patientId')
}

export function setPatientFirebaseId(uid) {
    localStorage.setItem('patientFirebaseId', JSON.stringify(uid));
}

export function getPatientFirebaseId() {
    return localStorage.getItem('patientFirebaseId') ? JSON.parse(localStorage.getItem('patientFirebaseId')) : null;
}

export function removePatientFirebaseId() {
    localStorage.removeItem('patientFirebaseId');
}

export function setPatientName(name) {
    localStorage.setItem('patientName', name);
} 

export function getPatientName() {
    return localStorage.getItem('patientName');
}

export function removePatientName() {
    localStorage.removeItem('patientName');
}

export function setBatteryLevel(level) {
    localStorage.setItem("batteryLevel", level)
}

export function getBatteryLevel() {
    return localStorage.getItem('batteryLevel')
}

export function removeBatteryLevel() {
    localStorage.removeItem("batteryLevel")
}

export function setDoctorId(doctorId) {
    localStorage.setItem('doctorId', JSON.stringify(doctorId))
}

export function getDoctorId() {
    return getUserType() === 2 ? getManagerId() : localStorage.getItem('doctorId') ? JSON.parse(localStorage.getItem('doctorId')) : null;
}

export function removeDoctorId() {
    localStorage.removeItem('doctorId')
}

export function setDoctorName(name) {
    localStorage.setItem('doctorName', name)
}

export function getDoctorName() {
    return localStorage.getItem('doctorName');
}

export function removeDoctorName() {
    localStorage.removeItem('doctorName')
}

export function setUserType(type) {
    localStorage.setItem("userType", type)
}

export function getUserType() {
    return localStorage.getItem('userType') ? JSON.parse(localStorage.getItem("userType")) : null;
}

export function setManagerId(doctorId) {
    localStorage.setItem("managerId", doctorId);
}

export function getManagerId() {
    return localStorage.getItem('managerId') ? JSON.parse(localStorage.getItem('managerId')) : null
}

export function removeManagerId() {
    localStorage.removeItem('managerId')
}

export function removeUserType() {
    localStorage.removeItem('userType')
}

export function setDoctorFirebaseId(doctorFirebaseId) {
    if(doctorFirebaseId) {
        localStorage.setItem('doctorFirebaseId', JSON.stringify(doctorFirebaseId))
    }
}

export function getDoctorFirebaseId() {
    return localStorage.getItem('doctorFirebaseId') ? JSON.parse(localStorage.getItem('doctorFirebaseId')) : null;
}

export function removeDoctorFirebaseId() {
    localStorage.removeItem('doctorFirebaseId')
}

export function getObsColors() {
    return localStorage.getItem('obsColors') ? JSON.parse(localStorage.getItem('obsColors')) : {lv1: 'white', lv2: '#fef3b3', lv3: '#fcce92', lv4: '#f39c81'}
}

export function setObsColors(obsColors) {
    if(obsColors && Object.keys(obsColors).length > 0) {
        localStorage.setItem("obsColors", JSON.stringify(obsColors));
    } else {
        localStorage.setItem("obsColors", JSON.stringify({lv1: 'white', lv2: '#fef3b3', lv3: '#fcce92', lv4: '#f39c81'}))
    }
}

export function setLineChartDrawn(value) {
    localStorage.setItem('lineChartDrawn', JSON.stringify(value));
}

export function getLineChartDrawn() {
    return localStorage.getItem('lineChartDrawn') ? JSON.parse(localStorage.getItem('lineChartDrawn')) : false
}

const currentBaseURL = "https://thinkvitals-be.click:8080/api/"
// const currentBaseURL = "http://localhost:9000/api/"

const currentAxios = axios.create({
    baseURL: currentBaseURL,
    timeout: 5000
})

export function authenticateUser(data) {
    return currentAxios.post(`doctor/create-login`, data)
    .then(res => res.data).catch(error => console.log(error))
}
// Firebase UID For Doctor
export function updateFirebaseUserId(id, uid) {
    return currentAxios.post(`doctor/update-uid`, null, {
        params: {id, uid}
    }).then(res => res.data).catch(error => console.log(error))
}

export function getListPatients() {
    return currentAxios.get(`patients/get-list-patient`, {
        params: {doctor_id: getUserType() === 2 ? getManagerId() : getDoctorId()}
    })
    .then(res => res.data).catch(error => console.log(error))
}

export function getPatientInfoById() {
    if(!getPatientId()) {
        openPage("/404")
        return {};
    } 

    return currentAxios.get(`patients/get-patient-by-id/${getPatientId()}`)
    .then(res => res.data).catch(error => console.log(error))
}

export function getListNotes() {
    return currentAxios.get(`note/get-list-note-by-patient-id`, {
        params: {patient_id: getPatientId()}
    }).then(res => res.data).catch(error => console.log(error))
}

export function getPatientObs(pageNumber) {
    return currentAxios.get(`patients/get-patients-obs/${getPatientId()}`, {
        params: {pageNumber}
    })
    .then(res => res.data).catch(error => console.log(error))
}
export function getAllObs() {
    return currentAxios.get(`patients/get-all-obs/${getPatientId()}`)
    .then(res => res.data).catch(error => console.log(error))
}
export function getAllPatientObs() {
    return currentAxios.get(`patients/get-all-patient-obs`)
    .then(res => res.data).catch(error => console.log(error))
}
export function getExerciseData() {
    return currentAxios.get(`exercise/get-latest-bmi-by-patient-id`, {
        params: {patient_id: getPatientId()}
    }).then(res => res.data).catch(error => console.log(error))
}
export function getExerciseTableData() {
    return currentAxios.get(`exercise/get-list-steps-sleep-by-patient-id`,{
        params: {patient_id: getPatientId()}
    }).then(res => res.data).catch(error => console.log(error))
}
export function getSummaryData() {
    return currentAxios.get(`patients/get-latest-patient-obs/${getPatientId()}`)
    .then(res => res.data).catch(error => console.log(error))
}
export function getMedicalData() {
    return currentAxios.get(`medical/get-medical-by-id/${getPatientId()}`)
    .then(res => res.data).catch(error => console.log(error))
}
export function createPatientNote(data) {
    return currentAxios.post(`note/add-or-update-note`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function createExerciseHeightWeight(data) {
    return currentAxios.post(`exercise/insert-height-weight-by-patient-id`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function createMedical(data) {
    return currentAxios.post(`medical/create-medical`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function createPatientObs(data) {
    return currentAxios.post(`patients/create-patients-obs`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function createNewPatient(data) {
    return currentAxios.post(`patients/register-patient`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function updatePatientInfo(data) {
    return currentAxios.post(`patients/update-patient`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function createParameter(data) {
    return currentAxios.post(`parameter/add-or-update-parameter`, data)
    .then(res => res.data).catch(error => console.log(error))
}
export function getParameterData() {
    return currentAxios.get(`parameter/get-list-parameter-by-patient-id`, {
        params: {patient_id: getPatientId()}
    }).then(res => res.data).catch(error => console.log(error))
}
export function updatePatientFirebaseUserId(id, uid) {
    return currentAxios.post(`patients/update-uid`, null, {
        params: {id, uid}
    }).then(res => res.data).catch(error => console.log(error))
}
export function getAllExercises() {
    return currentAxios.get(`exercise/get-exercise-notification`)
    .then(res => res.data).catch(error => console.log(error))
}
export function uploadImage(formData) {
    return axios.post(`${IMG_END_POINT}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    }).then(res => res.data).catch(error => console.log(error))
}

//#region DOCTOR MANAGEMENT
export function getAllDoctors(type = 1) {
    return currentAxios.post(`doctor/get-doctor-list`, null, {
        params: {doctor_id: getUserType() === 2 ? getManagerId() : getDoctorId(), type}
    })
    .then(res => res.data).catch(error => console.log(error))
}

export function createDoctor(data) {
    return currentAxios.post(`doctor/create-doctor`, data)
    .then(res => res.data).catch(error => console.log(error))
}

export function updateDoctor(data) {
    return currentAxios.post(`doctor/update-info`, data)
    .then(res => res.data).catch(error => console.log(error))
}
//#endregion

//#region DISCARD NOTIFICATIONS
export function discardObsMessage(id) {
    return currentAxios.post(`patients/discard-notification`, null, {
        params: {id}
    }).then(res => res.data).catch(error => console.log(error))
}

export function discardExerciseMessage(id) {
    return currentAxios.post(`exercise/discard-notification`, null, {
        params: {id}
    }).then(res => res.data).catch(error => console.log(error))
}
//#endregion 

//#region GPDATA
export function getAllGPs() {
    return currentAxios.get(`gp/get-list-all-gp`)
    .then(res => res.data).catch(error => console.log(error))
}

export function createGpData(data) {
    return currentAxios.post(`gp/createGp`, data)
    .then(res => res.data).catch(error => console.log(error))
}
//#endregion