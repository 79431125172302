import { getUserType } from "../../api/CurrentServiceWorker";

export function convertDataList(masterList, nodeList, toAddKey, usedFor) {

    

    // Kiem tra DS BN
    if(masterList && Array.isArray(masterList) && nodeList && Array.isArray(nodeList)) {
        // Loop qua tung BN
        for(let i = 0; i < masterList.length; i++) {
            let dataListPlaceholder = [];
            // Kiem tra BN duoc assign y ta chua ?
            if(masterList[i]
                // cho patient comp : BN do dang co ds y ta
                && ((usedFor === "patientComp")
                    || (usedFor === "doctorComp" && masterList[i].id)
                )) {
                // Co ds Id y ta
                if(usedFor === "patientComp") {
                    masterList[i].fullname = masterList[i].first_name + " " + masterList[i].last_name 
                    masterList[i].id = masterList[i].patient_id
                    masterList[i].type = "patient"
                    if(masterList[i].chooseNurse) {
                        let chooseNurseIds = JSON.parse(masterList[i].chooseNurse)
                        if(chooseNurseIds && Array.isArray(chooseNurseIds) && chooseNurseIds.length > 0) {
                            // Lay thong tin y ta tu Id
                            for(let j = 0; j < chooseNurseIds.length; j++) {
                                // Id y ta
                                if(chooseNurseIds[j]){
                                    // Filter y ta bang Id
                                    let nurseDataItem = nodeList.filter(x => x.id === chooseNurseIds[j]);
                                    // tra ve DS y ta vd [{}] || []
                                    nodeList[j].fullname = nodeList[j].firstname + " " + nodeList[j].lastname
                                    let toPushItem = nurseDataItem && nurseDataItem.length > 0 ? nurseDataItem[0] : {};
                                    if(Object.keys(toPushItem).length > 0) {
                                        dataListPlaceholder.push(toPushItem)
                                    }
                                }
                            }
                        }
                    }
                } else if(usedFor === "doctorComp") {
                    // masterList : DS Y Ta || masterList[i] : thong tin y ta || masterList[i].id : ID y ta
                    // nodeList : DS BN || nodeList[j] : Thong tin BN || nodeList[j].chooseNurse : String array cua y ta duoc assign
                    // chooseNurseIds : ["120", "121"] => chooseNurseIds.includes(120)
                    // dataListPlaceholder : DS tam giu gia tri thong tin cua BN => [{...patientInfo}, {...patientInfo}]

                    // Loop qua DS BN de kiem tra id y ta co nam trong ds y ta duoc assign hay khong
                    
                    masterList[i].fullname = masterList[i].firstname + " " + masterList[i].lastname
                    
                    if(nodeList && Array.isArray(nodeList) && nodeList.length > 0) {
                        for(let j = 0; j < nodeList.length; j++) {
                            if(nodeList[j]) {
                                // change key here
                                nodeList[j].firstname = nodeList[j].first_name;
                                nodeList[j].lastname = nodeList[j].last_name;
                                nodeList[j].fullname = nodeList[j].firstname + " " + nodeList[j].lastname
                                nodeList[j].id ="#"+ nodeList[j].patient_id;
                                if(nodeList[j].chooseNurse) {
                                    let chooseNurseIds = JSON.parse(nodeList[j].chooseNurse)

                                    if(chooseNurseIds.includes(masterList[i].id)) {
                                        dataListPlaceholder.push(nodeList[j])
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

            if(toAddKey) {
                masterList[i][toAddKey] = dataListPlaceholder
            }
        }
    }

    return masterList
}

// [], patient_id, nurse_id, nurseList, "patient", "nurse"
// [], doctor_id, patient_id, patientList, "nurse", "patient"
export function converteDataListToTreeTable(dataList, parentKeyId, childKeyId, keyList, parentLabel, childLabel) {
    if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
        return []
    }

    let placeholder = []
    for(let i = 0; i < dataList.length; i++) {
        if(dataList[i]) {
            let item = {
                key: `${parentLabel}-${dataList[i][parentKeyId]}}`,
                data: {...dataList[i]},
            }

            if(dataList[i][keyList] && Array.isArray(dataList[i][keyList])) {
                let childrenPlaceholder = []
                for(let j = 0; j < dataList[i][keyList].length; j++) {
                    let childItem = {
                        key: `${childLabel}-${dataList[i][keyList][j][childKeyId]}`,
                        data: {...dataList[i][keyList][j]}
                    }
                    childrenPlaceholder.push(childItem)
                }

                item.children = childrenPlaceholder
            }

            placeholder.push(item)
        }
    }

    return placeholder
}