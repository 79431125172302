import EventEmitter from 'eventemitter3';

export const menuEvent = new EventEmitter();

export function onMenuChanged() {
    menuEvent.emit('menuChanged')
}

export function removeOnMenuChanged() {
    menuEvent.removeListener('menuChanged');
}