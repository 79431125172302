import React from 'react'

const ExercisesStatus = ({data}) => {
  
    const bmiStatus = ( value ) => {
      if ((value < 18.5)) {
          return {
              label: "Thin",
              color: "rgb(244, 174, 149)"
          }
        }
        if ((18.5 <= value <= 22.9)) {
          return {
              label: "Average",
              color: "#44cc61"
          }
      }   
        if ((23.1 <= value <= 24.9)) {
          return {
              label: "Over Weight",
              color: "#fed070"
          }
          
        }
        if ((25 <= value <= 29.9)) {
          return {
              label: "Obesity I",
              color: "#fed070"
          }
          
        }
        if ((30 <= value <= 30.9)) {
          return {
              label: "Obesity II",
              color: "rgb(244, 174, 149)"
          }
        }
        if (30.9 <= value <= 40 ) {
          return {
              label: "Obesity III",
              color: "#e74142"
          }
        }
    }

    const getBmiValue = () => {
      if( !data || !data.weight || !data.height ) {
        return 0
      }

      return (data.weight) / ((data.height/100) * 2 )

    }

    const bmiValue = getBmiValue()


    const background = bmiValue ? bmiStatus(bmiValue).color : ""
    
    
    const  bmiData = [
      {
        key: "height",
        label: "Cm",
        value: data && data.height
      },
      {
        key: "weight",
        label: "Kg",
        value: data && data.weight
      },
      {
        key: "bmi",
        label: "BMI",
        value: (bmiValue).toFixed(2)
      },
      {
        key:"status",
        label:"",
        value: data && data.bmi ? bmiStatus(data.bmi).label : '',
      }
    ]

  return (
    <div className="col-12 p-0">
      <div className='grid font-bold text-center p-0 border-round summary-box clearfix' style={{backgroundColor: background, color: background ? "white" : "black"}} >

        {bmiData.map((item, idx)=>
            <div key={idx} className="col-3 flex " >
              <div className=' col-6 text-center'>
                {item.value 
                ?
                  <>
                    {item.value}
                    <span className='m-1'>{item.label}</span>
                  </>
                : "No Data"
                }
                
               </div>
              <div className=' col-6 text-center'> </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default ExercisesStatus
