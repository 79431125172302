  import React, { useEffect, useState } from 'react'
  import PageContentWrapper from '../../utils/LayoutUtils'
import { Button } from 'primereact/button';
import { openPage } from '../../utils/NavigatorUtils';
import { getWidget } from '../../api/ServiceWorker';

  const Registrations = () => {
    const [widgetData, setWidgetData] = useState({
      url: "",
      expires_in: null
    })

    useEffect(() => {
      loadWidget()
    }, [])

    const loadWidget = async() => {
      let toPostData = {
        referenceId: "123",
        authSuccessRedirectUrl: "https://0960-42-115-94-239.ap.ngrok.io/registration/success",
        authFailureRedirectUrl: "https://0960-42-115-94-239.ap.ngrok.io/registration/failure"
      }
      const {url, expires_in} = await getWidget(toPostData);

      setWidgetData({
        url, expires_in
      })
    }

    

    return (
    <PageContentWrapper>
      {console.log(widgetData)}
        {/* <div >
          <img 
              src={require("../../assets/images/Success.svg")}
              style={{height: 150, width: '100%'}}
          />
          <h5 className='font-bold' style={{fontSize:"3rem", textAlign:"center "}}>Connect Success</h5>
        </div> */}
        {/* <Button onClick={() => openPage("https://widget.tryterra.co/session/7779f80c-4a2c-48d4-86ed-2d2b2d179749")}>
              Register
            </Button> */}
        <div className='container'>
          {
            widgetData?.url &&
            <Button onClick={() => openPage(widgetData.url)}>
              Register
            </Button>
          }
       
          
          {/* <iframe frameBorder="0" className="responsive-iframe" src="https://widget.tryterra.co/session/25d13bce-09f7-4734-b95a-658ca18a4944"> </iframe> */}
        </div>
    </PageContentWrapper>
    )
  }

  export default Registrations
