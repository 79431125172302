import { getObsColors } from "../../api/CurrentServiceWorker";

export function setShowAllColors(isShown) {
    localStorage.setItem("showAllObsColors", JSON.stringify(isShown));
}

export function getShowAllColors() {
    return localStorage.getItem("showAllObsColors") ? JSON.parse(localStorage.getItem("showAllObsColors")) : "";
}

export const holderValue = ""
export const dataCols = 24;
export const displayCols = 24;

export const colors = getObsColors()

export const operators = {
    greaterThan: "greaterThan",
    greaterOrEqualTo: "greaterOrEqualTo",
    lessThan: "lessThan",
    lessOrEqualTo: "lessOrEqualTo",
    equal: "equal",
    between: "between"
}

export const levels = [
    {label: "None", value: 25},
    {label: "Mild", value: 50},
    {label: "Moderate", value: 75},
    {label: "Severe", value: 100},
]

export const keys = {
    // Patient Info Keys
    ratingScale: "ratingScale",
    patientName: "patientName",
    hospitalNumber: "hospitalNumber",
    dob: "dob",
    arrivalDate: "arrivalDate",
  
    // Obs Data Keys
    createdAt: "created_at",
    respiration: "rrValue",
    spO2: "oxiValue",
    bloodPressure: "bpValue",
    heartRate: "hrValue",
    consciousness: "consciousness",
    temperature: "tempValue",
    news: "news",
    painAtRest: "paValue",
    painOnMovement: "poValue",
    bloodGlucoseLevel: "bgValue",
    comment: "Comment",
    rhythm: "Rhythm",
    device: "Device",
    initials: "initials",
    syValue: "syValue",
    diValue: "diValue",
    oxiValue: 'oxiValue',
    tempValue: 'tempValue',
    bgValue: 'bgValue',
    bpLimb: 'bpLimb',
    bpPosition: 'bpPosition',
    paValue: 'paValue',
    poValue: 'poValue'
}

export const headerData = [
    {
      key: keys.ratingScale,
      data: [colors.lv1, colors.lv2, colors.lv3, colors.lv4]
    }, 
    {
      key: keys.patientName,
      label: "Name",
      value: "Tim Taylor"
    },
    {
      key: keys.hospitalNumber,
      label: "Hos. Number",
      value: "10215"
    },
    {
      key: keys.dob,
      label: "Age",
      value: "15/10/2000"
    },
    {
      key: keys.arrivalDate,
      label: "Arrival Date",
      value: "20/01/2022"
    }
]

export const respirationDataRange = [
    {
        value: 25,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 21,
        end: 24,
        operator: operators.between, 
    },
    {
        start: 18,
        end: 20,
        operator: operators.between, 
    },
    {
        start: 15,
        end: 17,
        operator: operators.between, 
    },
    {
        start: 12,
        end: 14,
        operator: operators.between, 
    },
    {
        start: 9,
        end: 11,
        operator: operators.between, 
    },
    {
        value: 8,
        operator: operators.lessOrEqualTo, 
    },
]

export const spO2DataRange = [
    {
        value: 96,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 94,
        end: 95,
        operator: operators.between
    },
    {
        start: 92,
        end: 93,
        operator: operators.between
    },
    {
        value: 91,
        operator: operators.lessOrEqualTo
    },
]

export const bloodPressureDataRange = [
    {
        value: 220,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 201,
        end: 219,
        operator: operators.between
    },
    {
        start: 181,
        end: 200,
        operator: operators.between
    },
    {
        start: 161,
        end: 180,
        operator: operators.between
    }, {
        start: 141,
        end: 160,
        operator: operators.between
    },
    {
        start: 121,
        end: 140,
        operator: operators.between
    }, 
    {
        start: 111,
        end: 120,
        operator: operators.between
    }, 
    {
        start: 101,
        end: 110,
        operator: operators.between
    }, 
    {
        start: 91,
        end: 100,
        operator: operators.between
    }, 
    {
        start: 81,
        end: 90,
        operator: operators.between
    }, 
    {
        start: 71,
        end: 80,
        operator: operators.between
    }, 
    {
        start: 61,
        end: 70,
        operator: operators.between
    }, 
    {
        start: 51,
        end: 60,
        operator: operators.between
    }, 
    {
        value: 50,
        operator: operators.lessOrEqualTo
    },
]

export const pulseDataRange = [
    {
        value: 131,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 121,
        end: 130,
        operator: operators.between
    },
    {
        start: 111,
        end: 120,
        operator: operators.between
    },
    {
        start: 101,
        end: 110,
        operator: operators.between
    }, {
        start: 91,
        end: 100,
        operator: operators.between
    },
    {
        start: 81,
        end: 90,
        operator: operators.between
    }, 
    {
        start: 71,
        end: 80,
        operator: operators.between
    }, 
    {
        start: 61,
        end: 70,
        operator: operators.between
    }, 
    {
        start: 51,
        end: 60,
        operator: operators.between
    }, 
    {
        start: 41,
        end: 50,
        operator: operators.between
    }, 
    {
        start: 31,
        end: 40,
        operator: operators.between
    },
    {
        value: 30,
        operator: operators.lessOrEqualTo
    },
]

export const temperatureDataRange = [
    {
        value: 39.1,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 38.1,
        end: 39,
        operator: operators.between
    },
    {
        start: 37.1,
        end: 38,
        operator: operators.between
    },
    {
        start: 36.1,
        end: 37,
        operator: operators.between
    },
    {
        start: 35.1,
        end: 36,
        operator: operators.between
    },
    {
        value: 35,
        operator: operators.lessOrEqualTo
    },
]
export const systolicDataRange = [
    {
        value: 39.1,
        operator: operators.greaterOrEqualTo
    },
    {
        start: 38.1,
        end: 39,
        operator: operators.between
    },
    {
        start: 37.1,
        end: 38,
        operator: operators.between
    },
    {
        start: 36.1,
        end: 37,
        operator: operators.between
    },
    {
        start: 35.1,
        end: 36,
        operator: operators.between
    },
    {
        value: 35,
        operator: operators.lessOrEqualTo
    },
]

export const pulseColorData = [colors.lv4, colors.lv3, colors.lv3, colors.lv2, colors.lv2, colors.lv1, colors.lv1, colors.lv1, colors.lv1, colors.lv2, colors.lv4, colors.lv4 ]
export const respirationColorData = [colors.lv4, colors.lv3, colors.lv1, colors.lv1, colors.lv1, colors.lv2, colors.lv4]
export const bloodPressureColorData = [colors.lv4, colors.lv1, colors.lv1, colors.lv1, colors.lv1, colors.lv1, colors.lv1, colors.lv2, colors.lv3, colors.lv4, colors.lv4, colors.lv4, colors.lv4, colors.lv4 ]
export const tempColorData = [colors.lv3, colors.lv2, colors.lv1, colors.lv1, colors.lv2, colors.lv4]
export const oxiColorData = [colors.lv1, colors.lv2, colors.lv3, colors.lv4]
export const systolicColorData = []
export const obsColWrapperData = [
    {
      key: keys.createdAt,
      label: "Date",
      colNumber: 1,
      sticky: true
    },
    {
      key: keys.createdAt,
      label: "Time",
      colNumber: 1,
      sticky: true
    },
    {
      key: keys.respiration,
      label: "Respirations",
      colNumber: 2,
      extraColData: ["≥25", "21-24", "18-20", "15-17", "12-14", "9-11", "≤8"],
      colors: respirationColorData,
      dataRange: respirationDataRange
    },
    {
      key: keys.spO2,
      label: "Sp02 Scale 1",
      colNumber: 2,
      extraColData: ["≥96", "94-95", "92-93", "≤91"],
      colors: oxiColorData,
      dataRange: spO2DataRange
    },
    {
      key: keys.bloodPressure,
      label: "Blood Pressure",
      colNumber: 2,
      extraColData: ["≥220", "201-219", "181-200", "161-180", "141-160", "121-140", "111-120", "101-110", "91-100", "81-90", "71-80", "61-70", "51-60", "≤50"],
      colors: bloodPressureColorData,
      dataRange: bloodPressureDataRange
    },
    {
      key: keys.heartRate,
      label: "Heart Rate",
      colNumber: 2,
      extraColData: ["≥131", "121-130", "111-120", "101-110", "91-100", "81-90", "71-80", "61-70", "51-60", "41-50", "31-40", "≤30"],
      colors: pulseColorData,
      dataRange: pulseDataRange
    },
    {
      key: keys.consciousness,
      label: "Consciousness",
      colNumber: 2,
      extraColData: ["Alert", "C/V/P/U"],
      colors: [colors.lv1, colors.lv4]
    },
    {
      key: keys.temperature,
      label: "Temperature",
      colNumber: 2,
      extraColData: ["≥39.1°", "38.1°-39.0°", "37.1°-38.0°", "36.1°-37.0°", "35.1°-36.0°", "≤35.0°"],
      colors: tempColorData,
      dataRange: temperatureDataRange
    },
    {
        key: keys.news,
        label: "NEWS",
        colNumber: 1
    },
    {
      key: keys.painAtRest,
      label: "Pain At Rest",
      colNumber: 1
    },
    {
      key: keys.painOnMovement,
      label: "Pain On Movement",
      colNumber: 1
    },
    {
      key: keys.bloodGlucoseLevel,
      label: "Blood Glucose Level",
      colNumber: 1
    },
    {
      key: keys.comment,
      label: "Comment",
      colNumber: 1
    },
    {
      key: keys.rhythm,
      label: "Rhythm",
      colNumber: 1
    },
    {
      key: keys.device,
      label: "Device",
      colNumber: 1
    },
    {
        key: keys.initials,
        label: "Initials",
        colNumber: 1
    },
]

// OBS FORM DATA
export const oneSlider = [
    { 
      key : keys.oxiValue,
      label : "Oxygen Saturation %",
      min : 50,
      max : 100,
      step :'',
      dataRange: spO2DataRange,
      colors: oxiColorData,
      labelValue :"%"
    },
    { key : keys.tempValue,
      label : "Temperature °C",
      min : 27,
      max : 43,
      step :'',
      dataRange: temperatureDataRange,
      colors: tempColorData,
      labelValue :"°C"
    },
]

export const oneSlider1 = [
    { 
        key : keys.bgValue,
        label : "Blood Glucose Level (mol)",
        min : 0,
        max : 30,
        step :'',
        labelValue :"mol"
    },
]

export const twoSlider = [
    { 
      key: keys.heartRate,
      label : "Heart Rate (BPM)",
      min : 20,
      max : 220,
      step :'',
      dataRange: pulseDataRange,
      colors: pulseColorData,
      labelValue :"BPM"
    },
    { 
      key: keys.respiration,
      label : "Respiration Rate (BPM)",
      min : 0,
      max : 50,
      step :'',
      dataRange: respirationDataRange,
      colors: respirationColorData,
      labelValue :"BPM"
    }
]

export const twoSlider1 = [
    { 
        key: keys.syValue,
        label : "Systolic BP (mm Hg)",
        min : 40,
        max : 250,
        step :'',
        dataRange: bloodPressureDataRange,
        colors: bloodPressureColorData,
        labelValue :"mm Hg"
    },
    { 
        key: keys.diValue,
        label : "Diastolic BP (mm Hg)",
        min : 30,
        max : 140,
        step :'',
        // dataRange: bloodPressureDataRange,
        // colors: bloodPressureColorData,
        labelValue :"mm Hg"
    }
]

export const consciousnessOptions =[
    {label: 'Alert (A)', value: 'Alert', shorten: "A"},
    {label: 'Voice (V)', value: 'Voice', shorten: "V"},
    {label: 'Pain (P)', value: 'Pain', shorten: "P"},
    {label: 'Unresponsive (UR)', value: 'Unresponsive', shorten: "UR"},
    {label: 'Confusion(CF)', value: 'Confusion', shorten: "CF"},
    {label: 'Verbal (VB)', value: 'Verbal', shorten: "VB"},
    {label: 'Drowsiness (DS)', value: 'Drowsiness', shorten: "DS"},
    {label: 'Lethargy (LT)', value: 'Lethargy', shorten: "LT"},
    {label: 'Coma (CM)', value: 'Coma', shorten: "CM"},
]
export const confusionOptions =[
    {label: 'Yes', value: true},
    {label: 'No', value: false},
]
  
export const bloodPressureLimbOptions = [
    {label: 'Left Arm (LA)', value: 'Left Arm', shorten: "LA"},
    {label: 'Right Arm (RA)', value: 'Right Arm', shorten: "RA"},
    {label: 'Left Leg (LL)', value: 'Left Leg', shorten: "LL"},
    {label: 'Right Leg (RL)', value: 'Right Leg', shorten: "RL"},
    {label: 'Other (O)', value: 'Other', shorten: "O"},
]
export const bloodPressurePositionOptions = [
    {label: 'Lying (L)', value: 'Lying', shorten: "L"},
    {label: 'Sitting (SI)', value: 'Sitting', shorten: "SI"},
    {label: 'Standing (ST)', value: 'Standing', shorten: "ST"},
]
export const painOptions = [
    {label: 'None (NO)', value: 25, shorten: "NO"},
    {label: 'Mild (MI)', value: 50, shorten: "MI"},
    {label: 'Moderate (MO)', value: 75, shorten: "MO"},
    {label: 'Severe (SE)', value: 100, shorten: "SE"},
    
]

export const rhythmOptions = [
    {label: 'Regular', value: 'Regular', shorten: "RE"},
    {label: 'Irregular', value: 'Irregular', shorten: "IR"}
]

export const selectBtnGroups = [
    {
        key: keys.bpLimb,
        label : "Blood Pressure Limb",
        options : bloodPressureLimbOptions
    },
    {
        key: keys.bpPosition,
        label : "Blood Pressure Position",
        options : bloodPressurePositionOptions
    }
]

export const selectBtnGroups1 = [
    {
        key: keys.paValue,
        label : "Pain At Rest",
        options : painOptions
    },
    {
        key: keys.poValue,
        label : "Pain On Movement",
        options : painOptions
    }
]

export const shortenValueKeys = [keys.consciousness, keys.painOnMovement, keys.painAtRest, keys.rhythm, keys.comment]