import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

const useWindowSize = () => {
  const [width, setWidth] = useState(0)

  const windowListener = useCallback(
    debounce(() => {
      if (window) setWidth(window.innerWidth)
    }, 250),
    []
  )

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth)
      window.addEventListener('resize', windowListener)
    }
    return () => {
      windowListener.cancel()
      window && window.removeEventListener('resize', windowListener)
    }
  }, [])

  return width
}

export const useRefDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 2 })
  useEffect(() => {
    if (ref.current) {
      const { current } = ref
      const boundingRect = current.getBoundingClientRect()
      const { width, height } = boundingRect
      setDimensions({ width: Math.round(width), height: Math.round(height) })
    }
  }, [ref])
  return dimensions
}

export default useWindowSize
