import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PageContentWrapper from "../../utils/LayoutUtils";
import { InputTextarea } from "primereact/inputtextarea";
import {
  Avatar,
  Button,
  Calendar,
  Checkbox,
  Dropdown,
  FileUpload,
  InputMask,
  InputText,
  MultiSelect,
  
} from "primereact";
import { InputNumber } from "primereact/inputnumber";
import {
  leftPatientForm,
  rightPatientForm,
  nokForm,
  gpForm,
  componentTypes,
  classnameTypes
} from "./PatientOptionsForm";
import { createNewPatient, getAllDoctors, getDoctorId, getPatientId, updatePatientInfo, uploadImage } from "../../api/CurrentServiceWorker";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { showNotification } from "../notification/NotificationService";
import { capitalize, phoneFormat, validEmail } from "../../utils/CommonUtils";
import { Sidebar } from 'primereact/sidebar';
import { IMG_END_POINT } from "../../Constants";
import { onAuthChange } from "../authentication/FirebaseAuthService";
import { roleTypes } from "../authentication/FirebaseAuthProvider";
import { openPage } from "../../utils/NavigatorUtils";
import moment from "moment";
import { useMemo } from "react";


const PatientForm = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showForm: (data) => showForm(data)
  }))

  const {data: nurseList} = useQuery(
    ["doctorList", 1],
    () => getAllDoctors()
  )

  const topFormElement = useRef(null)
  
  const [errors, setErrors] = useState([])
  const [visible, setVisible] = useState(false);
  const sickInitialValues = [
    { name: "Dementia", key: "dementia", checked: false},
    { name: "Heart Condition", key: "heartCondition", checked: false},
    { name: "COPD", key: "copd", checked: false },
    { name: "Diabetics", key: "diabetics", checked: false },
    { name: "Learning Disability", key: "learningDisability", checked: false },
    { name: "Covid", key: "covid", checked: false },
    { name: "Falls", key: "fall", checked: false },
    { name: "Low Mood", key: "lowMood", checked: false },
  ]
  const [sickCategories, setSickCategories] = useState(sickInitialValues)
  
  const checkedSickValues = (dataString) => {
    if(dataString) {
      const dataList = JSON.parse(dataString);
      if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
        return sickInitialValues
      }

      let sickValues = [...sickInitialValues];
      for(let i = 0; i < sickValues.length; i++) {
        if(dataList.includes(sickValues[i].key)) {
          sickValues[i].checked = true
        }
      }

      return sickValues;
    }

    return sickInitialValues;
  }

  const convertToNurseOptions = () => {
    if(!nurseList || !Array.isArray(nurseList)) {
      return []
    }

    let returnedList = []
    for(let i = 0; i < nurseList.length; i++) {
      if(nurseList[i]){
        let item = {
          label: `${nurseList[i].firstname} ${nurseList[i].lastname}`,
          value: nurseList[i].id
        }

        returnedList.push(item)
      }
    }
    
    return returnedList
  }

  const nurseOptions = useMemo (() => {
    return convertToNurseOptions()
  }, [nurseList?.length]) 

  const showForm = (data) => {
    setVisible(true);
    setData(data && data[0] ? {...data[0], chooseNurse: JSON.parse(data[0].chooseNurse)} : {})
    setSickCategories(data && data[0] ? checkedSickValues(data[0].checkbox_sick) : sickInitialValues)
  }

  const hideForm = () => {
    setVisible(false);
    setData({});
    setSickCategories(sickInitialValues)
    setErrors([])
  }

  const [data, setData] = useState({});
  const queryClient = useQueryClient();
  
  const topform = [
    { 
      label: "UserName",
      key: "username",
      component: componentTypes.input,
      className: classnameTypes.double,
      hidden: data && data.patient_id 
    },
    {
      
      label: "Password",
      key: "password",
      component: componentTypes.input,
      className: classnameTypes.double,
      hidden: data && data.patient_id
    },
    {
      label: "Email",
      key: "email",
      component: componentTypes.input,
      className: classnameTypes.simple,
      hidden: data && data.patient_id
    },
  ]

  const toFormatPhoneKeys = [
    "gpTelephone", "nok_mobile_number", "mobile_number"
  ]

  const handleChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      // [toFormatPhoneKeys.includes(e.target.name) ? `${e.target.name}-fmt` : e.target.name]: 
    });
  };
  const handleChangeDropdown = (e) => {
    setData({
      ...data,
      [e.target.name]: e.value,
    });
  };
  const onCategoryChange = (key, categoryKey, e) => {
    // categories options
    let tmpCategories = [];

    // data checkbox sick
    let sickness = [];

    for(let i = 0 ; i < sickCategories.length ; i++) {
      if(sickCategories[i].key === categoryKey) {
        // create tmp obj of category
        let item = {
          ...sickCategories[i],
          checked: e.checked
        }

        // add to list
        tmpCategories.push(item)

        // add to data if e.checked
        if(e.checked) {
          sickness.push(categoryKey)  
        }
      } else {
        // add the rest to list
        tmpCategories.push(sickCategories[i])

        // check the rest and add to list if e.checked
        if(sickCategories[i].checked) {
          sickness.push(sickCategories[i].key)
        }
      }
    }
    
    // DATA
    setData({
      ...data,
      [key]: JSON.stringify(sickness) 
    })

    // UI
    setSickCategories(tmpCategories)
  }

  const handleCreatePatient = async () => {
    const requiredKeys = ['username', 'password', 'email', 'first_name', 'last_name'];
    let missingKeys = [...requiredKeys]
    for(const key in data) {
      if(requiredKeys.includes(key) && data[key]) {
        if(key === "email" && !validEmail(data[key])) {
          missingKeys.push("invalidEmail")
        }

        if((key === "username" || key === "password") && data[key].length < 6) {
          missingKeys.push(key === "username" ? "invalidUsername" : "invalidPassword")
        }

        missingKeys = missingKeys.filter(x => x !== key)
      }
    }

    let isError = false;
    if(missingKeys.length > 0) {
      setErrors(missingKeys)
      isError = true

      showNotification("error", "Error", "Please fill in required information");
      topFormElement.current.scrollIntoView({ behavior: "smooth" });
    }

    if(!isError) {
      let toPostData = {
        ...data,
        dob: data && data.dob ? moment(data.dob).format("DD-MM-yyyy") : "",
        doctor_id: getDoctorId(),
        chooseNurse: data && data.chooseNurse ? JSON.stringify(data.chooseNurse) : "[]"
      }

      if(data.patient_id) {
        updationMutate(toPostData)
      } else {
        mutate(toPostData);
      }
    }
  }

  const {mutate: updationMutate} = useMutation(toUpdatePatient => updatePatientInfo(toUpdatePatient), {
    onSuccess: (data, variables, context) => {
      showNotification("success", "Success", "Update successfully");

      queryClient.setQueryData(
        ['patientList', getDoctorId()],
        oldDataList => {
            if(!oldDataList || !Array.isArray(oldDataList) || oldDataList.length < 1) {
                return [variables]
            }

            return oldDataList.map((item) => (item.patient_id === variables.patient_id ? item = variables : item))
        }
      )

      queryClient.setQueryData(
        ['patientDetail', getPatientId()],
        oldDataList => [variables]
      )

      hideForm();
    },
    onError: (data, variables, context) => {
      showNotification("error", "Error", "Fail to update patient information !")
    }
  })

  const { mutate } = useMutation(toAddPatient => createNewPatient(toAddPatient), {
    onSuccess: (data, variables, context) => {
      if(data && data.id) {
        showNotification("success", "Success", "Patient Created");

        queryClient.setQueryData(
          ['patientList', getDoctorId()],
          oldDataList => oldDataList && Array.isArray(oldDataList) ? [...oldDataList, {...data, patient_id: data.id}] : [{...data, patient_id: data.id}]
        )

        setData({})
        hideForm();

        onAuthChange(data, roleTypes.patient)
      } else {
        showNotification("error", "Error", "Fail to create patient");
      } 
    },
    onError: (error, variables, context) => {
      showNotification("error", "Error", "Fail to create patient");
    }
  })

  const convertKeyToLabel = (key) => {
    if(!key) {
      return "";
    }

    let capitalizedAfterUnderscore = "";
    for(let i = 0; i < key.length; i++) {
      if(key[i] === "_") {
        capitalizedAfterUnderscore += key[i];

        if(key[i + 1]) {
          capitalizedAfterUnderscore += key[i+1]
        }
      } else {
        if(key[i-1] !== "_") {
          capitalizedAfterUnderscore += key[i];
        }
      }
    }

    let removedUnderscore = capitalizedAfterUnderscore.replace(/_/g, " ");
    let capitalizedKey = removedUnderscore ? capitalize(removedUnderscore) : removedUnderscore;

    return capitalizedKey;
  } 

  const onBasicUpload = async(e) => {
    const imageFile = e.files[0];
    
    var bodyFormData = new FormData();
    bodyFormData.append("image", imageFile);

    const imageResponse = await uploadImage(bodyFormData);
    if(imageResponse && imageResponse.success && imageResponse.data) {
      setData({...data, 
        avatar : imageResponse.data.display_url })
    }
  }

  const customIcons = (
    <div className='flex align-items-center px-3 ml-6' style={{cursor: 'pointer', backgroundColor: 'rgba(99,102,241, 0.3)', borderRadius: 6, height: 30}} onClick={() => hideForm()}>
        <div className='mr-3'>
            <i className="pi pi-arrow-left"/>
        </div>
        <div className='font-bold'>
          BACK TO LIST
        </div>
      </div>
  )

  return (
    <Sidebar className="custom-sidebar" visible={visible} fullScreen onHide={() => hideForm()} dismissable={false}
      icons={customIcons} blockScroll={true}
    >
      <PageContentWrapper>
        <div style={{ float:"left", clear: "both" }}
          ref={topFormElement}>
        </div>
        <div className="m-3">
          <h2 className="font-bold"> Client Form</h2>
          <hr style={{width: '7%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
        </div>
      
        <div className="grid m-2">
          <div className="col-6 p-2">
            <div className="col-12 grid">
            {topform.map((item) =>
                 (item.component === componentTypes.input && !item.hidden) ? (
                  <div key={item.key} className={item.className}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <InputText
                      className="col-12 p-inputtext-lg"
                      rows={3}
                      placeholder={`${convertKeyToLabel(item.key)}`}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    {(item.key === "username" && errors.includes("invalidUsername")) ? <label className="required-error">Invalid username !!. Must contain at least 6 characters</label> : ""}
                    {(item.key === "password" && errors.includes("invalidPassword")) ? <label className="required-error">Invalid password !!. Must contain at least 6 characters</label> : ""}
                    {(item.key === "email" && errors.includes("invalidEmail")) ? <label className="required-error">Invalid email</label> : ""}
                  </div>
                ) : item.component === componentTypes.inputNumber ? (
                  <div key={item.key} className={`${item.className}`}>
                    <InputNumber
                      className="col-12 p-0"
                      inputStyle={{height: '45px'}}
                      rows={3}
                      placeholder={item.label}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => setData({...data, [item.key]: e.value})}
                    />
                  </div>
                ) : (
                  item.component === componentTypes.dropdown && (
                    <div key={item.key} className={`${item.className}`}>
                      <Dropdown
                        className="col-12 py-1-custom"
                        name={item.key}
                        filter
                        filterBy="label"
                        optionValue="value"
                        placeholder={`Select ${(item.label)}`}
                        value={data[item.key] ||""}
                        options={item.options}
                        onChange={(e) => handleChangeDropdown(e)}
                      />
                    </div>
                  )
                )
              )}
              {leftPatientForm.map((item, idx) =>
                item.component === componentTypes.inputArea ? (
                  <div key={item.key} className={item.className}>
                    {/* <h5 className="col-12">{item.label}</h5> */}
                    <h5 className={`font-bold`}>{item.label}</h5>
                    <InputTextarea
                      className="col-12"
                      autoResize
                      rows={5}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                      placeholder={`${convertKeyToLabel(item.key)}`}
                    />
                    
                  </div>
                ) : (item.component === componentTypes.input && !item.hidden) ? (
                  <div key={item.key} className={item.className}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <InputText
                      className="col-12 p-inputtext-lg"
                      rows={3}
                      placeholder={`${convertKeyToLabel(item.key)}`}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    {(item.key === "username" && errors.includes("invalidUsername")) ? <label className="required-error">Invalid username !!. Must contain at least 6 characters</label> : ""}
                    {(item.key === "password" && errors.includes("invalidPassword")) ? <label className="required-error">Invalid password !!. Must contain at least 6 characters</label> : ""}
                    {(item.key === "email" && errors.includes("invalidEmail")) ? <label className="required-error">Invalid email</label> : ""}
                  </div>
                ) : item.component === componentTypes.dropdown ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <Dropdown
                      className="col-12 py-1-custom"
                      name={item.key}
                      filter
                      filterBy="label"
                      optionValue="value"
                      placeholder={`Select ${(item.label)}`}
                      value={data[item.key] || item.initialValue}
                      options={item.options}
                      onChange={(e) => handleChangeDropdown(e)}
                    />
                  </div>
                )
                : item.component === componentTypes.multiSelect ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <MultiSelect
                      className="col-12 py-1-custom"
                      name={item.key}
                      filter
                      filterBy="label"
                      optionValue="value"
                      placeholder={`Select ${(item.label)}`}
                      value={data[item.key] || item.initialValue}
                      options={nurseOptions}
                      onChange={(e) => handleChangeDropdown(e)}
                      display="chip"
                    />
                  </div>
                )
                 : item.component === componentTypes.upload ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <FileUpload
                     mode="basic" name={item.key} 
                     url={IMG_END_POINT} 
                     accept="image/*" maxFileSize={1000000}
                     auto={false}
                     customUpload uploadHandler={onBasicUpload} />
                  </div>
                )
                : item.component === componentTypes.calendar ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <Calendar
                      className="col-12 p-0"
                      inputStyle={{height: '45px'}}
                      name={item.key}
                      value={ data[item.key] || item.initialValue}
                      onChange={(e) => setData({...data, [item.key]: e.value })}
                      placeholder={`Select ${(item.label)}`}
                    />
                  </div>
                )
                : item.component === componentTypes.inputMask ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <InputMask 
                      name={item.key} 
                      className="col-12"
                      style={{height: '45px'}}
                      mask="(999) 999-9999"
                      value={data[item.key] || ""}
                      placeholder="Phone Number"
                      onChange={(e) => setData({...data, [item.key]: e.value})}></InputMask>
                  </div>
                )
                : (
                  item.component === componentTypes.inputNumber && (
                    <div key={item.key} className={`${item.className}`}>
                      <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                      <InputNumber
                        className="col-12 p-0"
                        inputStyle={{height: '45px'}}
                        rows={3}
                        name={item.key}
                        value={data[item.key] || ""}
                        onChange={(e) => setData({...data, [item.key]: e.value})}
                        placeholder={` ${convertKeyToLabel(item.key)}`}
                      />
                    </div>
                  )
                )
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="col-12 grid">
              {rightPatientForm.map((item, idx) =>
                item.component === componentTypes.inputArea ? (
                  <div key={item.key} className={item.className}>
                    <h5>{item.label}</h5>
                    {/* componentTypes */}
                  </div>
                ) : item.component === componentTypes.inputArea ? (
                  <div key={item.key} className={item.className}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <InputTextarea
                      className="col-12 p-inputtext-lg"
                      rows={3}
                      placeholder={`${convertKeyToLabel(item.key)}`}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </div>
                ) : item.component === componentTypes.dropdown ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <Dropdown
                      className="col-12 py-1-custom"
                      name={item.key}
                      filter
                      filterBy="label"
                      optionValue="value"
                       placeholder={`Select ${(item.label)}`}
                      value={data[item.key] || item.initialValue}
                      options={item.options}
                      onChange={(e) => handleChangeDropdown(e)}
                    />
                  </div>
                ) : item.component === componentTypes.input ? (
                  <div key={item.key} className={item.className}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <InputText
                      className="col-12 p-inputtext-lg"
                      placeholder={`${convertKeyToLabel(item.key)}`}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </div>
                ) : item.component === componentTypes.dropdown ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <Dropdown
                      className="col-12 py-1-custom"
                      name={item.key}
                      filter
                      filterBy="label"
                      optionValue="value"
                      placeholder={item.key}
                      value={data[item.key] || item.initialValue}
                      options={[]}
                      onChange={(e) => handleChangeDropdown(e)}
                    />
                  </div>
                ) : item.component === componentTypes.multiCheckbox ? (
                  <div key={item.key} className={`${item.className} ${"grid"}`}>
                  {
                    sickCategories.map((category) => {
                    return (
                        <div key={category.key} className="field-checkbox lg:col-4 col-6">
                            <Checkbox
                              inputId={category.key} 
                              name="category"
                              onChange={(e) => onCategoryChange(item.key, category.key, e)} 
                              checked={category.checked} 
                              />
                            <label htmlFor={category.key}>{category.name}</label>
                        </div>
                      )
                    })
                  }
                  </div>
                ): item.component === componentTypes.inputMask ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>

                    <InputMask 
                      name={item.key} 
                      className="col-12"
                      style={{height: '45px'}}
                      mask="(999) 999-9999"
                      value={data[item.key] || ""}
                      placeholder={item.label}
                      onChange={(e) => setData({...data, [item.key]: e.value})}></InputMask>
                  </div>
                )
                 : item.component === componentTypes.calendar ? (
                  <div key={item.key} className={`${item.className}`}>
                    <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                    <Calendar
                      className="col-12 py-1-custom"
                      name={item.key}
                      value={data[item.key] || item.initialValue}
                      onChange={(e) => setData({...data, [item.key]:e.value})}
                      placeholder={`Select ${(item.label)}`}
                    />
                  </div>
                ) : ( 
                  item.component === componentTypes.inputNumber && (
                    <div key={item.key} className={`${item.className}`}>
                      <h5 className={`font-bold ${errors.includes(item.key) ? 'required-error' : ''}`}>{item.label}</h5>
                      <InputNumber
                        className="col-12 p-0"
                        inputStyle={{height: '45px'}}
                        rows={3}
                        name={item.key}
                        value={data[item.key] || ""}
                        onChange={(e) => setData({...data, [item.key]: e.value})}
                        placeholder={` ${convertKeyToLabel(item.key)}`}
                      />
                    </div>
                  )
                )
              )}

              <h5 className="col-12 p-0 font-bold">NOK Detail</h5>
              {nokForm.map((item, idx) =>
                item.component === componentTypes.input ? (
                  <div key={item.key} className={item.className}>
                    <InputText
                      className="col-12 p-inputtext-lg"
                      rows={3}
                      placeholder={item.label}
                      name={item.key}
                      value={item.formatPhone && data[item.key] ? phoneFormat(data[item.key]) : data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </div>
                ) : item.component === componentTypes.inputNumber ? (
                  <div key={item.key} className={`${item.className}`}>
                    <InputNumber
                      className="col-12 p-0"
                      inputStyle={{height: '45px'}}
                      rows={3}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => setData({...data, [item.key]: e.value})}
                      placeholder={` ${convertKeyToLabel(item.key)}`}
                    />
                  </div>
                ) : item.component === componentTypes.inputMask ? (
                  <div key={item.key} className={`${item.className}`}>
                    <InputMask 
                      name={item.key} 
                      className="col-12"
                      style={{height: '45px'}}
                      mask="(999) 999-9999"
                      value={data[item.key] || ""}
                      placeholder="Phone Number"
                      onChange={(e) => setData({...data, [item.key]: e.value})}></InputMask>
                  </div>
                )
                 : (
                  item.component === componentTypes.dropdown && (
                    <div key={item.key} className={`${item.className}`}>
                      <Dropdown
                        className="col-12 py-1-custom"
                        name={item.key}
                        filter
                        filterBy="label"
                        placeholder={`Select ${(item.label)}`}
                        value={data[item.key] || item.initialValue}
                        options={item.options}
                        onChange={(e) => handleChangeDropdown(e)}
                      />
                    </div>
                  )
                )
              )}

              <h5 className="col-12 p-0 font-bold">GP Detail</h5>
              {gpForm.map((item, idx) =>
                item.component === componentTypes.input ? (
                  <div key={item.key} className={item.className}>
                    <InputText
                      className="col-12 p-inputtext-lg"
                      rows={3}
                      placeholder={item.label}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </div>
                ) : item.component === componentTypes.inputNumber ? (
                  <div key={item.key} className={`${item.className}`}>
                    <InputNumber
                      className="col-12 p-0"
                      inputStyle={{height: '45px'}}
                      rows={3}
                      placeholder={item.label}
                      name={item.key}
                      value={data[item.key] || ""}
                      onChange={(e) => setData({...data, [item.key]: e.value})}
                    />
                  </div>
                ) : item.component === componentTypes.inputMask ? (
                  <div key={item.key} className={`${item.className}`}>
                    <InputMask 
                      name={item.key} 
                      className="col-12"
                      style={{height: '45px'}}
                      mask="(999) 999-9999"
                      value={data[item.key] || ""}
                      placeholder="Phone Number"
                      onChange={(e) => setData({...data, [item.key]: e.value})}></InputMask>
                  </div>
                ) : (
                  item.component === componentTypes.dropdown && (
                    <div key={item.key} className={`${item.className}`}>
                      <Dropdown
                        className="col-12 py-1-custom"
                        name={item.key}
                        filter
                        filterBy="label"
                        placeholder={`Select ${(item.label)}`}
                        value={data[item.key] ||""}
                        options={item.options}
                        onChange={(e) => handleChangeDropdown(e)}
                      />
                    </div>
                  )
                )
              )}
            </div>
          </div>
        </div>

        <div className="col-12">
          <Button className="col-12 p-button-lg" label="Submit"  onClick={handleCreatePatient} />
        </div>
      </PageContentWrapper>
    </Sidebar>
  );
});

export default PatientForm;
