import React, { useEffect, useMemo, useRef, useState }  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { openPage } from './utils/NavigatorUtils';
import { Menu } from 'primereact/menu';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { discardExerciseMessage, discardObsMessage, getAllExercises, getAllPatientObs, getDoctorId, getDoctorName, getListPatients, isAdmin, removeBatteryLevel, removeDoctorFirebaseId, removeDoctorId, removeDoctorName, removePatientFirebaseId, removePatientId, removePatientName, setPatientFirebaseId, setPatientId, setPatientName } from './api/CurrentServiceWorker';
import { Badge, Button, Dialog } from 'primereact';
import Map from './components/map/Map';
import PigeonMap from './components/map/PigeonMap';
import moment from 'moment';
import { getLabelFromKey } from './components/observations/Utils';
import { notificationEvent } from './components/notification/NotificationService';
import { Avatar } from 'primereact/avatar';
import { useAppContext } from './context/AppContext';
import TutorialsForm from './components/tutorials/TutorialsForm';
import fallIconRed from './assets/icon/fallIconRed.png'
import seizureIcon from './assets/icon/seizureIcon.png'
import alertIcon from './assets/icon/alertIcon.png'

export const AppTopbar = (props) => {

    const tutorialsForm = useRef(null);

    const queryClient = useQueryClient();
    const menuRef = useRef(null);

    const {patientManagedIds} = useAppContext();

    const [mapData, setMapData] = useState({});
    const [showMaps, setShowMaps] = useState(false);

    const [reload, setReload] = useState(0)

    const {data} = useQuery(
        ['notificationData'],
        () => getAllExercises()
    )

    const {data: patientObsList, refetch} = useQuery(
        ['notificationObsData'],
        async() => await getAllPatientObs()
    )

    const {data: patientList} = useQuery(
        ['patientList', getDoctorId()],
        async() => await getListPatients(),
        {
            enabled: !!getDoctorId()
        }
    )


    const getFilteredObsData = (dataList) => {
        let returnedDataList = [];
        if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
            return returnedDataList;
        }

        for(let i = 0; i < dataList.length; i++) {
            if( dataList[i].discard === 0 && dataList[i].news_log) {
                const newsLogData = JSON.parse(dataList[i].news_log);

                if(newsLogData && Object.keys(newsLogData).length > 0) {
                    returnedDataList.push({...newsLogData, id: dataList[i].id, type: "news", date: dataList[i].created_at ? moment(dataList[i].created_at).toDate() : dataList[i].created_at, created_at: dataList[i].created_at})
                }
            }
        }

        return returnedDataList;
    }


    const filterObsDataList = useMemo(() => {
        if(!patientObsList || !Array.isArray(patientObsList)) {
            return [];
        }

        return getFilteredObsData(patientObsList)
    }, [patientObsList?.length]) 

    const filterDataList = useMemo(() => {
        if(!data || !Array.isArray(data)) {
            return []
        }

        return data.filter(x =>(x.fall === "true" || x.epileptic === "true") && x.discard === 0);
    }, [data?.length])

    const convertDataList = useMemo(() => {
        if(!filterDataList || !Array.isArray(filterDataList) || filterDataList.length < 1 
        || !patientList || !Array.isArray(patientList) || patientList.length < 1
        ) {
            return [];
        }

        return filterDataList.map((item) => {
                
                    for(let i = 0; i < patientList.length; i++) {
                        if(item.patient_id === patientList[i].patient_id) {
                            item.type = "exercise"
                            item.actionType = item.fall === "true" ? "fall" : item.epileptic === "true" ? "epileptic" : ""
                            item.patient_name = `${patientList[i].first_name} ${patientList[i].last_name}`;
                            item.date = patientList[i].created_at ? moment(patientList[i].created_at).toDate() : patientList[i].created_at
                            break;
                        }
                    }

                    return item;
                }
            ) 
    }, [filterDataList?.length, patientList?.length, ])

    const mergedDataList = filterObsDataList.concat(convertDataList);
    const finalDataList = mergedDataList.sort((a,b) => a.date - b.date)

    const numberOfNotifcations = useMemo(() => {
        return finalDataList.length
    }, [finalDataList?.length])

    const handleViewInfo = async(data) => {
        if(data && data.type) {
            if(data.type === "exercise") {
                setMapData(data);
                setShowMaps(true);
                
                mutateExercise(data.id)
        
                await discardExerciseMessage(data.id)
            } else {
                setPatientId(data.patient_id);
                setPatientFirebaseId(data.uid);
                setPatientName(data.fullname);

                mutatePatientObs(data.id)
                
                await discardObsMessage(data.id)

                openPage(`/patient/${data.patient_id}/observation`)
            }
        }
    }

    const convertNewsKeyToLabel = (dataList) => {
        if(!dataList || !Array.isArray(dataList) || dataList.length < 1) {
            return "-"
        }

        let returnedLabel = "";
        for(let i = 0; i < dataList.length; i++) {
            if(dataList[i]) {
                if(i === 0) {
                    returnedLabel = getLabelFromKey( dataList[i] )
                } else {
                    returnedLabel += ` - ${getLabelFromKey( dataList[i] )}`
                }
            }
        }

        return returnedLabel
    }
    
    const getMenuItems = useMemo(() => {
        if(!finalDataList || finalDataList.length < 1) {
            return [];
        }

        let itemList = [];

        for(let i = 0; i < finalDataList.length; i++) {
            if(finalDataList[i] && Object.keys(finalDataList[i]).length > 0) {
                let label = null;
                let icon = "";
                if(finalDataList[i].type === "exercise") {
                    label = 

                            <div className='flex'>
                                <div className='flex align-items-center justify-content-center'>
                                    <Avatar image={finalDataList[i].actionType === "fall" ? fallIconRed :  finalDataList[i].actionType === "epileptic" ? seizureIcon : "-"}></Avatar>
                                </div>
                                <div className='ml-2'>
                                    <div className='mb-1'><strong>{finalDataList[i].patient_name ? finalDataList[i].patient_name : "-"}</strong></div>
                                    <div>Event: {finalDataList[i].actionType === "fall" ? "FALL" :  finalDataList[i].actionType === "epileptic" ? "SEIZURE" : "-"}</div>
                                    <div>Date: {finalDataList[i].created_at ? moment(finalDataList[i].created_at).format("yyyy-MM-DD HH:mm") : "-"}</div>
                                </div>
                            </div>

                } else {
                    label = 
                            <div className='flex'>
                                <div className='flex align-items-center justify-content-center'>
                                    <Avatar image={alertIcon}></Avatar>
                                </div>
                                <div className='ml-2'>
                                    <div className='mb-1'><strong>{finalDataList[i].fullname ? finalDataList[i].fullname : "-"}</strong></div>
                                    <div>Event: News Alert</div>
                                    <div>Date: {finalDataList[i].created_at ? moment(finalDataList[i].created_at).format("yyyy-MM-DD HH:mm")  : "-"}</div>
                                    <div>Fields: {finalDataList[i].data && Array.isArray(finalDataList[i].data) ? convertNewsKeyToLabel(finalDataList[i].data) : "-"}</div>
                                </div>
                            </div>
                            
                }
                        

                let item = {
                    label: label,
                    separator: true,
                    command: () => { handleViewInfo(finalDataList[i]) }
                }

                itemList.push(item)
            }
        }

        return itemList
    }, [finalDataList?.length])

    let items = [
        {
            label: 'Notifications',
            items: getMenuItems
        }
    ]

    const renderFooter = () => {
        return (
            <div>
                <Button className='mt-3 px-3' label="Close" icon="pi pi-times" onClick={() => setShowMaps(false)} autoFocus />
            </div>
        );
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const handleOpenNotification = (event) => {
        event.preventDefault();
        menuRef.current.toggle(event)
        
        // addClass(document.body, "body-overflow-hidden");
    }

    const {mutate: mutateExercise} = useMutation(id => () => {}, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(
                ["notificationData"],
                oldDataList => oldDataList && Array.isArray(oldDataList) && oldDataList.length > 0 
                                    ?   oldDataList.filter(x => x.id !== variables)
                                    :   []
            )
        }
    })


    const {mutate: mutatePatientObs} = useMutation(id => () => {}, {
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(
                ["notificationObsData"],
                oldDataList => oldDataList && Array.isArray(oldDataList) && oldDataList.length > 0 
                                    ?   oldDataList.filter(x => x.id !== variables)
                                    :   []
            )
        }
    })

    useEffect(() => {
        notificationEvent.on('PATIENT_OBS_NOTIFICATION', () => refetch())

        return () => notificationEvent.removeListener('PATIENT_OBS_NOTIFICATION')
    }, [])

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img style={{height: "4.5rem"}} src={require("./assets/logo/logo.png")} alt="logo"/>
            </Link>

            {!props.shouldHideMenuIcon &&
                <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars"/>
                </button>
            }

            {isAdmin() && window.location.pathname === "/" &&
                <Button className='px-3' icon="pi pi-user" label='ROLE MANAGEMENT' onClick={() => {
                    removeDoctorId()
                    removeDoctorFirebaseId()
                    removeDoctorName()
                    removePatientId()
                    removePatientFirebaseId()
                    removePatientName()
                    removeBatteryLevel()

                    openPage("/admin/doctor-management")
                }}/>
            }
            

            {!props.shouldHideMenuIcon &&
                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>
            }

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                {getDoctorId() && window.location.pathname !== "/admin/doctor-management" &&
                    <li>
                        <div className='py-3'>
                            <div className='p-2' style={{border: '1px solid #dee2e6', borderRadius: 26}}>
                                <div className='flex justify-content-center align-items-center'>
                                    <Avatar className='small-avatar' image={require("./assets/icon/doctor-icon.png")} size="large"  shape="circle" style={{backgroundColor: '#6366F1', color: 'white', fontSize: "13px", marginRight: '5px', height: 18, width: 18}}/>
                                    <h5 className='m-0'>{getDoctorName()}</h5>
                                </div>
                            </div>
                        </div>
                    </li>
                }
                <li>
                    <div className='flex justify-content-center align-items-center py-3'>
                        <div style={{position: "relative"}}>
                            <button className="p-link layout-topbar-button" onClick={() => tutorialsForm.current?.showForm()} >
                                <i className="pi pi-youtube"/>
                                <span>Tutorials</span>                       
                            </button>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='flex justify-content-center align-items-center py-3'>
                        <div style={{position: "relative"}}>
                            <button className="p-link layout-topbar-button" onClick={(event) => handleOpenNotification(event)}>
                                <i className="pi pi-bell"/>
                                <span>Events</span>                       
                            </button>
                            <div style={{position: 'absolute', top: "-1px", right: 0}}>
                                <Badge severity='danger' value={numberOfNotifcations}></Badge>
                            </div>
                        </div>
                    </div>
                </li>
                <Menu model={items} popup ref={menuRef} style={{height: '350px', width: '450px', overflowY: 'auto'}}/>
                <li>
                    <div className='flex justify-content-center align-items-center py-3'>
                        <button className="p-link layout-topbar-button" onClick={() => {localStorage.clear(); openPage("/")}}>
                            <i className="pi pi-sign-out"/>
                            <span>Log Out</span>
                        </button>
                    </div>
                </li>
            </ul>

            <Dialog
                header="Map"
                visible={showMaps} 
                style={{ width: '60vw' }}
                footer={renderFooter('displayBasic')}
                onHide={() => setShowMaps(false)}
            >
                {/* <Map
                    longtitude={mapData?.longitude}
                    latitude={mapData?.latitude}/> */}

                <PigeonMap 
                    longtitude={mapData?.longitude}
                    latitude={mapData?.latitude}
                />
            </Dialog>
            <TutorialsForm
            ref={tutorialsForm}
            />
        </div>
    );
}
