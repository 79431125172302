import moment from 'moment'

export function convertTimeToString(dateTime) {
    if(dateTime) {
        var date = new Date(dateTime);
        let hours = '' + date.getHours();
        let minutes = '' + date.getMinutes();

        if (hours.length < 2) 
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        
        return hours + ':' + minutes;
    }
}

export function revertStringToTime(timeStr) {
    if(timeStr) {
        return moment(timeStr, 'HH:mm').toDate(); 
    }
}


export function formatDate(date) {
    return date ?  moment(date).format(moment.HTML5_FMT.DATE) : null
}