import { useQuery } from '@tanstack/react-query'
import { Button, TabPanel, TabView} from 'primereact'
import React, {useRef, } from 'react'
import { getExerciseData, getPatientId } from '../../api/CurrentServiceWorker'
import PageContentWrapper from '../../utils/LayoutUtils'
import ExercisesChart from './ExercisesChart'
import ExercisesHeightWeightForm from './ExercisesHeightWeightForm'
import ExercisesStatus from './ExercisesStatus'
import ExercisesTable from './ExercisesTable'
const Exercises =()=> {
  const excerciseHeightWeightForm = useRef(null);

  const {data} = useQuery(['excerciseData', getPatientId()], async() => await getExerciseData())

  return (
    <PageContentWrapper>
        <div className='grid col-12 p-2'>
          <div className='col-12'>
            <div className='col-12 flex justify-content-between'>
              <div>
                <h2 className='font-bold'> Activities </h2>
              </div>
              <div>
                <Button label="Add Height Weight" icon="pi pi-external-link"
                  onClick={() => excerciseHeightWeightForm.current?.showForm() }
                />
              </div>
            </div>
          </div>
          
          <div className='col-12'>
            <TabView className="tabview-header-icon">
              <TabPanel  className='px-2' header=" Information " leftIcon="pi pi-info-circle">
                <div className='col-12 px-0'>
                  <ExercisesStatus data={data || {}}/>
                </div>
                <div className='col-12 px-0 mb-3'>
                  <ExercisesTable />
                </div>
              </TabPanel> 
              <TabPanel className='px-2' header=" Statistic " leftIcon="pi pi-chart-line">
                <div className='col-12 px-0 mb-3'>
                  <ExercisesChart />
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>

        <ExercisesHeightWeightForm
          ref={excerciseHeightWeightForm}
          exerciseData={data || {}}
        />
    </PageContentWrapper>
  )
}

export default Exercises
