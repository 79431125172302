import { useQuery } from '@tanstack/react-query';
import { Button, Column, DataTable, Dropdown } from 'primereact';
import React, { useEffect, useRef, useState } from 'react';
import { getAllGPs } from '../../api/CurrentServiceWorker';
import PageContentWrapper from '../../utils/LayoutUtils';

const GPDatatable = () => {
    const gpFormRef = useRef(null)
    const [pageable, setPageable] = useState({
        rows: 10,
        pageNumber: 0
    })

    const {data: dataList} = useQuery(
        ['gpDataList'],
        async() => await getAllGPs()
    )

    const paginatorTemplate = () => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 200, value: 200 },
        ];

        return <Dropdown
            value={pageable.rows || 10} options={dropdownOptions} 
            onChange={(e) => setPageable({...pageable, rows: e.value})} 
        />;
    }

    const renderHeader = () => {
        return(
            <div className='col-12 flex justify-content-between p-0'>
                <Button label='ADD NEW' onClick={() => gpFormRef.current?.showForm()} icon='pi pi-plus' iconPos='left'/>
                {paginatorTemplate()}
            </div>
        )
    }

    const handleViewDetail = (data) => {
        
    }

    return(
        <React.Fragment>
            <PageContentWrapper>
                <div className='mb-5'>
                    <h3 className='mb-1'>GP Management</h3>
                    <hr style={{width: '7%', backgroundColor: "#6366F1", height: 5, margin: 1, marginBottom: 3}}/>
                </div>
                <DataTable
                    header={renderHeader()} responsiveLayout="stack" breakpoint="960px"
                    filterDisplay="row" emptyMessage="No GP records found"
                    value={dataList ? dataList : []} sortField="patient_id" sortOrder={-1}
                    paginator onPage={(e) => setPageable({...pageable, pageNumber: e.page})}
                    totalRecords={dataList ? dataList.length : 0} first={(pageable.pageNumber * pageable.rows)} rows={pageable.rows}
                >
                    <Column header="ID" filterField='gp_id' filter filterPlaceholder="Search by ID" showFilterMenu={false}
                        body={(rowData) => <div>{rowData.gp_id ? `#${rowData.gp_id}` : "-"}</div>}
                    />
                    <Column header="Code" filterField='gpfh_code' filter filterPlaceholder="Search by code" showFilterMenu={false}
                        body={(rowData) => <div>{rowData.code ? `#${rowData.code}`: '-'}</div>}
                    />
                    <Column header="Surname" filterField='gp_surname' filter filterPlaceholder="Search by surname" showFilterMenu={false} 
                        body={(rowData) => <div>{rowData.gp_surname ? `${rowData.gp_title} ${rowData.gp_surname}` : "-" }</div>}
                    />
                    <Column header="DOH Code" filterField='gp_doh_code' filter filterPlaceholder="Search by doh code" showFilterMenu={false}
                        body={(rowData) => <div>{rowData.gp_doh_code ? `#${rowData.gp_doh_code}`: '-'}</div>}
                    />
                    <Column header="Practice" filterField='gp_practice' filter filterPlaceholder="Search by practice" showFilterMenu={false}
                        body={(rowData) => <div>{rowData.gp_practice ? `${rowData.gp_practice}`: '-'}</div>}
                    />               
                    <Column header="Action" body={(rowData) => <Button type="button" icon="pi pi-search" onClick={() => handleViewDetail(rowData)}></Button>} />
                </DataTable>

                {/* <GPForm 
                    ref={gpFormRef}
                /> */}
            </PageContentWrapper>
        </React.Fragment>
    )
}

export default GPDatatable;