import EventEmitter from 'eventemitter3';

export const loadingEvent = new EventEmitter();

export function showLoading(text) {
    loadingEvent.emit('LOADING', true, text)
}

export function stopLoading() {
    loadingEvent.emit('LOADING', false)
}

export function removeLoading() {
    loadingEvent.removeListener('LOADING');
}